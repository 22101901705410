<template>
  <div>
    <image-select-dialog
      v-model="dialog"
      :src="value.url"
      @change-url="update($event, 'logoUrl')"
      caption="ロゴを変更"
      :func="2"
    ></image-select-dialog>

    <!-- ロゴデザインの由来 入力サンプル-->
    <v-row class="ma-0 pa-0">
      <v-col align="right" class="ma-0 pa-0">
        <input-sample class="input-sample" :refDoc="true" reference="LogoAdd_logoMeaning.txt" label="ロゴデザインの由来"></input-sample>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0">
      <v-col cols="4" class="ma-0 pa-0 pr-2">
        <v-hover v-slot="{ hover }">
          <v-card v-if="value.logoUrl">
            <v-img
              :src="value.logoUrl"
            ></v-img>

            <v-btn
              :class="{ 'logo-btn--hover' : !readonly && hover }"
              class="logo-btn"
              x-small
              fab
              color="primary"
              @click="dialog = true"
              :disabled="readonly"
            >
              <v-icon
                color="white"
              >mdi-pencil</v-icon>
            </v-btn>
          </v-card>
          <v-card v-else>
            <v-responsive :aspect-ratio="1/1">
                <v-overlay
                  absolute
                >
                  <v-btn text
                    color="white"
                    @click="dialog = true"
                    :disabled="readonly"
                  >
                    ロゴを追加
                  </v-btn>
                </v-overlay>
            </v-responsive>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="8" class="ma-0 pa-0 pl-2">
          <v-textarea
            label="ロゴデザインの由来"
            outlined
            auto-grow
            :readonly="readonly"
            :value="value.logoMeaning"
            @input="update($event, 'logoMeaning')"
          ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ImageSelectDialog from '../atoms/ImageSelectDialog';
import InputSample from '../atoms/InputSample.vue';

export default {
  components: {
    ImageSelectDialog,
    InputSample,
  },

  props: {
    value:      Object,
    clearable:  Boolean,
    readonly:   Boolean,
  },

  data(){
    return{
      dialog: false,
    }
  },

  methods: {
    // データの更新
    update(value, key) {
      let logo = JSON.parse(JSON.stringify(this.value))
      logo[key] = value
      this.$emit('input', logo)
    }
  },
}
</script>
<style lang="scss" scoped>
.logo-btn{
  position: absolute;
  bottom: 5px;
  right: 5px;
  opacity: 0;

  &--hover{
    opacity: 1;
  }
}

.input-sample {
  margin-top: -3px;
}
</style>