/**
 * 目標数値-業績(歯科)情報 Request Item Class.
 *
 */

export default class TargetValuesDentistryItem {

  // 自費率
  selfFinancedRate = null;
  // リコール率
  recallRate = null;
  // 稼働率
  occupancyRate = null;
  // キャンセル率
  cancellationRate = null;

}