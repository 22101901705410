/**
 * 課題情報 Request Item Class.
 *
 */

export default class IssueRequestItem {

  // 抽出
  extraction = null;
  // 入力ステータス（課題）
  inputStatusIssue = null;

}