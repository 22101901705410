<template>
  <div class="d-flex flex-row-reverse">
    <v-textarea
      :value="value"
      @input="_input($event)"
      outlined
      auto-grow
      :counter="requirednum"
      :readonly="readonly"
      :rules="[minLen]"
    >
      <template v-slot:counter="{ props }">
        <div v-show="!readonly">
          <span v-if="props.max > props.value" class="red--text">{{props.max}}文字以上記入してください。入力するまで完了できません(残り{{props.max - props.value}}文字)</span>
          <span v-else></span>
        </div>
      </template>
    </v-textarea>
  </div>
</template>
<script>
import InputSample from '../atoms/InputSample.vue';

export default {
  components: {
    InputSample
  },
  props: ['value', 'readonly', 'requirednum'],
  data() {
    return {
      minLen: value =>  value?.length > this.requirednum || '',
    }
  },
  methods: {
    _input(val) {
      this.$emit('input', val)
    },
  },
}
</script>