<template>
  <v-container
    class="sp-common-width-narrow ref-mode px-3"
    v-if="
      isShowRefs(refMode, [
        pest.pestSummary,
        pest.pestTechnology,
        pest.pestSociety,
        pest.pestEconomy,
        pest.pestPolitics,
      ])
    "
  >
    <div class="mb-12" v-show="isShowRef(refMode, pest.pestPolitics)">
      <parts-input-textarea
        v-model="pest.pestPolitics"
        label="Politics（政治的要因）"
        rows="6"
        :readonly="readonly"
        :background-color="bgColor"
        reference="PestAnalysis_pestPolitics.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
    <div class="mb-12" v-show="isShowRef(refMode, pest.pestEconomy)">
      <parts-input-textarea
        v-model="pest.pestEconomy"
        label="Economy（経済的要因）"
        rows="6"
        :readonly="readonly"
        :background-color="bgColor"
        reference="PestAnalysis_pestEconomy.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
    <div class="mb-12" v-show="isShowRef(refMode, pest.pestSociety)">
      <parts-input-textarea
        v-model="pest.pestSociety"
        label="Society（社会的要因）"
        rows="6"
        :readonly="readonly"
        :background-color="bgColor"
        reference="PestAnalysis_pestSociety.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
    <div class="mb-12" v-show="isShowRef(refMode, pest.pestTechnology)">
      <parts-input-textarea
        v-model="pest.pestTechnology"
        label="Technology（技術的要因）"
        rows="6"
        :readonly="readonly"
        :background-color="bgColor"
        reference="PestAnalysis_pestTechnology.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
    <div v-show="isShowRef(refMode, pest.pestSummary)">
      <parts-input-textarea
        v-model="pest.pestSummary"
        :readonly="readonly"
        label="PEST分析まとめ"
        reference="PestAnalysis_pestSummary.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
  </v-container>
  <text-no-data v-else classVal="text-center" />
</template>
<script>
import PartsInputTextarea from "@/components/atomic/molecules/PartsInputTextarea.vue";
import TextNoData from "@/components/atomic/atoms/TextNoData.vue";

export default {
  components: {
    PartsInputTextarea,
    TextNoData,
  },
  props: ["value", "readonly", "refMode"],
  data() {
    return {
      pest: {
        pestPolitics: null,
        pestEconomy: null,
        pestSociety: null,
        pestTechnology: null,
        pestSummary: null,
      },
      bgColor: { default: "white" },
    };
  },
  created() {
    if (this.value) {
      this.pest = this.value;
    }
  },
  watch: {
    value(val) {
      this.pest = val;
    },
  },
};
</script>
