<template>
  <v-btn
    tile
    depressed
    color="btAccent"
    class="white--text"
    :class="customClass"
    min-width="160"
    @click="_click()"
  >
    <v-icon v-if="icon">{{ icon }}</v-icon>
    {{ label }}
  </v-btn>
</template>
<script>
export default {
  props: {
    label: String,
    icon: String,
    customClass: String,
  },
  methods: {
    _click(){
      this.$emit('click')
    },
  }
}
</script>
<style lang="scss" scoped>
</style>