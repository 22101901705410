<template>
  <div class="pt-0">
    <div class="mb-3" v-show="isShowRef(refMode, reference.concept)">
      <div class="text-caption">・コンセプト</div>
      <v-textarea-with-link
        v-model="reference.concept"
        :readonly="readonly"
        reference="HearingAndAnalysingReference_concept.txt"
        :refDoc="true"
      ></v-textarea-with-link>
    </div>
    <div class="mb-3" v-show="isShowRef(refMode, reference.vision)">
      <div class="text-caption">・ビジョン</div>
      <v-textarea-with-link
        v-model="reference.vision"
        :readonly="readonly"
        reference="HearingAndAnalysingReference_vision.txt"
        :refDoc="true"
      ></v-textarea-with-link>
    </div>
    <div class="mb-3" v-show="isShowRef(refMode, reference.tagline)">
      <div class="text-caption">・タグライン</div>
      <v-textarea-with-link
        class="mb-6"
        v-model="reference.tagline"
        :readonly="readonly"
        reference="HearingAndAnalysingReference_tagline.txt"
        :refDoc="true"
      ></v-textarea-with-link>
    </div>
  </div>
</template>
<script>
import VTextareaWithLink from "../atoms/VTextareaWithLink.vue"

export default {
  components: { VTextareaWithLink },
  props: ['value', 'readonly', 'refMode'],
  data () {
    return {
      reference: {
        concept: null,
        vision: null,
        tagline: null,
      },
    }
  },
  created(){
    if(this.value) {
      this.reference = this.value
    }
  },
  watch: {
    value(val) {
      this.reference = val
    },
  }
}
</script>