<template>
  <div class="sp-common-width-wide pt-3 ref-mode label-outside c">
    <div class="sp-common-width-narrow">
      <v-row
        v-show="isShowRef(refMode, service.serviceOverview.businessCategory)"
        no-gutters
      >
        <parts-element-label label="業態" class="col-12 mb-3" />
        <v-col cols="12">
          <v-select
            v-model="service.serviceOverview.businessCategory"
            :items="MasterData.serviceIndustries"
            outlined
            :readonly="readonly"
            hide-details
            class="mb-10"
            background-color="white"
          ></v-select>
        </v-col>
      </v-row>
      <parts-element-label
        :label="isDentistry ? '開業場所' : '商圏'"
        class="mb-5"
        v-show="
          isShowRefs(refMode, [
            service.serviceOverview.location1,
            service.serviceOverview.locationProperty1,
            service.serviceOverview.locationProperty2,
            service.serviceOverview.locationProperty3,
            service.serviceOverview.locationProperty4,
            service.serviceOverview.locationReason,
          ])
        "
      />
      <v-row class="mb-10">
        <v-col
          cols="12"
          class="py-1"
          v-show="isShowRef(refMode, service.serviceOverview.location1)"
        >
          <span class="text-caption py-1 black--text"
            >・{{ isDentistry ? "開業場所" : "商圏" }}</span
          >
          <v-text-field
            v-model="service.serviceOverview.location1"
            outlined
            hide-details
            :readonly="readonly"
            background-color="white"
          ></v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="py-1"
          v-show="isShowRef(refMode, service.serviceOverview.locationProperty1)"
        >
          <span class="text-caption py-1 black--text"
            >・{{ isDentistry ? "開業場所属性1" : "商圏属性1" }}</span
          >
          <v-select
            v-model="service.serviceOverview.locationProperty1"
            :items="MasterData.locationCategory1"
            outlined
            :readonly="readonly"
            background-color="white"
            class="text-filed-attribute"
            hide-details
          ></v-select>
        </v-col>

        <!-- 立地属性2 - 4 は歯科のみ表示 -->
        <v-col
          cols="6"
          class="py-1"
          v-show="
            isShowRef(refMode, service.serviceOverview.locationProperty2) &&
            isDentistry
          "
        >
          <span class="text-caption py-1 black--text">・開業場所属性2</span>
          <v-select
            v-model="service.serviceOverview.locationProperty2"
            :items="MasterData.locationCategory2"
            outlined
            :readonly="readonly"
            background-color="white"
            class="text-filed-attribute"
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="6"
          class="py-1"
          v-show="
            isShowRef(refMode, service.serviceOverview.locationProperty3) &&
            isDentistry
          "
        >
          <span class="text-caption py-1 black--text">・開業場所属性3</span>
          <v-select
            v-model="service.serviceOverview.locationProperty3"
            :items="MasterData.locationCategory3"
            outlined
            :readonly="readonly"
            background-color="white"
            class="text-filed-attribute"
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="6"
          class="py-1"
          v-show="
            isShowRef(refMode, service.serviceOverview.locationProperty4) &&
            isDentistry
          "
        >
          <span class="text-caption py-1 black--text">・開業場所属性4</span>
          <v-select
            v-model="service.serviceOverview.locationProperty4"
            :items="MasterData.locationCategory4"
            outlined
            :readonly="readonly"
            background-color="white"
            class="text-filed-attribute"
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          class="py-1"
          v-show="
            isShowRef(refMode, service.serviceOverview.locationReason) &&
            isDentistry
          "
        >
          <span class="text-caption py-1 black--text"
            >・開業場所を選んだ理由</span
          >
          <v-textarea
            v-model="service.serviceOverview.locationReason"
            reference="CompanyProfile_locationReason.txt"
            :refDoc="true"
            outlined
            :readonly="readonly"
            no-resize
            hide-details
            background-color="white"
            rows="3"
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- 不動産・建築のみ表示 -->
      <div class="mb-10" v-show="isBuilding || isRealEstate">
        <parts-element-label label="不動産/建築管理項目" class="mb-3" />
        <v-row class="justify-center mb-10">
          <v-col
            cols="12"
            v-show="
              isShowRef(
                refMode,
                service.serviceOverview.brokerageMediationTrend
              ) && isRealEstate
            "
          >
            <span class="text-caption py-1 black--text">・媒介/仲介の傾向</span>
            <v-textarea
              v-model="service.serviceOverview.brokerageMediationTrend"
              :readonly="readonly"
              outlined
              rows="3"
              no-resize
              hide-details
              class="mb-5"
              background-color="white"
            ></v-textarea>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              label="FC加盟有無"
              v-model="service.serviceOverview.fcMembership"
              :readonly="readonly"
              hide-details
              color="btPrimary"
            ></v-checkbox>
          </v-col>
          <v-col cols="9" class="pb-0">
            <v-text-field
              v-if="service.serviceOverview.fcMembership"
              v-model="service.serviceOverview.fcMembershipName"
              placeholder="FC名を入力"
              outlined
              :readonly="readonly"
              background-color="white"
              hide-details="auto"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </div>

      <div
        class="mb-10"
        v-show="
          isShowRef(refMode, service.serviceOverview.serviceTag) && !isDentistry
        "
      >
        <parts-element-label label="サービスタグ" class="mb-3" />
        <span>サービス内容を形容するタグを選択してください</span>
        <v-combobox
          v-model="service.serviceOverview.serviceTag"
          :items="serviceTagItems"
          outlined
          multiple
          chips
          deletable-chips
          hide-details
          :readonly="readonly"
          background-color="white"
        ></v-combobox>
      </div>
    </div>

    <div v-show="isShowRef(refMode, service.serviceInfo)">
      <parts-element-label label="サービス内容" class="mb-5" />
      <div class="service-info">
        <service-info
          v-model="service.serviceInfo"
          :readonly="readonly"
          :refMode="refMode"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MasterData from "../../../../utils/MasterData";

import PartsElementLabel from "../../atoms/PartsElementLabel.vue";
import PartsInputTextField from "../../molecules/PartsInputTextField.vue";
import PartsInputTextarea from "../../molecules/PartsInputTextarea.vue";
import ServiceInfo from "../ServiceInfo.vue";

export default {
  components: {
    PartsElementLabel,
    PartsInputTextField,
    PartsInputTextarea,
    ServiceInfo,
  },
  props: ["value", "readonly", "refMode"],
  data() {
    return {
      service: {
        serviceOverview: {
          businessCategory: null,
          location: null,
          locationProperty1: null,
          locationProperty2: null,
          locationProperty3: null,
          locationProperty4: null,
          locationReason: null,
          brokerageMediationTrend: null,
          fcMembership: false,
          fcMembershipName: null,
          serviceTag: null,
        },
        serviceInfo: [],
      },

      rules: {
        required: (value) => !!value || "必須です",
      },

      MasterData,
    };
  },
  computed: {
    ...mapGetters(["project"]),
    count() {
      return this.service.addItems.length;
    },
    isDentistry() {
      return this.project.industry == "歯科";
    },
    isRealEstate() {
      return this.project.industry == "不動産";
    },
    isBuilding() {
      return this.project.industry == "建築";
    },

    // サービスタグの選択肢業界出し分け
    serviceTagItems() {
      let rtn;

      if (this.isRealEstate) {
        rtn = this.MasterData.serviceTagsRealEstate;
      } else if (this.isBuilding) {
        rtn = this.MasterData.serviceTagsBuilding;
      } else {
        rtn = this.MasterData.serviceTags;
      }

      return rtn;
    },
  },
  created() {
    if (this.value) {
      this.service = this.value;
    }
  },
  watch: {
    value(val) {
      this.service = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.service-info {
  width: 100%;
  overflow-x: auto;
  @include scrollAreaX();
}
</style>