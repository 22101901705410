<template>
  <v-container
    class="sp-common-width-narrow ref-mode"
    v-if="
      isShowRefs(refMode, [
        fiveForces.fiveForceSummary,
        fiveForces.bargainingPowerCustomers,
        fiveForces.threatSubstitutes,
        fiveForces.competitiveRivalry,
        fiveForces.bargainingPowerSuppliers,
        fiveForces.threatNewEntrants,
        fiveForces.bargainingPowerCustomers,
      ])
    "
  >
    <div
      class="mb-12"
      v-show="isShowRef(refMode, fiveForces.competitiveRivalry)"
    >
      <parts-input-textarea
        v-model="fiveForces.competitiveRivalry"
        label="業界内の競争"
        :readonly="readonly"
        :background-color="bgColor"
        rows="5"
        reference="FiveForcesAnalysis_competitiveRivalry.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
    <div
      class="mb-12"
      v-show="isShowRef(refMode, fiveForces.threatNewEntrants)"
    >
      <parts-input-textarea
        v-model="fiveForces.threatNewEntrants"
        label="新規参入の脅威"
        :readonly="readonly"
        :background-color="bgColor"
        rows="5"
        reference="FiveForcesAnalysis_threatNewEntrants.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
    <div
      class="mb-12"
      v-show="isShowRef(refMode, fiveForces.threatSubstitutes)"
    >
      <parts-input-textarea
        v-model="fiveForces.threatSubstitutes"
        label="代替品の脅威"
        :readonly="readonly"
        :background-color="bgColor"
        rows="5"
        reference="FiveForcesAnalysis_threatSubstitutes.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
    <div
      class="mb-12"
      v-show="isShowRef(refMode, fiveForces.bargainingPowerCustomers)"
    >
      <parts-input-textarea
        v-model="fiveForces.bargainingPowerCustomers"
        label="買い手の交渉力"
        :background-color="bgColor"
        :readonly="readonly"
        rows="5"
        reference="FiveForcesAnalysis_bargainingPowerCustomers.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
    <div
      class="mb-12"
      v-show="isShowRef(refMode, fiveForces.bargainingPowerSuppliers)"
    >
      <parts-input-textarea
        v-model="fiveForces.bargainingPowerSuppliers"
        label="売り手の交渉力"
        :readonly="readonly"
        :background-color="bgColor"
        rows="5"
        reference="FiveForcesAnalysis_bargainingPowerSuppliers.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
    <div v-show="isShowRef(refMode, fiveForces.fiveForceSummary)">
      <parts-input-textarea
        v-model="fiveForces.fiveForceSummary"
        :readonly="readonly"
        label="5Force分析まとめ"
        reference="FiveForcesAnalysis_fiveForceSummary.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
  </v-container>
  <text-no-data v-else classVal="text-center" />
</template>
<script>
import PartsInputTextarea from "@/components/atomic/molecules/PartsInputTextarea.vue";
import TextNoData from "@/components/atomic/atoms/TextNoData.vue";

export default {
  components: {
    PartsInputTextarea,
    TextNoData,
  },
  props: ["value", "readonly", "refMode"],
  data() {
    return {
      fiveForces: {
        threatNewEntrants: null,
        threatSubstitutes: null,
        bargainingPowerCustomers: null,
        bargainingPowerSuppliers: null,
        competitiveRivalry: null,
        fiveForceSummary: null,
      },
      bgColor: { default: "white" },
    };
  },
  created() {
    if (this.value) {
      this.fiveForces = this.value;
    }
  },
  watch: {
    value(val) {
      this.fiveForces = val;
    },
  },
};
</script>