<template>
  <div class="sp-common-width-wide pt-0 swot-analysis-wrap">

    <v-row class="swot-matrix-wrap mt-5" no-gutters>
      <v-col class="swot-matrix">
        <v-capture :visible="noteMode" absolute>
          <swot-matrix v-model="swotItems.swotMatrix" :readonly="readonly"></swot-matrix>
        </v-capture>
      </v-col>
    </v-row>

    <div v-show="isShowRef(refMode, swotItems.swotSummary)" class="sp-common-width-narrow mt-6">
      <parts-input-textarea
        v-model="swotItems.swotSummary"
        :readonly="readonly"
        label="SWOT分析まとめ"
        reference="SwotAnalysis_swotSummary.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
  </div>
</template>
<script>
import SwotMatrix from '@/components/atomic/molecules/SwotMatrix';
import InputSample from '@/components/atomic/atoms/InputSample.vue';
import PartsInputTextarea from "../../molecules/PartsInputTextarea.vue";
import VCapture from '../../atoms/VCapture.vue';

export default {
  components: {
    SwotMatrix,
    InputSample,
    PartsInputTextarea,
    VCapture,
  },
  props: ['value', 'readonly', 'refMode', 'noteMode'],
  data () {
    return {
      swotItems: {
        swotMatrix: {
          swotStrengths: null,
          swotWeakness: null,
          swotOpportunity: null,
          swotThreat: null,
          swotStrengthsOpportunity: null,
          swotWeaknessOpportunity: null,
          swotStrengthsThreat: null,
          swotWeaknessThreat: null,
        },
        swotSummary: null,

        inputStatusSwot: '',
      },
    }
  },
  created(){
    if(this.value){
      this.swotItems = this.value
    }
  },
  methods: {
  },
  watch: {
    value(val){
      this.swotItems = val
    }
  }
}
</script>
<style lang="scss" scoped>
.swot-analysis-wrap {
  .swot-matrix-wrap {
    @include scrollAreaX();
    width: 100%;

    .swot-matrix {
       min-width: 1000px;

    }
  }
}
</style>