/**
 * SWOT分析情報 Request Item Class.
 *
 */
import SwotMatrixItem from "./SwotMatrixItem";

export default class SwotRequestItem {

  // SWOT分析マトリクス情報
  swotMatrix = new SwotMatrixItem();
  // SWOT分析まとめ
  swotSummary  = null;
  // 入力ステータス
  inputStatusSwot = null;

}