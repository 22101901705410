/**
 * ビジネスモデルキャンパス情報 Request Item Class.
 *
 */

export default class BusinessModelCanvasRequestItem {
  // 主要パートナー
  keyPartners = null;
  // 主要な活動
  keyActivities = null;
  // 主要な資源
  keyResources = null;
  // 提供価値
  valuePropositions = null;
  // 顧客との関係
  customerRelationships = null;
  // 顧客セグメント
  customerSegments = null;
  // チャネル/販路
  channels = null;
  // コスト構造
  costStructure = null;
  // 収益の流れ
  revenueStreams = null;
  // ビジネスモデルキャンパス概要
  bmcSummary = null;
  // 入力ステータス
  inputStatusBusinessModelCanvas = null;
}