<template>
  <div>
    <div class="mb-3">
      <parts-element-label
        :label="label"
        :description="description"
        :reference="reference"
        :refDoc="refDoc"
        :refImg="refImg"
        :readonly="readonly"
      ></parts-element-label>
    </div>
    <v-textarea
      v-if="!refMode"
      outlined
      :rows="rows"
      :no-resize="rows ? true : false"
      :auto-grow="!rows"
      hide-details
      :readonly="readonly"
      :value="value"
      :background-color="bgColor"
      :class="customClass"
      @input="$emit('input', $event)"
    ></v-textarea>
    <div class="refdisp text-wrap pl-3" width v-else>
      {{ value }}
    </div>
  </div>
</template>

<script>
import PartsElementLabel from "../atoms/PartsElementLabel.vue";

export default {
  name: "PartsInputTextarea",
  components: {
    PartsElementLabel,
  },
  props: {
    value: String,
    label: String,
    description: String,
    readonly: Boolean,
    refMode: {default: false},
    rows: String,
    reference: String,
    refDoc: Boolean, //参照ファイルがテキストの場合
    refImg: Boolean,
    bgColor: {default: "white"},
    customClass: String,
  },
  methods: {
    _input(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.refdisp {
  max-width: 100%;
  overflow-wrap: anywhere;
}
</style>
