<template>
  <div class="sp-common-width-narrow ref-mode label-outside">
    <!-- 業績 -->
    <div class="mb-12">
      <parts-element-label
        label="業績"
        :readonly="readonly"
        class="mb-5"
      ></parts-element-label>

      <!-- 業績(汎用) -->
      <v-row class="mb-8 justify-space-around">
        <v-col cols="6" class="py-0">
          <span v-if="isDentistry" class="text-caption py-1 black--text"
            >・年商</span
          >
          <span v-else class="text-caption py-1 black--text">・年間売上</span>
          <custom-currency-field
            v-model="targetValues.general.annualSales"
            prefix="￥"
            :readonly="readonly"
            maxLength="17"
          ></custom-currency-field>
        </v-col>

        <v-col v-if="isDentistry" cols="6" class="py-0"> </v-col>
        <v-col v-else cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・月間売上</span>
          <custom-currency-field
            v-model="targetValues.general.monthlySales"
            prefix="￥"
            :readonly="readonly"
            maxLength="17"
          ></custom-currency-field>
        </v-col>
      </v-row>

      <v-row class="mb-8 justify-space-around">
        <v-col cols="6" class="py-0">
          <span v-if="isDentistry" class="text-caption py-1 black--text"
            >・月間患者数</span
          >
          <span v-else class="text-caption py-1 black--text">・月間顧客数</span>
          <custom-currency-field
            v-model="targetValues.general.monthlyCustomer"
            suffix="人"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span v-if="isDentistry" class="text-caption py-1 black--text"
            >・月間新規患者数</span
          >
          <span v-else class="text-caption py-1 black--text"
            >・月間新規顧客数</span
          >
          <custom-currency-field
            v-model="targetValues.general.monthlyNewCustomer"
            suffix="人"
            outlined
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span v-if="isDentistry" class="text-caption py-1 black--text"
            >・1日患者数</span
          >
          <span v-else class="text-caption py-1 black--text">・1日顧客数</span>
          <custom-currency-field
            v-model="targetValues.general.dailyCustomer"
            suffix="人"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span v-if="isDentistry" class="text-caption py-1 black--text"
            >・中断患者数</span
          >
          <span v-else class="text-caption py-1 black--text">・途中解約数</span>
          <custom-currency-field
            v-model="targetValues.general.cancellationCustomer"
            suffix="人"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>
      </v-row>

      <!-- 業績(歯科) -->
      <v-row v-if="isDentistry" class="mb-8 justify-space-around">
        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・自費率</span>
          <custom-percent-field
            v-model="targetValues.dentistry.selfFinancedRate"
            suffix="%"
            :readonly="readonly"
          ></custom-percent-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・リコール率</span>
          <custom-percent-field
            v-model="targetValues.dentistry.recallRate"
            suffix="%"
            :readonly="readonly"
          ></custom-percent-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・稼働率</span>
          <custom-percent-field
            v-model="targetValues.dentistry.occupancyRate"
            suffix="%"
            :readonly="readonly"
          ></custom-percent-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・キャンセル率</span>
          <custom-percent-field
            v-model="targetValues.dentistry.cancellationRate"
            suffix="%"
            :readonly="readonly"
          ></custom-percent-field>
        </v-col>
      </v-row>

      <!-- 業績(不動産) -->
      <v-row v-if="isRealEstate" class="mb-8 justify-space-around">
        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・月間媒介契約件数</span>
          <custom-currency-field
            v-model="targetValues.realEstate.monthlyBrokerage"
            suffix="件"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text"
            >・平均取引金額（土地）</span
          >
          <custom-currency-field
            v-model="targetValues.realEstate.averageAmountLand"
            prefix="￥"
            :readonly="readonly"
            maxLength="17"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・月間買取件数</span>
          <custom-currency-field
            v-model="targetValues.realEstate.monthlyPurchase"
            suffix="件"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text"
            >・平均取引金額（戸建て）</span
          >
          <custom-currency-field
            v-model="targetValues.realEstate.averageAmountHouse"
            prefix="￥"
            :readonly="readonly"
            maxLength="17"
          ></custom-currency-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text"
            >・査定サイト月平均件数</span
          >
          <custom-currency-field
            v-model="targetValues.realEstate.monthlyPortalsiteInbound"
            suffix="件"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text"
            >・平均取引金額（マンション）</span
          >
          <custom-currency-field
            v-model="targetValues.realEstate.averageAmountMansion"
            prefix="￥"
            :readonly="readonly"
            maxLength="17"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・査定サイト成約率</span>
          <custom-percent-field
            v-model="targetValues.realEstate.portalsiteCloseRate"
            suffix="%"
            :readonly="readonly"
          ></custom-percent-field>
        </v-col>
        <v-col cols="6" class="py-0"></v-col>
      </v-row>

      <!-- 業績(建築) -->
      <v-row v-if="isBuilding" class="justify-space-around">
        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・年間取引件数</span>
          <custom-currency-field
            v-model="targetValues.building.annualQuantity"
            suffix="件"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・新規集客数</span>
          <custom-currency-field
            v-model="targetValues.building.monthlyNewLead"
            suffix="件"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・平均契約単価</span>
          <custom-currency-field
            v-model="targetValues.building.averageAmount"
            prefix="￥"
            :readonly="readonly"
            maxLength="17"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・新規問合せ数</span>
          <custom-currency-field
            v-model="targetValues.building.monthlyNewInquiry"
            suffix="件"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>
      </v-row>
    </div>

    <!-- 事業目標 -->
    <div class="mb-12">
      <parts-element-label
        label="事業目標"
        :readonly="readonly"
        class="mb-5"
      ></parts-element-label>

      <v-row class="justify-space-around">
        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・年間目標売上</span>
          <custom-currency-field
            v-model="targetValues.marketing.targetAnnualSales"
            prefix="￥"
            :readonly="readonly"
            maxLength="17"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・月間目標売上</span>
          <custom-currency-field
            v-model="targetValues.marketing.targetMonthlySales"
            prefix="￥"
            :readonly="readonly"
            maxLength="17"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span v-if="isDentistry" class="text-caption py-1 black--text"
            >・年間目標患者数</span
          >
          <span v-else class="text-caption py-1 black--text"
            >・年間目標顧客数</span
          >
          <custom-currency-field
            v-model="targetValues.marketing.targetAnnualCustomer"
            suffix="人"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span v-if="isDentistry" class="text-caption py-1 black--text"
            >・月間目標患者数</span
          >
          <span v-else class="text-caption py-1 black--text"
            >・月間目標顧客数</span
          >
          <custom-currency-field
            v-model="targetValues.marketing.targetMonthlyCustomer"
            suffix="人"
            :readonly="readonly"
          ></custom-currency-field>
        </v-col>
      </v-row>
    </div>

    <!-- プロモーション予算 -->
    <div class="mb-12">
      <parts-element-label
        label="プロモーション予算"
        :readonly="readonly"
        class="mb-5"
        reference="targetValue_promotionalBudget.txt"
        :refDoc="true"
      ></parts-element-label>

      <v-row class="mb-0 justify-space-around">
        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・年間予算</span>
          <custom-currency-field
            v-model="targetValues.marketing.budgetYearly"
            prefix="￥"
            :readonly="readonly"
            maxLength="17"
          ></custom-currency-field>
        </v-col>

        <v-col cols="6" class="py-0">
          <span class="text-caption py-1 black--text">・月間予算</span>
          <custom-currency-field
            v-model="targetValues.marketing.budgetMonthly"
            prefix="￥"
            :readonly="readonly"
            maxLength="17"
          ></custom-currency-field>
        </v-col>

        <v-col
          cols="12"
          class="py-0"
          v-show="isShowRef(refMode, targetValues.marketing.budgetRemarks)"
        >
          <span class="text-caption py-1 black--text">・予算備考</span>
          <v-textarea
            v-model="targetValues.marketing.budgetRemarks"
            :readonly="readonly"
            flat
            solo
            outlined
            auto-grow
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
    </div>

    <!-- ステップ別目標設計 -->
    <div class="mb-12">
      <parts-element-label
        label="ステップ別目標設計"
        :readonly="readonly"
        class="mb-5"
        reference="targetValue_targetVariables.txt"
        :refDoc="true"
      ></parts-element-label>
      <div
        v-for="(targetVariable, i) in targetValues.marketing.targetVariables"
        :key="targetVariable.id"
        class="mb-3 d-flex"
      >
        <div class="d-flex align-center" cols="4" style="width: 200px">
          <div
            class="text-body-1 black--text d-flex align-center"
            style="min-width: 60px; height: 64px"
          >
            目標{{ i + 1 }}
          </div>
          <div class="d-flex align-center">
            <v-btn
              v-show="!readonly"
              :disabled="i == 0"
              x-small
              color="btPrimary"
              plain
              fab
              @click.stop="moveUpTargetVariable(i)"
              width="20"
            >
              <v-icon>mdi-arrow-up-bold</v-icon>
            </v-btn>
            <v-btn
              v-show="!readonly"
              :disabled="i == targetValues.marketing.targetVariables.length - 1"
              x-small
              color="btPrimary"
              plain
              fab
              @click.stop="moveDownTargetVariable(i)"
              width="20"
            >
              <v-icon>mdi-arrow-down-bold</v-icon>
            </v-btn>
            <v-btn
              v-if="!readonly && i >= TARGET_VARIABLES_MIN_LENGTH"
              icon
              plain
              fab
              x-small
              width="20"
              color="red"
              @click="deleteTargetVariable(i)"
            >
              <v-icon dark dense>mdi-trash-can</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row class="justify-space-around">
          <v-col cols="6" class="py-0 pr-0">
            <span class="text-caption py-1 black--text">・目標名</span>
            <v-text-field
              v-model="targetVariable.targetName"
              outlined
              hide-details
              dense
              :readonly="readonly"
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="py-0 pr-0">
            <span class="text-caption py-1 black--text">・目標数</span>
            <custom-currency-field
              v-model="targetVariable.targetValue"
              dense
              hide-details
              :readonly="readonly"
            ></custom-currency-field>
          </v-col>

          <v-col cols="3" class="py-0 pr-0">
            <span class="text-caption py-1 black--text">・目標単価</span>
            <custom-currency-field
              v-model="targetVariable.targetCpa"
              dense
              prefix="￥"
              hide-details
              :readonly="readonly"
              maxLength="17"
            ></custom-currency-field>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="
          targetValues.marketing.targetVariables.length <
          TARGET_VARIABLES_MAX_LENGTH
        "
        class="d-flex justify-center align-center"
        style="height: 100%"
      >
        <common-add-btn
          v-show="!readonly"
          color="btPrimary"
          @click="addTargetVariable"
        />
      </div>
    </div>

    <!-- 目標と現状のギャップ -->
    <div
      class="mb-12"
      v-show="isShowRef(refMode, targetValues.marketing.goalsAchievements)"
    >
      <parts-input-textarea
        v-model="targetValues.marketing.goalsAchievements"
        label="目標と現状のギャップ"
        :readonly="readonly"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>

    <!-- ファネル上のボトルネック -->
    <div v-show="isShowRef(refMode, targetValues.marketing.bottleneckOfFunnel)">
      <parts-input-textarea
        v-model="targetValues.marketing.bottleneckOfFunnel"
        label="ファネル上のボトルネック"
        :readonly="readonly"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import CustomCurrencyField from "@/components/atomic/atoms/CustomCurrencyField";
import CustomPercentField from "@/components/atomic/atoms/CustomPercentField";
import PartsElementLabel from "@/components/atomic/atoms/PartsElementLabel.vue";
import PartsInputTextarea from "@/components/atomic/molecules/PartsInputTextarea";
import CommonAddBtn from "../../atoms/CommonAddBtn.vue";

export default {
  components: {
    CustomCurrencyField,
    CustomPercentField,
    PartsElementLabel,
    PartsInputTextarea,
    CommonAddBtn,
  },
  props: ["value", "readonly", "refMode"],
  data() {
    return {
      targetValues: {
        targetValuesId: null,
        general: {
          annualSales: null,
          monthlySales: null,
          monthlyCustomer: null,
          dailyCustomer: null,
          monthlyNewCustomer: null,
          cancellationCustomer: null,
        },
        dentistry: {
          selfFinancedRate: null,
          recallRate: null,
          occupancyRate: null,
          cancellationRate: null,
        },
        realEstate: {
          monthlyBrokerage: null,
          monthlyPurchase: null,
          monthlyPortalsiteInbound: null,
          portalsiteCloseRate: null,
          averageAmountLand: null,
          averageAmountHouse: null,
          averageAmountMansion: null,
        },
        building: {
          averageAmount: null,
          annualQuantity: null,
          monthlyNewLead: null,
          monthlyNewInquiry: null,
        },
        marketing: {
          budgetMonthly: null,
          budgetYearly: null,
          budgetRemarks: null,
          goalsAchievements: null,
          targetVariables: [
            {
              targetName: null,
              targetValue: null,
              targetCpa: null,
            },
          ],
          targetAnnualSales: null,
          targetMonthlySales: null,
          targetAnnualCustomer: null,
          targetMonthlyCustomer: null,
          bottleneckOfFunnel: null,
        },
      },
      rules: {
        required: (value) => !!value || "必須です",
      },
      // 定数として使用
      TARGET_VARIABLES_MAX_LENGTH: 10,
      TARGET_VARIABLES_MIN_LENGTH: 3,
    };
  },
  computed: {
    ...mapGetters(["project"]),
    isDentistry() {
      return this.project.industry == "歯科";
    },
    isRealEstate() {
      return this.project.industry == "不動産";
    },
    isBuilding() {
      return this.project.industry == "建築";
    },
  },
  created() {
    if (this.value) {
      this.targetValues = this.value;
    }
  },
  methods: {
    addTargetVariable() {
      this.targetValues.marketing.targetVariables.push({
        targetName: null,
        targetValue: null,
        targetCpa: null,
      });
    },
    deleteTargetVariable(i) {
      this.targetValues.marketing.targetVariables.splice(i, 1);
    },
    moveUpTargetVariable(i) {
      let currentItems = this.targetValues.marketing.targetVariables.concat();
      this.targetValues.marketing.targetVariables.splice(
        i - 1,
        2,
        currentItems[i],
        currentItems[i - 1]
      );
    },
    moveDownTargetVariable(i) {
      let currentItems = this.targetValues.marketing.targetVariables.concat();
      this.targetValues.marketing.targetVariables.splice(
        i,
        2,
        currentItems[i + 1],
        currentItems[i]
      );
    },
  },
  watch: {
    value(val) {
      this.targetValues = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-field-attribute {
  min-width: 400px;
}
</style>
<style lang="scss">
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
