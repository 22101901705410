/**
 * 現状の戦略と実施施策 Request Item Class.
 *
 */

export default class CompetitorsRequestItem {

  // 競合分析データセクション
  addItems = [];
  // 競合分析の入力ステータス
  inputStatusCompetitors = null;

}