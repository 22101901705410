<template>
  <div>
    <v-row class="d-flex flex-nowrap">

      <v-col cols="4">
        <div class="d-flex flex-column">
          <div
            class="theme-color-item mb-2"
            :style="{backgroundColor: mainColor}"
          >
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-responsive
                  :aspect-ratio="1/1"
                  v-on="!readonly && on"
                ></v-responsive>
              </template>
              <v-color-picker
                :value="mainColor"
                @input="$emit('input-main-color', $event)"
                show-swatches
                class="mx-auto"
              ></v-color-picker>
            </v-menu>
          </div>
          <div class="main-color">メイン</div>
        </div>
      </v-col>

      <v-col class="theme-color-item-wrap">
        <div class="d-flex flex-column">
          <div
            class="theme-color-item mb-2"
            :style="{backgroundColor: sub1Color}"
          >
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-responsive
                  :aspect-ratio="1/1"
                  v-on="!readonly && on"
                ></v-responsive>
              </template>
              <v-color-picker
                :value="sub1Color"
                @input="$emit('input-sub1-color', $event)"
                show-swatches
                class="mx-auto"
              ></v-color-picker>
            </v-menu>
          </div>
          <div class="sub-color">サブ</div>
        </div>
      </v-col>

      <v-col class="theme-color-item-wrap">
        <div class="d-flex flex-column">
          <div
            class="theme-color-item mb-2"
            :style="{backgroundColor: sub2Color}"
          >
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-responsive
                  :aspect-ratio="1/1"
                  v-on="!readonly && on"
                ></v-responsive>
              </template>
              <v-color-picker
                :value="sub2Color"
                @input="$emit('input-sub2-color', $event)"
                show-swatches
                class="mx-auto"
              ></v-color-picker>
            </v-menu>
          </div>
          <div class="sub-color">サブ</div>
        </div>
      </v-col>

      <v-col class="theme-color-item-wrap">
        <div class="d-flex flex-column">
          <div
            class="theme-color-item mb-2"
            :style="{backgroundColor: sub3Color}"
          >
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-responsive
                  :aspect-ratio="1/1"
                  v-on="!readonly && on"
                ></v-responsive>
              </template>
              <v-color-picker
                :value="sub3Color"
                @input="$emit('input-sub3-color', $event)"
                show-swatches
                class="mx-auto"
              ></v-color-picker>
            </v-menu>
          </div>
          <div class="sub-color">サブ</div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    mainColor: {
      type: String,
      require: false,
      default: '',
    },
    sub1Color: {
      type: String,
      require: false,
      default: '',
    },
    sub2Color: {
      type: String,
      require: false,
      default: '',
    },
    sub3Color: {
      type: String,
      require: false,
      default: '',
    },
    readonly: Boolean,
  },
}
</script>
<style lang="scss" scoped>
.theme-color-item-wrap{
  width: 25%;
}
.theme-color-item{
  border-radius: 4px;
  border: solid 1px #AFAFAF;
  cursor: pointer;
}
.main-color{
  font-size: 14px;
  text-align: center;
}
.sub-color{
  font-size: 10px;
  text-align: center;
}
</style>