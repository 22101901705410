<template>
  <div class="sp-common-width-wide pt-0 ref-mode">
    <v-row>
      <v-col cols="12" v-show="isShowRef(refMode, threeCs.customerViewpoint)">
        <parts-input-textarea
          v-model="threeCs.customerViewpoint"
          label="市場・顧客の観点"
          :readonly="readonly"
          class="mb-5"
          reference="ThreeCsAnalysis_customerViewpoint.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>
      <v-col cols="12" v-show="isShowRef(refMode, threeCs.competitorViewpoint)">
        <parts-input-textarea
          v-model="threeCs.competitorViewpoint"
          label="競合の観点（競合分析内容のまとめ）"
          :readonly="readonly"
          class="mb-5"
          reference="ThreeCsAnalysis_competitorViewpoint.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>
      <v-col cols="12" v-show="isShowRef(refMode, threeCs.companyViewpoint)">
        <parts-input-textarea
          v-model="threeCs.companyViewpoint"
          label="自社の観点"
          :readonly="readonly"
          class="mb-5"
          reference="ThreeCsAnalysis_companyViewpoint.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>
      <v-col cols="12">
        <parts-element-label
          label="クロス3C整理"
          :readonly="readonly"
          :refDoc="true"
          reference="ThreeCsAnalysis_crossThreeCs.txt"
          class="mb-3"
        ></parts-element-label>
        <cross-three-c-editor
          ref="crossThreeCEditor"
          v-model="threeCs.crossThreeCs"
          :sectionReadonly="readonly"
          class="mb-8"
        ></cross-three-c-editor>
      </v-col>
      <v-col cols="12" v-show="isShowRef(refMode, threeCs.threeCsSummary)">
        <parts-input-textarea
          v-model="threeCs.threeCsSummary"
          label="3Cまとめ（顧客ニーズと競合との差別化ポイント整理）"
          :readonly="readonly"
          class="mb-5"
          reference="ThreeCsAnalysis_threeCsSummary.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PartsInputTextarea from "@/components/atomic/molecules/PartsInputTextarea.vue";
import PartsElementLabel from "../../atoms/PartsElementLabel.vue";
import CrossThreeCEditor from "../CrossThreeCEditor.vue";

export default {
  components: {
    PartsInputTextarea,
    PartsElementLabel,
    CrossThreeCEditor,
  },
  props: ["value", "readonly", "refMode"],
  data() {
    return {
      threeCs: {
        customerViewpoint: null,
        competitorViewpoint: null,
        companyViewpoint: null,
        crossThreeCs: null,
        threeCsSummary: null,
      },
    };
  },
  created() {
    if (this.value) {
      this.threeCs = this.value;
    }
  },
  watch: {
    value(val) {
      this.threeCs = val;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>