/**
 * ビジョン Request Item Class.
 *
 */

export default class VisionRequestItem {

  // ビジョン1
  vision1 = null;
  // ビジョン2
  vision2 = null;
  // ビジョン3
  vision3 = null;
  // 業績1
  performance1 = null;
  // 業績2
  performance2 = null;
  // 業績3
  performance3 = null;
  // 組織医院規模1
  organization1 = null;
  // 組織医院規模2
  organization2 = null;
  // 組織医院規模3
  organization3 = null;
  // 設計評価
  visionEvaluation = null;
  // ビジョンの入力ステータス
  inputStatusVision = null;

}