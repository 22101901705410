<template>
  <div
    class="sp-common-width-narrow pt-5 target-user-wrap ref-mode"
    ref="target-user-wrap"
    v-if="
      isShowRefs(refMode, [
        target.mainDemogra,
        target.mainFeatures,
        target.mainNeeds,
      ]) || isShowRef_SubTarget(refMode, target.subTarget)
    "
  >
    <div
      class="mb-12"
      v-show="
        isShowRefs(refMode, [
          target.mainDemogra,
          target.mainFeatures,
          target.mainNeeds,
        ])
      "
    >
      <parts-element-label
        label="メインターゲット"
        class="mb-3"
      ></parts-element-label>
      <div class="d-inline-flex">
        <div
          class="input-area-main"
          v-show="isShowRef(refMode, target.mainDemogra)"
        >
          <span class="text-caption py-1 black--text">・デモグラ</span>
          <v-textarea
            v-model="target.mainDemogra"
            auto-grow
            outlined
            no-resize
            hide-details
            class="mb-4"
            background-color="white"
            :readonly="readonly"
          ></v-textarea>
        </div>
        <input-sample
          v-if="!refMode"
          class="input-sample"
          :refDoc="true"
          reference="TargetUser_demogra.txt"
          label="デモグラ"
        ></input-sample>
      </div>
      <div class="d-inline-flex">
        <div
          class="input-area-main"
          v-show="isShowRef(refMode, target.mainFeatures)"
        >
          <span class="text-caption py-1 black--text">・ターゲット特長</span>
          <v-textarea
            v-model="target.mainFeatures"
            auto-grow
            outlined
            no-resize
            hide-details
            class="mb-4"
            background-color="white"
            :readonly="readonly"
          ></v-textarea>
        </div>
        <input-sample
          v-if="!refMode"
          class="input-sample"
          :refDoc="true"
          reference="TargetUser_features.txt"
          label="ターゲット特長"
        ></input-sample>
      </div>
      <div class="d-inline-flex">
        <div
          class="input-area-main"
          v-show="isShowRef(refMode, target.mainNeeds)"
        >
          <span class="text-caption py-1 black--text">・ニーズ</span>
          <v-textarea
            v-model="target.mainNeeds"
            auto-grow
            outlined
            no-resize
            hide-details
            class="mb-4"
            background-color="white"
            :readonly="readonly"
          ></v-textarea>
        </div>
        <input-sample
          v-if="!refMode"
          class="input-sample"
          :refDoc="true"
          reference="TargetUser_needs.txt"
          label="ニーズ"
        ></input-sample>
      </div>
    </div>
    <div v-show="isShowRef_SubTarget(refMode, target.subTarget)">
      <parts-element-label
        label="サブターゲット"
        class="mb-3"
      ></parts-element-label>
      <div v-for="(item, i) in target.subTarget" :key="item.id">
        <v-card
          class="subtarget-box mb-6"
          :class="
            refMode ? 'subtarget-box__ref-mode' : 'subtarget-box__edit-mode'
          "
        >
          <div class="d-flex justify-end pa-3">
            <v-btn
              v-show="!readonly"
              depressed
              small
              color="primary"
              @click="deleteSubTarget(i)"
            >
              <v-icon left dark dense>mdi-trash-can</v-icon>
              削除
            </v-btn>
          </div>
          <div class="d-inline-flex">
            <div
              class="input-area-sub"
              v-show="isShowRef(refMode, item.subDemogra)"
            >
              <div class="input-area-sub__title text-caption py-1 black--text">
                ・デモグラ
              </div>
              <v-textarea
                v-model="item.subDemogra"
                auto-grow
                outlined
                no-resize
                hide-details
                class="mb-4"
                background-color="white"
                :readonly="readonly"
              ></v-textarea>
            </div>
            <input-sample
              v-if="!refMode"
              class="input-sample"
              :refDoc="true"
              reference="TargetUser_demogra.txt"
              label="デモグラ"
            ></input-sample>
          </div>
          <div class="d-inline-flex">
            <div
              class="input-area-sub"
              v-show="isShowRef(refMode, item.subFeatures)"
            >
              <div class="input-area-sub__title text-caption py-1 black--text">
                ・ターゲット特長
              </div>
              <v-textarea
                v-model="item.subFeatures"
                auto-grow
                outlined
                no-resize
                hide-details
                class="mb-4"
                background-color="white"
                :readonly="readonly"
              ></v-textarea>
            </div>
            <input-sample
              v-if="!refMode"
              class="input-sample"
              :refDoc="true"
              reference="TargetUser_features.txt"
              label="ターゲット特長"
            ></input-sample>
          </div>
          <div class="d-inline-flex">
            <div
              class="input-area-sub"
              v-show="isShowRef(refMode, item.subNeeds)"
            >
              <div class="input-area-sub__title text-caption py-1 black--text">
                ・ニーズ
              </div>
              <v-textarea
                v-model="item.subNeeds"
                auto-grow
                outlined
                no-resize
                hide-details
                class="mb-4"
                background-color="white"
                :readonly="readonly"
              ></v-textarea>
            </div>
            <input-sample
              v-if="!refMode"
              class="input-sample"
              :refDoc="true"
              reference="TargetUser_needs.txt"
              label="ニーズ"
            ></input-sample>
          </div>
        </v-card>
      </div>
      <div
        v-if="target && target.subTarget && target.subTarget.length < 3"
        class="d-flex justify-center"
      >
        <v-btn
          v-show="!readonly"
          fab
          outlined
          color="primary"
          depressed
          @click="addSubTarget"
          width="45"
          height="45"
        >
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
  <text-no-data
    v-else
    classVal="sp-common-width-narrow ref-mode px-3 text-center"
  />
</template>
<script>
import InputSample from "../../atoms/InputSample.vue";
import PartsElementLabel from "../../atoms/PartsElementLabel.vue";
import TextNoData from "../../atoms/TextNoData.vue";

export default {
  components: {
    InputSample,
    PartsElementLabel,
    TextNoData,
  },
  props: ["value", "readonly", "refMode"],
  data() {
    return {
      target: {
        mainDemogra: null,
        mainFeatures: null,
        mainNeeds: null,

        subTarget: [
          {
            subDemogra: null,
            subFeatures: null,
            subNeeds: null,
          },
        ],
      },
    };
  },
  created() {
    if (this.value) {
      this.target = this.value;
    }

    // サブターゲットが１つもない場合は追加する
    if (!this.target.subTarget?.length) this.addSubTarget();
  },
  watch: {
    value(val) {
      this.target = val;
    },
  },
  methods: {
    addSubTarget: function () {
      this.target.subTarget.push({
        subDemogra: null,
        subFeatures: null,
        subNeeds: null,
      });
    },
    deleteSubTarget: function (i) {
      this.target.subTarget.splice(i, 1);
    },
    isShowRef_SubTarget(refMode, value) {
      return (
        !refMode ||
        value.some((e) => e.subDemogra || e.subFeatures || e.subNeeds)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.target-user-wrap {
  .input-area-main {
    width: 660px;
  }
  .subtarget-box {
    min-width: 690px;
    &__edit-mode {
      background-color: #f3faff !important;
    }
    &__ref-mode {
      background-color: white !important;
    }
    text-align: center;
    .input-area-sub {
      min-width: 620px;
      padding-left: 4px;
      &__title {
        text-align: left;
      }
    }
  }
  .input-sample {
    margin-top: -3px;
  }
}
</style>