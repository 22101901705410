<template>
  <div v-if="value" class="sp-common-width-wide competitors-wrap label-outside ref-mode">
    <!-- 参照バーではラベル形式でタイトルを表示する -->
    <parts-element-label v-if="refMode" label="競合情報" class="mb-3" />
    <div style="text-align: right">
      <input-sample
        v-if="!readonly && (i = 1)"
        class="input-sample"
        :refDoc="true"
        reference="Competitors_competitors.txt"
        label="競合名・特長"
      ></input-sample>
    </div>
    <div v-if="readonly && count == 0">1件も登録されていません</div>
    <div v-else>
      <v-card class="tab-editor-wrap">
        <v-card-text>
          <v-tabs
            v-model="tabs"
            vertical
            @change="tabChange($event)"
          >
            <v-tab
              v-for="(data, i) in competitors.addItems"
              :key="i"
              class="justify-start tab"
            >
              <div class="service-name">{{ data.targetName }}</div>
              <div class="d-flex">
                <v-btn
                  v-show="!(!hasWritePermission || refMode)"
                  :disabled="i == 0"
                  x-small
                  color="gray"
                  plain
                  fab
                  @click.stop="moveUpCompetitor(i)"
                  width="25"
                >
                  <v-icon
                    color="gray"
                  >mdi-arrow-up-bold</v-icon>
                </v-btn>
                <v-btn
                  v-show="!(!hasWritePermission || refMode)"
                  :disabled="i == competitors.addItems.length - 1"
                  x-small
                  color="gray"
                  plain
                  fab
                  @click.stop="moveDownCompetitor(i)"
                  width="25"
                >
                  <v-icon
                    color="gray"
                  >mdi-arrow-down-bold</v-icon>
                </v-btn>
                <v-btn
                  v-show="!(!hasWritePermission || refMode)"
                  x-small
                  color="gray"
                  plain
                  fab
                  @click="showDialog(i)"
                  width="25"
                >
                  <v-icon
                    color="gray"
                  >mdi-trash-can</v-icon>
                </v-btn>
              </div>
            </v-tab>
            <!-- dialog -->
            <v-dialog
              v-model="dialog"
              persistent
              max-width="360px"
            >
              <v-card>
                <v-card-title class="headline"></v-card-title>
                <v-card-text>削除してもよろしいですか？</v-card-text>
                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    class="pa-3 mr-3 text-body-1 white--text"
                    width="126"
                    color="btPrimary"
                    large
                    depressed
                    @click="dialog = false; deleteItem()"
                  >
                    削除
                  </v-btn>
                  <v-btn
                    class="pa-3 text-body-1"
                    width="126"
                    color="grey lighten-2"
                    large
                    depressed
                    @click="dialog = false"
                  >
                    キャンセル
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- dialog -->
            <div class="justify-start mt-3" v-show="!(!hasWritePermission || refMode)">
              <v-btn
                v-show="hasWritePermission"
                depressed
                tile
                color="btPrimary"
                class="white--text"
                @click="addCompetitor"
              >
                競合を追加
                <v-icon
                  small
                  color="white"
                  class="ml-1"
                >mdi-plus-thick</v-icon>
              </v-btn>
            </div>
            <v-tab-item
              v-for="(data, idx) in competitors.addItems"
              :key="idx"
              class="tab-item-border"
              transition="slide-y-transition"
            >
              <v-card flat>
                <v-card-text class="py-0">
                  <!-- 競合名 -->
                  <div class="mb-2">
                    <span class="text-caption py-1 black--text"
                      >・{{ targetName }}</span
                    >
                    <v-text-field
                      v-model="data.targetName"
                      outlined
                      hide-details
                      class="competitor-name"
                      :readonly="readonly"
                    ></v-text-field>
                  </div>

                  <!-- 競合名 -->
                  <div class="mb-2">
                    <span class="text-caption py-1 black--text">・URL</span>
                    <v-text-field
                      v-model="data.targetUrl"
                      class="competitor-url"
                      :hint="data.targetUrl"
                      persistent-hint
                      outlined
                      :readonly="readonly"
                    >
                      <template v-slot:message="{ message }">
                        <a :href="message" target="_blank">{{ message }}</a>
                      </template>
                    </v-text-field>
                  </div>
                  <span class="text-caption py-1 black--text">・特長</span>
                  <v-textarea
                    v-model="data.targetFeatures"
                    outlined
                    auto-grow
                    hide-details
                    :readonly="readonly"
                  ></v-textarea>
                  <div class="mt-3">
                    <v-checkbox
                      v-model="data.clientInstructed"
                      label="クライアント指定"
                      color="btPrimary"
                      hide-details
                      :readonly="readonly"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import InputSample from "../../atoms/InputSample.vue";
import PartsElementLabel from "../../atoms/PartsElementLabel.vue";

export default {
  components: {
    InputSample,
    PartsElementLabel,
  },
  props: ["value", "readonly", "refMode"],
  data() {
    return {
      competitors: {
        addItems: [
          {
            targetName: null,
            targetUrl: null,
            targetFeatures: null,
            clientInstructed: null,
          },
        ],
      },

      tabs: 0,
      currentTab: 0,

      tab: null,
      dialog: false,
      delete_data: null,

      initDialog: false,
    };
  },
  computed: {
    ...mapGetters(["project", "hasWritePermission"]),
    count() {
      return this.competitors.addItems.length;
    },
    targetName() {
      return this.project.industry == "歯科" ? "医院名" : "競合名";
    },
  },
  created() {
    if (this.value) {
      this.competitors = this.value;
      if(!this.competitors.addItems?.length) {
        this.addCompetitor();
      }
    } 
  },
  methods: {
    //競合分析セクションの未入力チェック
    isEmpty() {
      if (!this.competitors) return true;

      const competitors = this.competitors;
      let ret = false;

      //追加質問項目をチェック
      if (competitors.addItems && competitors.addItems.length > 0) {
        ret = competitors.addItems.some(
          (i) =>
            i.targetName ||
            i.targetUrl ||
            i.targetFeatures ||
            i.clientInstructed
        );
        if (ret) return false;
      }

      return true;
    },
    addCompetitor() {
      this.competitors.addItems.push({
        targetName: "新しい競合",
        targetUrl: null,
        targetFeatures: null,
        clientInstructed: null,
      });

      this.tabs = this.competitors.addItems.length-1
      this.tabChange(this.competitors.addItems.length-1)
    },
    deleteCompetitor(i) {
      this.competitors.addItems.splice(i, 1);
    },
    moveUpCompetitor(i) {
      let currentItems = this.competitors.addItems.concat();
      this.competitors.addItems.splice(
        i - 1,
        2,
        currentItems[i],
        currentItems[i - 1]
      );
    },
    moveDownCompetitor(i) {
      let currentItems = this.competitors.addItems.concat();
      this.competitors.addItems.splice(
        i,
        2,
        currentItems[i + 1],
        currentItems[i]
      );
    },

    deleteItem() {
      this.competitors.addItems.splice(this.delete_data, 1)
    },

    showDialog(i)　{
      this.dialog = true
      this.delete_data = i
    },
    
    tabChange(i) {
      if (typeof i !== 'undefined') {
        this.currentTab = i
      }
    },
  },
  watch: {
    value(val) {
      this.competitors = val;
    },
    "competitors.inputStatusCompetitive"(val) {
      this.$store.commit("brand_input_status_competitors", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.competitors-wrap {
  min-width: 700px;
  .competitor-name {
    max-width: 700px;
  }
  .competitor-url {
    max-width: 700px;
  }
  .input-sample {
    margin-top: -3px;
  }
  .v-input--selection-controls {
    margin-top: 0 !important;
  }
  .tab-editor-wrap {
  max-width: 1040px;
  padding: 15px;
  }
  .input-sample {
    margin-top: -3px;
  }
}
</style>

<style lang="scss">
  .tab-editor-wrap {
    .tab{
      max-width: 260px!important;
      min-width: 260px!important;
    }
  }
  .service-name{
      width: 270px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
  }
  .tab-item-border{
    border-left: solid 3px #ECEFF1;
  }
  .v-avatar img {
    object-fit: cover; 
  }
  @media screen and (max-width: 1024px) {
    .tab{
      width: 200px!important;
    }
  }
  /* セレクトボックスの選択肢の色をreadonly時に変化させない対応 */
  .theme--light .v-select .v-select__selection--disabled {
    color: rgba(0,0,0,.87)
  }
</style>
