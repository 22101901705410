<template>
  <div>
    <p class="text-center text-subtitle-1">{{ caption }}</p>
    <v-slider
      :value="value"
      :min="min"
      :max="max"
      @input="update($event)"
      @change="update($event)"
      :tick-labels="tickLabels"
      ticks="always"
      color="grey"
      track-color="grey"
      :thumb-color="color"
      :readonly="readonly"
      class="tick-label"
    >
      <template v-slot:prepend>
        <div class="label-block">
          <span class="br text-body-2">{{ prependLabel }}</span>
        </div>
      </template>

      <template v-slot:append>
        <div class="label-block">
          <span class="br text-body-2">{{ appendLabel }}</span>
        </div>
      </template>
    </v-slider>
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      require: true,
      default: '',
    },
    prependLabel: {
      type: String,
      require: true,
      default: '',
    },
    appendLabel: {
      type: String,
      require: true,
      default: '',
    },
    value: {
      type: Number,
      require: true,
      default: 0,
    },
    min: {
      type: Number,
      require: false,
      default: 0,
    },
    max: {
      type: Number,
      require: false,
      default: 10,
    },
    tickLabels: {
      type: Array,
      require: false,
      default: () => [],
    },
    readonly: Boolean,
  },

  methods: {
    // データの更新
    update(value) {
      this.$emit('input', value)
    }
  },

  computed: {
    color() {
      if (this.value < this.colorNumberArray[0]) return 'indigo'
      if (this.value < this.colorNumberArray[1]) return 'teal'
      if (this.value < this.colorNumberArray[2]) return 'green'
      if (this.value < this.colorNumberArray[3]) return 'orange'
      return 'red'
    },
    colorNumberArray() {
      const count = ((this.max - this.min) + 1)
      const num = count / 5
      const surplus = count % 2
      return [
        num + this.min - surplus,
        (num * 2) + this.min - surplus,
        (num * 3) + this.min,
        (num * 4) + this.min
      ]
    },
  },
}
</script>

<style scoped>
.br {
  white-space: pre-line;
}

.label-block {
  width: 120px;
  text-align: center;
  color: #333333;
}

.tick-label {
  font-size: 14px;
  color: #AAAAAA;
}
</style>
