<script>
// BPシート
import ManegementPhilosophy from "@/components/atomic/organisms/parts/ManegementPhilosophy";
import BrandNumInfo from "@/components/atomic/organisms/parts/BrandNumInfo";
import CompanyVision from "@/components/atomic/organisms/parts/CompanyVision";
import CurrentStrategyAndAction from "@/components/atomic/organisms/parts/CurrentStrategyAndAction";
// KPI設計
import TargetValues from "@/components/atomic/organisms/parts/TargetValues";
// 事業理解
import Service from "@/components/atomic/organisms/parts/Service";
import Competitors from "@/components/atomic/organisms/parts/Competitors";
import BusinessModelCanvas from "@/components/atomic/organisms/parts/BusinessModelCanvas";
// 市場環境分析
import PestAnalysis from "@/components/atomic/organisms/parts/PestAnalysis";
import FiveForcesAnalysis from "@/components/atomic/organisms/parts/FiveForcesAnalysis";
import ThreeCsAnalysis from "@/components/atomic/organisms/parts/ThreeCsAnalysis";
import SwotAnalysis from "@/components/atomic/organisms/parts/SwotAnalysis.vue";
// 戦略/施策立案
import StpAnalysis from "@/components/atomic/organisms/parts/StpAnalysis";
import FourPsFourCsAnalysis from "@/components/atomic/organisms/parts/FourPsFourCsAnalysis";
import FourPsComparisonAnalysis from "@/components/atomic/organisms/parts/FourPsComparisonAnalysis";
import TargetUser from "@/components/atomic/organisms/parts/TargetUser";
import PersonaInfo from "@/components/atomic/organisms/parts/PersonaInfo";
// デザイン要件
import DesignRequirements from "@/components/atomic/organisms/parts/DesignRequirements";
// プランニング
import PlanningSheet from "@/components/atomic/organisms/parts/PlanningSheet";

export default {
  props: {
    value: {},
    hasWritePermission: {},
    dataInit: Boolean,
    elementId: String,
    name: String,
    inputStatus: String,
    refMode: Boolean,
    refFullExpand: Boolean,
    noteMode: Boolean,
  },

  data() {
    return {
      components: {
        // BPシート
        ManegementPhilosophy,
        BrandNumInfo,
        CompanyVision,
        CurrentStrategyAndAction,
        // KPI設計
        TargetValues,
        // 事業理解
        Service,
        Competitors,
        BusinessModelCanvas,
        // 市場環境分析
        PestAnalysis,
        FiveForcesAnalysis,
        ThreeCsAnalysis,
        SwotAnalysis,
        // 戦略/施策立案
        StpAnalysis,
        FourPsFourCsAnalysis,
        FourPsComparisonAnalysis,
        TargetUser,
        PersonaInfo,
        // デザイン要件
        DesignRequirements,
        // プランニング
        PlanningSheet,
      },

      el: null,
      need_notInput: false, //未入力・編集中チェック要否フラグ
    };
  },

  render(h) {
    const comp = this.components[this.name];

    return (this.el = h(comp, {
      props: {
        value: this.value,
        readonly: !this.hasWritePermission || this.inputStatus == "done",
        dataInit: this.dataInit,
        refMode: this.refMode,
        refFullExpand: this.refFullExpand,
        noteMode: this.noteMode,
      },
      domProps: {
        id: this.elementId,
      },
      on: {
        input: (e) => this.$emit("input", e.target.value),
        update: (e) => this.$emit("update"),
      },
    }));
  },

  methods: {
    timeredCheck() {
      if (this.el) {
        if (
          this.value &&
          this.value[this.inputStatus] != "done" &&
          this.need_notInput
        ) {
          if (this.isEmpty()) {
            if (this.value[this.inputStatus] != "notInput")
              this.value[this.inputStatus] = "notInput";
          } else {
            if (this.value[this.inputStatus] != "edit")
              this.value[this.inputStatus] = "edit";
          }
          this.need_notInput = false;
        }
      }
    },
    isEmpty() {
      if (this.el) {
        return this.el.child.isEmpty();
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$emit("mounted");
  },
  watch: {
    value: {
      handler() {
        if (this.dataInit) {
          this.need_notInput = true; //未入力チェック必要、でフラグ立て
          this.$emit("update");
        }
      },
      deep: true,
    },
  },
};
</script>
