<template>
  <v-container :class="classVal">
    {{ $store.getters.msgNoData }}
  </v-container>
</template>

<script>
export default {
  props: {
    classVal: String,
  },
};
</script>