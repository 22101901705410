var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.isShowRefs(_vm.refMode, [
      _vm.planning.issue.extraction,
      _vm.planning.objective.quantification,
      _vm.planning.objective.qualification,
      _vm.planning.reference.concept,
      _vm.planning.reference.vision,
      _vm.planning.reference.tagline,
      _vm.planning.plan.summary ])
  )?_c('div',{staticClass:"sp-common-width-narrow ref-mode"},[(_vm.planning.issue)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowRef(_vm.refMode, _vm.planning.issue.extraction)),expression:"isShowRef(refMode, planning.issue.extraction)"}]},[_c('parts-input-textarea',{staticClass:"mb-6",attrs:{"label":"課題抽出","readonly":!_vm.hasWritePermission || _vm.refMode,"reference":"PlanningIssue_extraction.txt","refDoc":true,"refMode":_vm.refMode},model:{value:(_vm.planning.issue.extraction),callback:function ($$v) {_vm.$set(_vm.planning.issue, "extraction", $$v)},expression:"planning.issue.extraction"}})],1):_vm._e(),(_vm.planning.objective)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.isShowRefs(_vm.refMode, [
        _vm.planning.objective.quantification,
        _vm.planning.objective.qualification ])
    ),expression:"\n      isShowRefs(refMode, [\n        planning.objective.quantification,\n        planning.objective.qualification,\n      ])\n    "}]},[_c('parts-element-label',{staticClass:"mb-3",attrs:{"label":"目標","readonly":!_vm.hasWritePermission || _vm.refMode}}),_c('div',{staticClass:"mb-6"},[_c('planning-objective',{attrs:{"readonly":!_vm.hasWritePermission || _vm.refMode,"refMode":_vm.refMode},model:{value:(_vm.planning.objective),callback:function ($$v) {_vm.$set(_vm.planning, "objective", $$v)},expression:"planning.objective"}})],1)],1):_vm._e(),(_vm.planning.reference)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.isShowRefs(_vm.refMode, [
        _vm.planning.reference.concept,
        _vm.planning.reference.vision,
        _vm.planning.reference.tagline ])
    ),expression:"\n      isShowRefs(refMode, [\n        planning.reference.concept,\n        planning.reference.vision,\n        planning.reference.tagline,\n      ])\n    "}]},[_c('parts-element-label',{staticClass:"mb-3",attrs:{"label":"戦略整理","readonly":!_vm.hasWritePermission}}),_c('div',{staticClass:"mb-7"},[_c('hearing-and-analysing-reference',{staticClass:"mx-auto",attrs:{"readonly":!_vm.hasWritePermission || _vm.refMode,"refMode":_vm.refMode},model:{value:(_vm.planning.reference),callback:function ($$v) {_vm.$set(_vm.planning, "reference", $$v)},expression:"planning.reference"}})],1)],1):_vm._e(),(_vm.planning.plan)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowRef(_vm.refMode, _vm.planning.plan.summary)),expression:"isShowRef(refMode, planning.plan.summary)"}]},[_c('parts-element-label',{staticClass:"mb-3",attrs:{"label":"戦略まとめ","readonly":!_vm.hasWritePermission || _vm.refMode,"disabled":_vm.planning.plan.summary &&
        _vm.planning.plan.summary.length < _vm.requirednum_plan_summary,"refDoc":true,"reference":"PlanningSummary_summary.txt"}}),_c('div',[_c('planning-summary',{attrs:{"requirednum":_vm.requirednum_plan_summary,"readonly":!_vm.hasWritePermission || _vm.refMode},model:{value:(_vm.planning.plan.summary),callback:function ($$v) {_vm.$set(_vm.planning.plan, "summary", $$v)},expression:"planning.plan.summary"}})],1)],1):_vm._e()]):_c('text-no-data',{attrs:{"classVal":"sp-common-width-narrow ref-mode text-center"}})}
var staticRenderFns = []

export { render, staticRenderFns }