<template>
  <div class="pt-0">
    <v-textarea-with-link
      class="mb-6"
      v-model="issue.extraction"
      label="課題抽出"
      :readonly="readonly"
      reference="PlanningIssue_extraction.txt"
      :refDoc="true"
    ></v-textarea-with-link>
  </div>
</template>
<script>
import VTextareaWithLink from '../atoms/VTextareaWithLink.vue';

export default {
  components: {
    VTextareaWithLink,
  },
  props: ['value', 'readonly'],
  data () {
    return {
      issue: {
        extraction: null,
      },
    }
  },
  created(){
    if(this.value) {
      this.issue = this.value
    }
  },
  watch: {
    value(val) {
      this.issue = val
    },
  }
}
</script>