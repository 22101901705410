<template>
  <div
    class="sp-common-width-narrow ref-mode"
    v-if="
      isShowRefs(refMode, [
        planning.issue.extraction,
        planning.objective.quantification,
        planning.objective.qualification,
        planning.reference.concept,
        planning.reference.vision,
        planning.reference.tagline,
        planning.plan.summary,
      ])
    "
  >
    <div
      v-if="planning.issue"
      v-show="isShowRef(refMode, planning.issue.extraction)"
    >
      <!-- 課題 -->
      <parts-input-textarea
        class="mb-6"
        v-model="planning.issue.extraction"
        label="課題抽出"
        :readonly="!hasWritePermission || refMode"
        reference="PlanningIssue_extraction.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
    <div
      v-if="planning.objective"
      v-show="
        isShowRefs(refMode, [
          planning.objective.quantification,
          planning.objective.qualification,
        ])
      "
    >
      <parts-element-label
        label="目標"
        :readonly="!hasWritePermission || refMode"
        class="mb-3"
      ></parts-element-label>
      <div class="mb-6">
        <!-- 目標 -->
        <planning-objective
          v-model="planning.objective"
          :readonly="!hasWritePermission || refMode"
          :refMode="refMode"
        ></planning-objective>
      </div>
    </div>
    <div
      v-if="planning.reference"
      v-show="
        isShowRefs(refMode, [
          planning.reference.concept,
          planning.reference.vision,
          planning.reference.tagline,
        ])
      "
    >
      <parts-element-label
        label="戦略整理"
        :readonly="!hasWritePermission"
        class="mb-3"
      ></parts-element-label>
      <div class="mb-7">
        <hearing-and-analysing-reference
          v-model="planning.reference"
          :readonly="!hasWritePermission || refMode"
          class="mx-auto"
          :refMode="refMode"
        ></hearing-and-analysing-reference>
      </div>
    </div>
    <div
      v-if="planning.plan"
      v-show="isShowRef(refMode, planning.plan.summary)"
    >
      <parts-element-label
        label="戦略まとめ"
        :readonly="!hasWritePermission || refMode"
        :disabled="
          planning.plan.summary &&
          planning.plan.summary.length < requirednum_plan_summary
        "
        class="mb-3"
        :refDoc="true"
        reference="PlanningSummary_summary.txt"
      ></parts-element-label>
      <div>
        <!-- 戦略まとめ -->
        <planning-summary
          v-model="planning.plan.summary"
          :requirednum="requirednum_plan_summary"
          :readonly="!hasWritePermission || refMode"
        ></planning-summary>
      </div>
    </div>
  </div>
  <text-no-data v-else classVal="sp-common-width-narrow ref-mode text-center" />
</template>

<script>
import { mapGetters } from "vuex";

import VExpansionPanelHeaderWithLabel from "../../atoms/VExpansionPanelHeaderWithLabel";
import VTextareaWithLink from "../../atoms/VTextareaWithLink.vue";
import PlanningIssue from "../../organisms/PlanningIssue";
import PlanningObjective from "../../organisms/PlanningObjective";
import HearingAndAnalysingReference from "../../organisms/HearingAndAnalysingReference";
import PlanningSummary from "../../organisms/PlanningSummary";
import SwotMatrix from "../../molecules/SwotMatrix";
import TargetUser from "../../organisms/TargetUser";
import PersonaInfo from "../../organisms/PersonaInfo";
import PositioningMapEditor from "../../organisms/PositioningMapEditor";
import Competitors from "../../organisms/bp/Competitors";
import ThumbsUpInput from "../../molecules/ThumbsUpInput";
import SheetInfo from "../../organisms/SheetInfo";
import PartsInputTextarea from "../../molecules/PartsInputTextarea.vue";
import PartsElementLabel from "../../atoms/PartsElementLabel.vue";
import TextNoData from "../../atoms/TextNoData.vue";

export default {
  components: {
    VExpansionPanelHeaderWithLabel,
    PlanningIssue,
    PlanningObjective,
    HearingAndAnalysingReference,
    PlanningSummary,
    SwotMatrix,
    TargetUser,
    PersonaInfo,
    PositioningMapEditor,
    Competitors,
    ThumbsUpInput,
    SheetInfo,
    PartsElementLabel,
    VTextareaWithLink,
    PartsInputTextarea,
    TextNoData,
  },

  props: ["value", "refMode"],
  data() {
    return {
      requirednum_plan_summary: 120, //戦略情報まとめの必要文字数

      expansionPanelsValues: [0, 1, 2, 3],

      //### データ関連 ###
      dataInit: false,
      needUpdate: false, //更新API呼出の要否フラグ

      planning: null, //このタブのメインデータ
    };
  },

  created() {
    //データ初期化
    if (this.value) {
      this.planning = this.value;
    }
  },
  beforeDestroy() {},
  computed: {
    ...mapGetters(["project", "hasWritePermission"]),
  },
  methods: {
    moveOther(tab, selector) {
      this.$emit("move-other", { tab, selector });
    },
  },

  watch: {
    value(to) {
      if (this.value) {
        this.planning = to;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bottommost-section {
  margin-bottom: 420px;
}
.reference-link {
  font-size: 0.85em;
  text-align: right;
}
.reference-title-hr {
  background-color: #1565c0;
}
.strategy-contents-container {
  max-width: 1040px;
}
</style>
<style lang="scss">
.sheet-info-wrap {
  .head {
    font-weight: 600;
  }
}
</style>