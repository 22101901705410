<template>
  <div>
    <v-card class="positioning-map-tab ref-mode" v-click-outside="saveAll">
      <v-card-text>
        <v-tabs
          v-model="tabs"
          @change="tabChange($event)"
        >
          <v-tab
            v-for="(data, i) in positioning"
            :key="i"
            class="justify-start tab"
          >
            <div>
              <div class="tab-item-name"><span v-html="positioningMapNameViews[i]"></span></div>
            </div>
            <div class="d-flex">
              <v-btn
                v-show="!readonly"
                :disabled="i == 0"
                x-small
                color="gray"
                plain
                fab
                @click.stop="inFront(i)"
                width="25"
              >
                <v-icon
                  color="gray"
                >mdi-arrow-left-bold</v-icon>
              </v-btn>
              <v-btn
                v-show="!readonly"
                :disabled="i == positioning.length - 1"
                x-small
                color="gray"
                plain
                fab
                @click.stop="behind(i)"
                width="25"
              >
                <v-icon
                  color="gray"
                >mdi-arrow-right-bold</v-icon>
              </v-btn>
              <v-btn
                v-show="!readonly"
                x-small
                color="gray"
                plain
                fab
                @click="showDialog(i)"
                width="25"
              >
                <v-icon
                  color="gray"
                >mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </v-tab>
          <!-- dialog -->
          <v-dialog
            v-model="dialog"
            persistent
            max-width="360px"
          >
            <v-card>
              <v-card-title class="headline"></v-card-title>
              <v-card-text>削除してもよろしいですか？</v-card-text>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  class="pa-3 mr-3 text-body-1"
                  width="126"
                  color="primary"
                  large
                  depressed
                  @click="dialog = false; deleteItem()"
                >
                  削除
                </v-btn>
                <v-btn
                  class="pa-3 text-body-1"
                  width="126"
                  color="grey lighten-2"
                  large
                  depressed
                  @click="dialog = false"
                >
                  キャンセル
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- dialog -->
          <div class="d-flex align-center ml-3">
            <v-btn
              v-show="!readonly"
              depressed
              color="btPrimary"
              class="white--text"
              @click="addItem()"
            >
              追加
              <v-icon
                small
                color="white"
                class="ml-1"
              >mdi-plus-thick</v-icon>
            </v-btn>
          </div>
          <v-tab-item
            v-for="(data, idx) in positioning"
            :key="idx"
            class="tab-item-border tab-content"
            transition="slide-y-transition"
          >
            <v-card flat>
              <v-card-text class="py-0 black--text">
                <div v-show="!refMode">
                  <div class="mb-2">
                    ①選ばれる理由からポジショニングマップの軸になる要素を選んで下さい
                  </div>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        v-model="data.positioningVertical"
                        label="縦軸"
                        outlined
                        :items="differentiation"
                        no-data-text="選択できるデータがありません。"
                        @change="setPositioningmapName(idx)"
                        :readonly="!hasWritePermission || refMode"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="data.positioningHorizontal"
                        label="横軸"
                        outlined
                        :items="differentiation"
                        no-data-text="選択できるデータがありません。"
                        @change="setPositioningmapName(idx)"
                        :readonly="!hasWritePermission || refMode"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <div>
                    ②ポジショニングマップを編集して、クライアントや競合の立ち位置を明確にしてください
                  </div>
                </div>
                <v-row class="ma-0 pa-0">
                  <v-col align="right" class="ma-0 pa-0">
                    <div v-show="!readonly">
                      <input-sample
                        class="input-sample"
                        :refImg="true"
                        reference="PositioningMap_image.jpg"
                        label="ポジショニングマップ"
                      ></input-sample>
                    </div>
                  </v-col>
                </v-row>
                <positioning-map-editor
                  ref="editor"
                  v-model="data.positioningPositioningmap"
                  :sectionReadonly="readonly"
                  class="mb-8"
                  :class="readonly ? 'mt-6' : ''"
                  :labelVertical="data.positioningVertical"
                  :labelHorizontal="data.positioningHorizontal"
                  :competitors="competitors"
                  :noteMode="noteMode"
                  :key="idx + positioning * cnt"
                ></positioning-map-editor>

                <div>
                  <div class="mb-4">
                    <div class="mb-1">③ポジショニングマップに名前を付けてください</div>
                    <v-text-field
                      :value="data.positioningmapName"
                      @blur="data.positioningmapName = $event.target._value"
                      outlined
                      dense
                      hide-details
                      :readonly="!hasWritePermission || refMode"
                    ></v-text-field>
                  </div>
                  <div class="mb-2">
                    まとめ
                  </div>
                  <v-textarea 
                    :value="data.positioningSummary"
                    @blur="data.positioningSummary = $event.target._value"
                    :readonly="!hasWritePermission || refMode"
                    outlined
                    hide-details=""
                  ></v-textarea>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PositioningMapEditor from "@/components/atomic/organisms/PositioningMapEditor.vue";
import InputSample from '../atoms/InputSample.vue';
import YesNoDialog from '../atoms/YesNoDialog.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    PositioningMapEditor,
    InputSample,
    YesNoDialog,
  },
  props: ['value', 'readonly', 'refMode', 'competitors', 'noteMode', 'differentiation'],
  data () {
    return {
      tabs: 0,
      currentTab: 0,

      positioning: [],

      tab: null,
      dialog: false,
      delete_data: null,

      cnt: 1,
    }
  },
  computed: {
    ...mapGetters(['project', "hasWritePermission"]),

    positioningMapNameViews() {
      let rtn = [] 
      // '×' が含まれている場合には '×' で改行する
      rtn = this.positioning.map(d => { 
        let name = !!d.positioningmapName ? d.positioningmapName : '';
        let idx = name.indexOf('×');
        if(idx == -1) return name;
        return name.replace('×', '<br>× ');
      });
      return rtn;
    }
  },
  created(){
    this.positioning = this.value
    if(this.value.length) {
      this.checkSeq();
      this.sort();
    } else {
      this.addItem()
    }
  },
  watch: {
    value(val) {
      this.positioning = val
    },
  },
  methods: {
    deleteItem: function(){
      this.positioning.splice(this.delete_data, 1)
      this.sort();
      this.cnt++;
    },
    addItem: function(){
      this.positioning.push(
          {
            positioningVertical: null,
            positioningHorizontal: null,
            positioningmapName: null,
            positioningPositioningmap: null,
            positioningSummary: null,
            seq: this.positioning.length + 1,
          }
        )

      this.tabs = this.positioning.length-1
      this.tabChange(this.positioning.length-1)
    },
    showDialog: function(i){
      this.dialog = true
      this.delete_data = i
    },
    tabChange: function(i) {
      if (typeof i !== 'undefined') {
        this.currentTab = i
      }
    },
    inFront(idx) {
      [this.positioning[idx].seq, this.positioning[idx - 1].seq] = [this.positioning[idx - 1].seq, this.positioning[idx].seq]
      this.sort()
      this.cnt++;
    },
    behind(idx) {
      [this.positioning[idx].seq, this.positioning[idx + 1].seq] = [this.positioning[idx + 1].seq, this.positioning[idx].seq]
      this.sort()
      this.cnt++;
    },
    sort() {
      this.positioning.sort((a,b) => {
        return (a.seq < b.seq) ? -1 : 1;
      });
      this.setSeq()
    },

    checkSeq() {
      // ソートキーがない要素が1つでもあれば振りなおす
      const noSeq = this.positioning.filter(item => !item?.seq);
      if(!noSeq.length) return;

      this.setSeq()
    },

    setSeq() {
      this.positioning.forEach((item, idx) => {
        item.seq = idx + 1;
      });
    },

    saveAll() {
      if(!this.$refs.editor?.length) return;

      // タブに含まれる全てのポジショニングマップエディタを保存する
      for(let i = 0; i < this.$refs.editor.length; i++) {
        this.$refs.editor[i].save();
      }
    },

    setPositioningmapName(i) {
      const lblV = !!this.positioning[i].positioningVertical ? this.positioning[i].positioningVertical : '';
      const lblH = !!this.positioning[i].positioningHorizontal ? this.positioning[i].positioningHorizontal : '';
      this.positioning[i].positioningmapName = `${lblV}×${lblH}`
    },
  },
}
</script>
<style lang="scss" scoped>
.positioning-map-tab {
  min-width: 800px;
  max-width: 1040px;
  padding: 15px;
}
.input-sample {
  margin-top: -3px;
}
.tab-item-name{
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  text-transform: none;
}
.tab-content {
  margin-top: 15px;
  padding-top: 15px;
  border-top: solid 3px #ECEFF1;
  border-left: none;
}
</style>
<style lang="scss">
.positioning-map-tab {
  .tab{
    max-width: 260px!important;
    min-width: 260px!important;
  }
}
/* セレクトボックスの選択肢の色をreadonly時に変化させない対応 */
.theme--light .v-select .v-select__selection--disabled {
  color: rgba(0,0,0,.87)
}
</style>