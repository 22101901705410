/**
 * デザイン要件 Request Item Class.
 *
 */

export default class DesignRequirementsItem {
  image = new Image();
  note = new Note();
  parameter = new Parameter();
  referencesite = new Referencesite();
  // レギュレーション有無
  designRegulation = null;
  // レギュレーション内容
  regulationDetail = "";
  // 重要コンテンツ
  priorityContents = "";
  // 対策キーワード
  targetKeyword = new TargetKeyword();
}

class Image {
  designKeyword = [];
  font = [];
  logo = {
    logoMeaning: null,
    logoUrl: null,
  };
  mainColor = null;
  referenceImages = [];
  sub1Color = null;
  sub2Color = null;
  sub3Color = null;
  taste = null;
}

class Note {
  designNote = null;
}

class Referencesite {
  data = [];
}

class Parameter {
  parameters = [];
}

class TargetKeyword {
  mainKeyword = null;
  mainKeywordAssess = null;
  subKeyword = null;
  subKeywordAssess = null;
}