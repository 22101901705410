/**
 * PEST分析情報 Request Item Class.
 *
 */

export default class PestRequestItem {

  // 政治的要因
  pestPolitics = null;
  // 経済的要因
  pestEconomy = null;
  // 社会的要因
  pestSociety = null;
  // 技術的要因
  pestTechnology = null;
  // PEST分析まとめ
  pestSummary = null;
  // 入力ステータス
  inputStatusPest = null;

}