/**
 * 現状の戦略と実施施策 Request Item Class.
 *
 */

export default class CurrentRequestItem {

  // 戦略方針
  strategicPolicy = null;
  // オンライン施策の詳細
  measuresOnlineAttract = null;
  // オフライン施策の詳細
  measuresOfflineAttract = null;
  // 現在実施している施策（オンライン）
  measuresWeb = [];
  // 現在実施している施策（オフライン）
  measuresReal = [];
  // 施策評価
  measuresEvaluation = null;
  // 現状の戦略と実施施策の入力ステータス
  inputStatusCurrent = null;

}