/**
 * STP分析情報 Request Item Class.
 *
 */

 export default class StpRequestItem {

  // セグメンテーション
  stpSegmentation = null;
  // ターゲット
  stpTarget = null;
  // 差別化ポイント
  stpDifferentiation = null;
  // 競合名
  stpCompetitor = [];
  // ポジショニング
  positioning = [];
  // STP分析まとめ
  stpSummary = null;
  // 入力ステータス
  inputStatusStp = null;

}