<template>
  <div>
    <v-row>
      <v-col sm="12" v-show="isShowRef(refMode, objective.quantification)">
        <div class="text-caption">・定量化目標</div>
        <v-textarea
          v-model="objective.quantification"
          outlined
          auto-grow
          :readonly="readonly"
          hide-details=""
        ></v-textarea>
      </v-col>
      <v-col sm="12" class="pt-0 py-5"  v-show="isShowRef(refMode, objective.qualification)">
        <div class="text-caption">・定性化目標</div>
        <v-textarea
          v-model="objective.qualification"
          outlined
          auto-grow
          :readonly="readonly"
          hide-details=""
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ['value', 'readonly', 'refMode'],
  data () {
    return {
      objective: {
        quantification: null,
        qualification: null,
      },
    }
  },
  created(){
    if(this.value) {
      this.objective = this.value
    }
  },
  watch: {
    value(val) {
      this.objective = val
    },
  },
}
</script>