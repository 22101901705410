/**
 * 目標数値 Request Item Class.
 *
 */
 import TargetValuesGeneralItem from "./TargetValuesGeneralItem";
 import TargetValuesDentistryItem from "./TargetValuesDentistryItem";
 import TargetValuesRealEstateItem from "./TargetValuesRealEstateItem";
 import TargetValuesBuildingItem from "./TargetValuesBuildingItem";
 import TargetValuesMarketingItem from "./TargetValuesMarketingItem";

export default class TargetValuesRequestItem {

  // 目標数値ID
  targetValuesId = null;

  // 業績(汎用)
  general = new TargetValuesGeneralItem;

  // 業績(歯科)
  dentistry = new TargetValuesDentistryItem;

  // 業績(不動産)
  realEstate = new TargetValuesRealEstateItem;

  // 業績(建築)
  building = new TargetValuesBuildingItem;

  // マーケティング目標
  marketing = new TargetValuesMarketingItem;

  // 入力ステータス
  inputStatusTargetValues = null;

}