<template>
  <div class="ref-mode pt-1 px-0">
    <!-- 横スクロールバー上 -->
    <div
      id="FourPsComparisonAnalysis_topbar"
      class="x-scroll mb-1"
      :style="!refMode ? scrollAreaStyle : ''"
      v-scroll.self="onScrollXTopBar"
    >
      <div :style="!refMode ? contentAreaStyle : ''">
        <div
          :style="{
            width: contentAreaWidth + 'px',
            maxWidth: 'none',
            height: '1px',
          }"
        ></div>
      </div>
    </div>
    <!-- 4P競合分析 ＋ 横スクロールバー下 -->
    <div
      id="FourPsComparisonAnalysis_content"
      class="x-scroll mb-10"
      :style="!refMode ? scrollAreaStyle : ''"
      v-scroll.self="onScrollXContent"
    >
      <div :style="!refMode ? contentAreaStyle : ''">
        <v-container
          :style="{ width: contentAreaWidth + 'px', maxWidth: 'none' }"
        >
          <v-capture :visible="noteMode">
            <!-- 列エリア -->
            <div class="column-comparison ma-0 pa-0">
              <!-- 見出し -->
              <div>
                <v-row class="ma-0">
                  <v-col
                    class="four-ps-comparison__fixed-col pt-0 pb-1"
                    :class="readonly ? 'bg-refbar' : 'bg-white'"
                  ></v-col>
                  <v-col
                    class="
                      four-ps-comparison__header-col
                      four-ps-comparison__sticky-col
                      pt-0
                      pb-1
                    "
                    :class="readonly ? 'bg-refbar' : 'bg-white'"
                    align="center"
                    >自社</v-col
                  >
                  <!-- 競合(n個) -->
                  <v-col
                    class="four-ps-comparison__header-col pt-0 pb-1"
                    :class="readonly ? 'bg-refbar' : 'bg-white'"
                    align="center"
                    v-for="(competitor, i) in fourPsComparison.competitors"
                    :key="competitor.fourPsCompetitiveAnalysisId"
                  >
                    競合{{ i + 1 }}
                    <v-btn
                      v-show="!readonly"
                      fab
                      dark
                      x-small
                      elevation="0"
                      color="btPrimary"
                      class="float-right"
                      @click="deleteCompetitor(i)"
                    >
                      <v-icon dark dense>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- 競合追加（＋）ボタン -->
                <div class="float-right mt-5 mr-6" style="height: 100%">
                  <v-btn
                    v-show="!readonly"
                    color="btPrimary"
                    fab
                    light
                    outlined
                    depressed
                    @click="addCompetitor(1)"
                    width="45"
                    height="45"
                  >
                    <v-icon color="btPrimary">mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
              <!-- 列データ -->
              <v-row class="ma-0">
                <v-col
                  class="
                    four-ps-comparison__fixed-col
                    four-ps-comparison__added-border-right
                  "
                  :class="readonly ? 'bg-refbar' : 'bg-white'"
                ></v-col>
                <!-- 自社 -->
                <v-col
                  class="
                    four-ps-comparison__borderd-col
                    four-ps-comparison__sticky-col
                    bg-white
                    d-flex
                    justify-center
                    align-center
                    text-body-2
                  "
                  >{{ clientName }}</v-col
                >
                <!-- 競合(n個) -->
                <v-col
                  class="
                    four-ps-comparison__borderd-col
                    bg-white
                    d-inline-flex
                    align-center
                  "
                  v-for="(competitor, i) in fourPsComparison.competitors"
                  :key="competitor.fourPsCompetitiveAnalysisId"
                >
                  <v-btn
                    v-show="!readonly"
                    icon
                    color="btPrimary"
                    :disabled="i == 0"
                    @click="moveUpCompetitor(i)"
                  >
                    <v-icon>mdi-arrow-left-drop-circle-outline</v-icon>
                  </v-btn>
                  <v-combobox
                    v-model="competitor.targetName"
                    class="text-body-2"
                    :items="competitors"
                    :readonly="readonly"
                    hide-details
                    flat
                    solo
                    outlined
                  />
                  <v-btn
                    v-show="!readonly"
                    icon
                    color="btPrimary"
                    :disabled="i == itemCount - 1"
                    @click="moveDownCompetitor(i)"
                  >
                    <v-icon>mdi-arrow-right-drop-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <!-- 行エリア -->
            <div class="ma-0 pa-0">
              <!-- Product行 -->
              <v-row class="ma-0">
                <!-- Product見出し -->
                <v-col
                  class="
                    four-ps-comparison__fixed-col
                    four-ps-comparison__borderd-col
                    four-ps-comparison__added-border-left
                    bg-white
                  "
                  ><div align="center">
                    <p class="text-h6 mr-1">
                      <span class="red--text text--darken-1">P</span>roduct
                    </p>
                    <span class="text-subtitle-2">商品・サービス</span>
                  </div></v-col
                >
                <!-- Productデータ 自社 -->
                <v-col
                  class="
                    four-ps-comparison__borderd-col
                    four-ps-comparison__sticky-col
                    bg-white
                  "
                >
                  <v-textarea
                    v-model="fourPsComparison.product"
                    class="text-body-2"
                    rows="8"
                    no-resize
                    :readonly="readonly"
                    flat
                    solo
                    outlined
                    hide-details
                  ></v-textarea>
                </v-col>
                <!-- Productデータ 競合(n個) -->
                <v-col
                  class="four-ps-comparison__borderd-col bg-white"
                  v-for="competitor in fourPsComparison.competitors"
                  :key="competitor.fourPsCompetitiveAnalysisId"
                >
                  <v-textarea
                    v-model="competitor.product"
                    class="text-body-2"
                    rows="8"
                    no-resize
                    :readonly="readonly"
                    flat
                    solo
                    outlined
                    hide-details
                  ></v-textarea>
                </v-col>
              </v-row>
              <!-- Price行 -->
              <v-row class="ma-0">
                <!-- Price見出し -->
                <v-col
                  class="
                    four-ps-comparison__fixed-col
                    four-ps-comparison__borderd-col
                    four-ps-comparison__added-border-left
                    bg-white
                  "
                  ><div align="center">
                    <p class="text-h6 mr-1">
                      <span class="red--text text--darken-1">P</span>rice
                    </p>
                    <span class="text-subtitle-2">価格帯</span>
                  </div></v-col
                >
                <!-- Priceデータ 自社 -->
                <v-col
                  class="
                    four-ps-comparison__borderd-col
                    four-ps-comparison__sticky-col
                    bg-white
                  "
                >
                  <v-textarea
                    v-model="fourPsComparison.price"
                    class="text-body-2"
                    rows="8"
                    no-resize
                    :readonly="readonly"
                    flat
                    solo
                    outlined
                    hide-details
                  ></v-textarea>
                </v-col>
                <!-- Priceデータ 競合(n個) -->
                <v-col
                  class="four-ps-comparison__borderd-col bg-white"
                  v-for="competitor in fourPsComparison.competitors"
                  :key="competitor.fourPsCompetitiveAnalysisId"
                >
                  <v-textarea
                    v-model="competitor.price"
                    class="text-body-2"
                    rows="8"
                    no-resize
                    :readonly="readonly"
                    flat
                    solo
                    outlined
                    hide-details
                  ></v-textarea>
                </v-col>
              </v-row>
              <!-- Place行 -->
              <v-row class="ma-0">
                <!-- Place見出し -->
                <v-col
                  class="
                    four-ps-comparison__fixed-col
                    four-ps-comparison__borderd-col
                    four-ps-comparison__added-border-left
                    bg-white
                  "
                  ><div align="center">
                    <p class="text-h6 mr-1">
                      <span class="red--text text--darken-1">P</span>lace
                    </p>
                    <span class="text-subtitle-2">立地・流通方法</span>
                  </div></v-col
                >
                <!-- Placeデータ 自社 -->
                <v-col
                  class="
                    four-ps-comparison__borderd-col
                    four-ps-comparison__sticky-col
                    bg-white
                  "
                >
                  <v-textarea
                    v-model="fourPsComparison.place"
                    class="text-body-2"
                    rows="8"
                    no-resize
                    :readonly="readonly"
                    flat
                    solo
                    outlined
                    hide-details
                  ></v-textarea>
                </v-col>
                <!-- Priceデータ 競合(n個) -->
                <v-col
                  class="four-ps-comparison__borderd-col bg-white"
                  v-for="competitor in fourPsComparison.competitors"
                  :key="competitor.fourPsCompetitiveAnalysisId"
                >
                  <v-textarea
                    v-model="competitor.place"
                    class="text-body-2"
                    rows="8"
                    no-resize
                    :readonly="readonly"
                    flat
                    solo
                    outlined
                    hide-details
                  ></v-textarea>
                </v-col>
              </v-row>
              <!-- Promotion行 -->
              <v-row class="ma-0">
                <!-- Promotion見出し -->
                <v-col
                  class="
                    four-ps-comparison__fixed-col
                    four-ps-comparison__borderd-col-bottom
                    four-ps-comparison__added-border-left
                    bg-white
                  "
                  ><div align="center">
                    <p class="text-h6 mr-1">
                      <span class="red--text text--darken-1">P</span>romotion
                    </p>
                    <span class="text-subtitle-2">プロモーション</span>
                  </div></v-col
                >
                <!-- Promotionデータ 自社 -->
                <v-col
                  class="
                    four-ps-comparison__borderd-col-bottom
                    four-ps-comparison__sticky-col
                    bg-white
                  "
                >
                  <v-textarea
                    v-model="fourPsComparison.promotion"
                    class="text-body-2"
                    rows="8"
                    no-resize
                    :readonly="readonly"
                    flat
                    solo
                    outlined
                    hide-details
                  ></v-textarea>
                </v-col>
                <!-- Promotionデータ 競合(n個) -->
                <v-col
                  class="four-ps-comparison__borderd-col-bottom bg-white"
                  v-for="competitor in fourPsComparison.competitors"
                  :key="competitor.fourPsCompetitiveAnalysisId"
                >
                  <v-textarea
                    v-model="competitor.promotion"
                    class="text-body-2"
                    rows="8"
                    no-resize
                    :readonly="readonly"
                    flat
                    solo
                    outlined
                    hide-details
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
          </v-capture>
        </v-container>
      </div>
    </div>
    <div
      class="sp-common-width-narrow"
      v-show="isShowRef(refMode, fourPsComparison.summary)"
    >
      <!-- 4Pまとめ行 -->
      <parts-input-textarea
        v-model="fourPsComparison.summary"
        label="4P競合比較まとめ"
        rows="8"
        :readonly="readonly"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import VCapture from "../../atoms/VCapture.vue";

import PartsInputTextarea from "../../molecules/PartsInputTextarea.vue";

export default {
  components: {
    PartsInputTextarea,
    VCapture,
  },
  props: {
    value: Object,
    readonly: Boolean,
    refMode: Boolean,
    noteMode: Boolean,
  },
  data() {
    return {
      fourPsComparison: {
        product: null,
        price: null,
        place: null,
        promotion: null,
        competitors: [
          {
            fourPsCompetitiveAnalysisId: null,
            targetName: null,
            product: null,
            price: null,
            place: null,
            promotion: null,
          },
        ],
        summary: null,
      },
      // 定数として使用
      ADD_BUTTON_WIDTH: 130, // 競合追加（＋）ボタンのx軸幅
      ROW_HEADER_WIDTH: 130, // 行見出しのx軸幅
      COMPANY_COL_WIDTH: 480, // 自社列のx軸幅
      COMPETITOR_COL_WIDTH: 480, // 競合列のx軸幅
    };
  },
  computed: {
    ...mapGetters("relativeScrollableArea", [
      "scrollAreaStyle",
      "contentAreaStyle",
    ]),
    ...mapGetters(["project", "brand"]),
    contentAreaWidth() {
      return (
        this.ADD_BUTTON_WIDTH +
        this.ROW_HEADER_WIDTH +
        this.COMPANY_COL_WIDTH +
        this.COMPETITOR_COL_WIDTH * this.itemCount
      );
    },
    itemCount() {
      return this.fourPsComparison.competitors.length;
    },
    clientName() {
      // 自社名(顧客名)
      return this.project?.clientName ?? "";
    },
    // 競合情報
    competitors() {
      return this.brand?.competitors?.addItems?.map((item) => item.targetName);
    },
  },
  created() {
    if (this.value) {
      this.fourPsComparison = this.value;
    }
    if (!this.itemCount) {
      this.addCompetitor(2);
    }

    this.loadCompetitors();
  },
  methods: {
    ...mapActions(["fetchBrand"]),

    onScrollXTopBar(e) {
      document
        .getElementById("FourPsComparisonAnalysis_content")
        .scrollTo(e.target.scrollLeft, 0);
    },
    onScrollXContent(e) {
      document
        .getElementById("FourPsComparisonAnalysis_topbar")
        .scrollTo(e.target.scrollLeft, 0);
    },
    addCompetitor(n) {
      for (let i = 0; i < n; i++) {
        this.fourPsComparison.competitors.push({
          fourPsCompetitiveAnalysisId: null,
          targetName: null,
          product: null,
          price: null,
          place: null,
          promotion: null,
        });
      }
    },
    deleteCompetitor(i) {
      this.fourPsComparison.competitors.splice(i, 1);
    },
    moveUpCompetitor(i) {
      let currentItems = this.fourPsComparison.competitors.concat();
      this.fourPsComparison.competitors.splice(
        i - 1,
        2,
        currentItems[i],
        currentItems[i - 1]
      );
    },
    moveDownCompetitor(i) {
      let currentItems = this.fourPsComparison.competitors.concat();
      this.fourPsComparison.competitors.splice(
        i,
        2,
        currentItems[i + 1],
        currentItems[i]
      );
    },
    async loadCompetitors() {
      await this.fetchBrand();
    },
  },
  watch: {
    value(val) {
      this.fourPsComparison = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.four-ps-comparison {
  &__header-col {
    max-width: 480px;
    min-width: 480px;
  }

  &__borderd-col {
    max-width: 480px;
    min-width: 480px;
    border: black solid;
    border-width: 1px 1px 0 0;
  }

  &__borderd-col-bottom {
    max-width: 480px;
    min-width: 480px;
    border: black solid;
    border-width: 1px 1px 1px 0;
  }

  &__added-border-left {
    border-left: 1px black solid;
  }

  &__added-border-right {
    border-right: 1px black solid;
  }

  &__fixed-col {
    max-width: 130px;
    min-width: 130px;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__sticky-col {
    position: sticky;
    left: 0;
    z-index: 2;
  }
}
.bg-white {
  background-color: #fff;
}
.bg-refbar {
  background-color: #f1f7ff;
}
.x-scroll {
  padding-bottom: 8px;
  overflow-y: hidden;
  overflow-x: auto;
  left: 0 !important;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border: none;
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    box-shadow: none;
  }
}
</style>
