/**
 * 目標情報 Request Item Class.
 *
 */

export default class ObjectiveRequestItem {

  // 定量化目標
  quantification = null;
  // 定性化目標
  qualification = null;
  // 入力ステータス（目標）
  inputStatusObjective = null;

}