/**
 * リファレンス情報 Request Item Class.
 *
 */

export default class ReferenceRequestItem {

  // コンセプト
  concept = null;
  // ビジョン
  vision = null;
  // タグライン
  tagline = null;
  // 入力ステータス（リファレンス）
  inputStatusReference = null;

}