var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex flex-nowrap"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"theme-color-item mb-2",style:({backgroundColor: _vm.mainColor})},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-responsive',_vm._g({attrs:{"aspect-ratio":1/1}},!_vm.readonly && on))]}}])},[_c('v-color-picker',{staticClass:"mx-auto",attrs:{"value":_vm.mainColor,"show-swatches":""},on:{"input":function($event){return _vm.$emit('input-main-color', $event)}}})],1)],1),_c('div',{staticClass:"main-color"},[_vm._v("メイン")])])]),_c('v-col',{staticClass:"theme-color-item-wrap"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"theme-color-item mb-2",style:({backgroundColor: _vm.sub1Color})},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-responsive',_vm._g({attrs:{"aspect-ratio":1/1}},!_vm.readonly && on))]}}])},[_c('v-color-picker',{staticClass:"mx-auto",attrs:{"value":_vm.sub1Color,"show-swatches":""},on:{"input":function($event){return _vm.$emit('input-sub1-color', $event)}}})],1)],1),_c('div',{staticClass:"sub-color"},[_vm._v("サブ")])])]),_c('v-col',{staticClass:"theme-color-item-wrap"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"theme-color-item mb-2",style:({backgroundColor: _vm.sub2Color})},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-responsive',_vm._g({attrs:{"aspect-ratio":1/1}},!_vm.readonly && on))]}}])},[_c('v-color-picker',{staticClass:"mx-auto",attrs:{"value":_vm.sub2Color,"show-swatches":""},on:{"input":function($event){return _vm.$emit('input-sub2-color', $event)}}})],1)],1),_c('div',{staticClass:"sub-color"},[_vm._v("サブ")])])]),_c('v-col',{staticClass:"theme-color-item-wrap"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"theme-color-item mb-2",style:({backgroundColor: _vm.sub3Color})},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-responsive',_vm._g({attrs:{"aspect-ratio":1/1}},!_vm.readonly && on))]}}])},[_c('v-color-picker',{staticClass:"mx-auto",attrs:{"value":_vm.sub3Color,"show-swatches":""},on:{"input":function($event){return _vm.$emit('input-sub3-color', $event)}}})],1)],1),_c('div',{staticClass:"sub-color"},[_vm._v("サブ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }