/**
 * 経営理念 Request Item Class.
 *
 */

export default class PhilosophyRequestItem {

  // 設問1
  question1 = null;
  // 設問2
  question2 = null;
  // 設問3
  question3 = null;
  // 設問4
  question4 = null;
  // 設問5
  case1 = null;
  // 設問6
  case2 = null;
  // 設問7
  case3 = null;
  // 最終的なゴール
  finalyObjective = null;
  // ブランドメッセージ
  brandmessage = null;
  // 追加設問データセクション
  hearingAddItems = [];
  // 経営理念の入力ステータス
  inputStatusPhilosophy = null;

}