/**
 * 目標数値-マーケティング目標情報 Request Item Class.
 *
 */

export default class TargetValuesMarketingItem {

  // 予算(数値)
  budgetMonthly = null;
  // 予算(数値)
  budgetYearly = null;
  // 予算備考
  budgetRemarks = null;
  // 目標と現状成果
  goalsAchievements = null;
  // 目標変数（目標名、目標数、目標単価）
  targetVariables = [];
  // 年間目標売上
  targetAnnualSales = null;
  // 月間目標売上
  targetMonthlySales = null;
  // 年間目標顧客数
  targetAnnualCustomer = null;
  // 月間目標顧客数
  targetMonthlyCustomer = null;
  // ファネル上のボトルネック
  bottleneckOfFunnel = null;

}