/**
 * ペルソナ情報 Request Item Class.
 *
 */
import PersonaDataItem from "./PersonaDataItem";

export default class PersonaRequestItem {

  // PersonaDataItem
  data = null;
  // 入力ステータス（ペルソナ）
  inputStatusPersona = null;
  // 入力ステータス（カスタマージャーニー）
  inputStatusCustomerJourney = null;

}