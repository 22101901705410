<template>
  <div class="sp-common-width-narrow ref-mode label-outside">
    <v-row
      class="mx-0 mt-3"
      v-if="
        isShowRefs(refMode, [
          philosophy.question1,
          philosophy.question2,
          philosophy.question3,
          philosophy.question4,
          philosophy.case1,
          philosophy.case2,
          philosophy.case3,
          philosophy.case1,
          philosophy.case2,
          philosophy.case3,
          philosophy.finalyObjective,
          philosophy.brandmessage,
        ]) || isShowRef_HearingAddItems(refMode, philosophy.hearingAddItems)
      "
    >
      <!-- 以下は歯科業界のみで表示 -->
      <v-col
        cols="12"
        class="pa-0 mb-12"
        v-show="isDentistry && isShowRef(refMode, philosophy.question1)"
      >
        <parts-input-textarea
          label="歯科医になった理由"
          description="クライアントが事業経営する上で根底となる考え方や目指しているゴールを整理します。"
          v-model="philosophy.question1"
          :readonly="readonly"
          reference="ManegementPhilosophy_question1.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>
      <v-col
        cols="12"
        class="pa-0 mb-12"
        v-show="isDentistry && isShowRef(refMode, philosophy.question2)"
      >
        <parts-input-textarea
          v-model="philosophy.question2"
          label="歯科医療で大切にしていること"
          description="その理念を体現するような事例があれば、そのエピソードも併せて記入してください。"
          :readonly="readonly"
          reference="ManegementPhilosophy_question2.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>
      <v-col
        cols="12"
        class="pa-0 mb-12"
        v-show="isDentistry && isShowRef(refMode, philosophy.question3)"
      >
        <parts-input-textarea
          v-model="philosophy.question3"
          label="患者様に対して心がけていること"
          :readonly="readonly"
          reference="ManegementPhilosophy_question3.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>
      <v-col
        cols="12"
        class="pa-0 mb-12"
        v-show="isDentistry && isShowRef(refMode, philosophy.question4)"
      >
        <parts-input-textarea
          v-model="philosophy.question4"
          label="これまで歯科治療してきた中で心に残っているエピソード"
          :readonly="readonly"
          reference="ManegementPhilosophy_question4.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>

      <v-col
        v-show="
          isDentistry &&
          isShowRefs(refMode, [
            philosophy.case1,
            philosophy.case2,
            philosophy.case3,
          ])
        "
        cols="12"
        class="pa-0 mb-12"
      >
        <parts-element-label
          class="mr-2 mb-3"
          label="その他のお客様事例"
        ></parts-element-label>
        <v-card
          outlined
          elevation="5"
          class="mt-7 customer-case-textarea"
          :class="
            refMode
              ? 'customer-case-textarea__ref-mode'
              : 'customer-case-textarea__edit-mode'
          "
        >
          <v-row class="pa-8 d-flex">
            <v-col
              cols="12"
              class="py-0"
              v-show="isShowRef(refMode, philosophy.case1)"
            >
              <span class="text-caption py-1 black--text">・事例1</span>
              <v-textarea
                outlined
                auto-grow
                hide-details
                v-model="philosophy.case1"
                :readonly="readonly"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
              v-show="isShowRef(refMode, philosophy.case2)"
            >
              <span class="text-caption py-1 black--text">・事例2</span>
              <v-textarea
                outlined
                auto-grow
                hide-details
                v-model="philosophy.case2"
                :readonly="readonly"
              ></v-textarea>
            </v-col>
            <v-col cols="12" v-show="isShowRef(refMode, philosophy.case3)">
              <span class="text-caption py-1 black--text">・事例3</span>
              <v-textarea
                outlined
                auto-grow
                hide-details
                v-model="philosophy.case3"
                :readonly="readonly"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        class="pa-0 mb-12"
        v-show="isDentistry && isShowRef(refMode, philosophy.finalyObjective)"
      >
        <parts-input-textarea
          v-model="philosophy.finalyObjective"
          label="医院の目指している最終ゴール"
          :readonly="readonly"
          reference="ManegementPhilosophy_finalyObjective.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>

      <!-- 以下は全業界で表示 -->
      <v-col
        cols="12"
        class="pa-0 mb-12"
        v-show="isShowRef(refMode, philosophy.brandmessage)"
      >
        <parts-input-textarea
          v-model="philosophy.brandmessage"
          label="ブランドメッセージ"
          :readonly="readonly"
          reference="ManegementPhilosophy_brandmessage.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>

      <v-col
        cols="12"
        class="pa-0"
        v-show="isShowRef_HearingAddItems(refMode, philosophy.hearingAddItems)"
      >
        <parts-element-label
          label="追加のヒアリング事項"
          class="mb-3"
        ></parts-element-label>
        <add-hearing-items
          :show-label="false"
          v-model="philosophy.hearingAddItems"
          :readonly="readonly"
          :refMode="refMode"
        ></add-hearing-items>
      </v-col>
    </v-row>
    <text-no-data v-else classVal="text-center" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import VTextareaWithLink from "../../atoms/VTextareaWithLink.vue";
import InputSample from "../../atoms/InputSample.vue";
import AddHearingItems from "../../molecules/AddHearingItems.vue";
import PartsInputTextarea from "../../molecules/PartsInputTextarea.vue";
import PartsElementLabel from "../../atoms/PartsElementLabel.vue";
import TextNoData from "../../atoms/TextNoData.vue";

export default {
  components: {
    VTextareaWithLink,
    PartsInputTextarea,
    InputSample,
    AddHearingItems,
    PartsElementLabel,
    TextNoData,
  },
  props: ["value", "readonly", "refMode"],
  data() {
    return {
      philosophy: {
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: null,

        hearingAddItems: [
          {
            text: "",
          },
        ],
      },
      customerCaseWidth: 300,
    };
  },
  computed: {
    ...mapGetters(["project"]),
    isDentistry() {
      return this.project.industry == "歯科";
    },
    isRealEstate() {
      return this.project.industry == "不動産";
    },
    isBuilding() {
      return this.project.industry == "建築";
    },
  },
  created() {
    if (this.value) {
      this.philosophy = this.value;
    }
  },
  methods: {
    //経営理念セクションの未入力チェック
    isEmpty() {
      if (!this.philosophy) return true;

      const philosophy = this.philosophy;
      let ret = false;

      //各質問項目をチェック
      const questionKeys = [
        "question1",
        "question2",
        "question3",
        "question4",
        "case1",
        "case2",
        "case3",
        "finalyObjective",
        "brandmessage",
      ];
      ret = questionKeys.some((i) => philosophy[i]);
      if (ret) return false;

      //追加質問項目をチェック
      if (philosophy.hearingAddItems && philosophy.hearingAddItems.length > 0) {
        ret = philosophy.hearingAddItems.some((i) => i.label || i.text);
        if (ret) return false;
      }

      return true;
    },
    isShowRef_HearingAddItems(refMode, value) {
      return !refMode || value.some((e) => e.label || e.text);
    },
  },
  watch: {
    value(val) {
      this.philosophy = val;
    },
    "philosophy.inputStatusPhilosophy"(val) {
      this.$store.commit("brand_input_status_philosophy", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.customer-case-textarea {
  &__edit-mode {
    background-color: #f3faff !important;
  }
  &__ref-mode {
    background-color: white !important;
  }
  .input-sample {
    margin-bottom: -2px;
  }
}
</style>
<style lang="scss">
.customer-case-textarea {
  .v-input__slot {
    background-color: white !important;
  }
}
</style>
