/**
 * 5Force分析情報 Request Item Class.
 *
 */

export default class FiveForcesRequestItem {

  // 新規参入の脅威
  threatNewEntrants = null;
  // 代替品の脅威
  threatSubstitutes = null;
  // 買い手の交渉力
  bargainingPowerCustomers = null;
  // 売り手の交渉力
  bargainingPowerSuppliers = null;
  // 業界内の競争
  competitiveRivalry = null;
  // 5Force分析まとめ
  fiveForceSummary = null;
  // 入力ステータス
  inputStatusFiveForces = null;

}