<template>
  <div class="design-parameter-wrap">
    <!-- <v-row class="ma-0 pa-0">
      <v-col align="right" class="ma-0 pa-0">
        <input-sample class="input-sample" :refDoc="true" reference="DesignParameter_designParameter.txt" label="デザインパラメータ"></input-sample>
      </v-col>
    </v-row> -->
    <parameter-slider
      v-for="(param, i) in value.parameters.filter(param => param !== null)"
      :key="i"
      :caption="info[i].caption"
      :prependLabel="info[i].prependLabel"
      :appendLabel="info[i].appendLabel"
      :value="param"
      :min="min"
      :max="max"
      :tick-labels="tickLabels"
      @input="update($event, i)"
      :readonly="readonly"
    ></parameter-slider>
  </div>
</template>

<script>
import ParameterSlider from '../molecules/ParameterSlider'
import InputSample from '../atoms/InputSample.vue';

export default {
  components: {
    ParameterSlider,
    InputSample,
  },
  props: {
    value: {
      type: Object,
      require: false,
      default: null,
    },
    readonly: Boolean,
  },

  data() {
    return {
      min: -5,
      max: 5,
      tickLabels: [ '5', '4', '3', '2', '1', '0', '1', '2', '3', '4', '5' ],
      parameter: {
        parameters: [ 0, 0, 0, 0, 0, ],

        inputStatusParameters: '',
      },
      info: [
        {
          caption:      '装飾性',
          prependLabel: 'シンプル',
          appendLabel:  'デコラティブ',
        },
        {
          caption:      '成熟度',
          prependLabel: '若々しい',
          appendLabel:  'トラディショナル',
        },
        {
          caption:      '愛嬌',
          prependLabel: 'スタイリッシュ',
          appendLabel:  'キュート',
        },
        {
          caption:      '品性',
          prependLabel: '落ち着き\nスタティック',
          appendLabel:  'アクティブ\nダイナミック',
        },
        {
          caption:      '温度感',
          prependLabel: '冷たい\n無機質',
          appendLabel:  '暖かい\n人間性',
        },
      ],
    }
  },

  created() {
    this.init()
  },

  methods: {
    // 初期化
    init() {
      if (this.value && this.value.parameters && this.value.parameters.length) {
        // 値が指定されている場合
        const param = {
          parameters: this.value.parameters ?? this.parameter.parameters,

          inputStatusParameters: this.value.inputStatusParameters ?? this.parameter.inputStatusParameters
        }
        this.$emit('input', param)

      } else {
        // 値が指定されていない場合
        const param = JSON.parse(JSON.stringify(this.parameter))
        this.$emit('input', param)
      }
    },

    // データの更新
    update(value, index) {
      const param = JSON.parse(JSON.stringify(this.value))
      param.parameters[index] = value
      this.$emit('input', param)
    }
  }
}
</script>
<style lang="scss" scoped>
.design-parameter-wrap {
  .input-sample{
    margin-top: -3px;
  }
}
</style>