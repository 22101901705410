<template>
  <v-btn
    text
    block
    color="#003565"
    :href="href"
    target="_blank"
    class="v-btn-content text-capitalize"
    :disabled="disabled"
  >
    <v-icon left color="#656766">
      mdi-open-in-new
    </v-icon>
    {{text}}
  </v-btn>
</template>
<script>
export default {
  props: {
    href: String,
    target: String,
    text: String,
    disabled: Boolean,
  },
}
</script>
<style lang="scss" scoped>
.v-btn-content {
  justify-content: left;
}
</style>