/**
 * SWOT分析マトリクス情報 Request Item Class.
 *
 */

export default class SwotMatrixItem {

  // 強み
  swotStrengths = null;
  // 弱み
  swotWeakness = null;
  // 機会
  swotOpportunity = null;
  // 脅威
  swotThreat = null;
  // 強み×機会
  swotStrengthsOpportunity = null;
  // 弱み×機会
  swotWeaknessOpportunity = null;
  // 強み×脅威
  swotStrengthsThreat = null;
  // 弱み×脅威
  swotWeaknessThreat = null;

}