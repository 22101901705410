<template>
  <div v-if="dialog" v-drag:ReferenceImageDialogHead class="reference-image-dialog"
    :style="{top: top + 'px', left: left + 'px'}" >
    <v-card>
      <v-system-bar window id="ReferenceImageDialogHead">
        <span v-if="label">{{label}}</span>
        <span v-else>入力サンプル</span>
        <v-spacer></v-spacer>
        <v-btn icon small dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <div v-if="!content" class="d-flex align-center justify-center" style="height: 100px">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <template v-else>
        <v-card-text
          v-if="contentType == 'image'"
          class="overflow-x-auto overflow-y-auto"
          style="max-height: auto;" >
          <img :src="content">
        </v-card-text>

        <v-card-text
          v-else
          class="overflow-y-auto"
          style="user-select: text; max-height: 350px;" v-html="content">
        </v-card-text>
      </template>
    </v-card>
  </div>

</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import drag from '@branu-jp/v-drag'

export default {
  directives: { drag },
  data() {
    return {
      dialog: false,
      top: 10,
      left: 10, 
      content: null,
      contentType: 'text',
      label: null,
    }
  },
  computed: {
    ...mapGetters(['open_referenceImage', 'close_referenceImage', 'referenceImageInfo', ]),
  },
  methods: {
    close() {
      this.dialog = false
      this.content = null
      this.$store.commit('close_referenceImage')
    },
  },

  watch: {
    open_referenceImage(val) {
      if(!this.dialog && val) {
        this.dialog = true

        const info = this.referenceImageInfo
        this.top = info.top + 180 > window.innerHeight ? window.innerHeight - 180 : info.top
        this.left = info.left + 720 > window.innerWidth ? window.innerWidth - 720 : info.left
      } else {
        console.log("ReferenceImageDialog: close()")
        this.close()
      }
    },
    referenceImageInfo(val) {
        this.content = null

        const info = val
        if(!this.dialog) {
          this.top = info.top + 180 > window.innerHeight ? window.innerHeight - 180 : info.top
          this.left = info.left + 720 > window.innerWidth ? window.innerWidth - 720 : info.left
        }

        const getContent = async () => {

          let initContent = false;

          if(info.id) {
            const ext = info.id.split('.').reverse()[0]

            if(ext == 'png' || ext == 'jpg') {
              this.content = `/refdoc/img/${info.id}`
              this.contentType = 'image';
              initContent = true;
            } else {
              try {
                const res = await axios.get(`/refdoc/${info.id}`);

                this.content = res.data.replace(/\r?\n/g, '<br>');
                this.contentType = 'text';
                initContent = true;

              } catch (error) {
                console.error(error)
              }
            }
          }

          if(info.label) {
            this.label = info.label
          }

          if(!initContent) {
            this.content = `
              <br>
              <div class='text-center'>
              ※作成中...
              </div>
              <br>
            `;
            this.contentType = 'text';
          }

        }

        getContent();
    },
  },
}
</script>
<style lang="scss" scoped>
.reference-image-dialog {
  position: fixed;
  user-select: none;
  min-width: 300px;
  min-height: 200px;
  max-width: 700px;
  z-index: 9999;
}
</style>