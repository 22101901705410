<template>
  <div v-if="value">
    <v-row>
      <!-- ロゴ -->
      <v-col cols="6">
        <p class="text-h6 ma-0 pt-3 pb-0">{{ caption.logo }}</p>
        <logo-add :value="value.logo" @input="update($event, 'logo')" :clearable="!readonly" :readonly="readonly"></logo-add>
      </v-col>

      <!-- テーマカラー -->
      <v-col cols="6">
        <p class="text-h6 ma-0 pt-3 pb-6">{{ caption.colors }}</p>
        <theme-colors
          :main-color="value.mainColor"
          :sub1-color="value.sub1Color"
          :sub2-color="value.sub2Color"
          :sub3-color="value.sub3Color"
          @input-main-color="update($event, 'mainColor')"
          @input-sub1-color="update($event, 'sub1Color')"
          @input-sub2-color="update($event, 'sub2Color')"
          @input-sub3-color="update($event, 'sub3Color')"
          :readonly="readonly"
        >
        </theme-colors>
      </v-col>
    </v-row>

    <!-- 参考画像 -->
    <section class="mb-8">
      <p class="text-h6 ma-0 pt-3 pb-2">{{ caption.images }}</p>
      <reference-image :value="value.referenceImages" @input="update($event, 'referenceImages')" :readonly="readonly"></reference-image>
    </section>

    <!-- テイスト・こだわり -->
    <section class="mb-4" v-show="isShowRef(refMode, value.taste)">
      <div class="text-caption">・テイスト・こだわり</div>
      <v-textarea-with-link
        :value="value.taste"
        @input="update($event, 'taste')"
        :readonly="readonly"
        reference="DesignImage_taste.txt"
        :refDoc="true"
      ></v-textarea-with-link>
    </section>

    <!-- フォントイメージ -->
    <section class="mb-4" v-show="isShowRef(refMode, value.font)">
      <v-combobox-with-link
        :value="value.font"
        @input="update($event, 'font')"
        :items="MasterData.designFontItems"
        outlined
        :link-label="caption.font"
        :label="caption.font"
        multiple
        chips
        deletable-chips
        hide-details
        :readonly="readonly"
        reference="font_image.jpg"
        :refImg="true"
      ></v-combobox-with-link>
    </section>

    <!-- デザインキーワード -->
    <section class="mb-4" v-show="isShowRef(refMode, value.designKeyword)">
      <div class="text-caption">・デザインキーワード</div>
      <v-combobox
        :value="value.designKeyword"
        @input="update($event, 'designKeyword')"
        :items="MasterData.designKeywordItems"
        outlined
        multiple
        chips
        deletable-chips
        hide-details
        :readonly="readonly"
      ></v-combobox>
    </section>
  </div>
</template>

<script>
import LogoAdd from '../molecules/LogoAdd'
import ThemeColors from '../molecules/ThemeColors'
import ReferenceImage from '../molecules/ReferenceImage'
import VComboboxWithLink from '../atoms/VComboboxWithLink'
import VTextareaWithLink from '../atoms/VTextareaWithLink.vue'

import MasterData from '../../../utils/MasterData'

export default {
  components: {
    LogoAdd,
    ThemeColors,
    ReferenceImage,
    VComboboxWithLink,
    VTextareaWithLink,
  },

  props: {
    value: Object,
    readonly: Boolean,
    refMode: Boolean,
  },

  data() {
    return {
      caption: {
        logo:     'ロゴ',
        colors:   '医院カラー',
        images:   '医院内装',
        taste:    'テイスト・こだわり',
        font:    'フォントイメージ',
        keywords: 'デザインキーワード',
      },
      template: {
        logo: {
          logoUrl: null,
          logoMeaning: '',
        },
        mainColor: '',
        sub1Color: '',
        sub2Color: '',
        sub3Color: '',
        referenceImages: [],
        taste: '',
        font: [],
        designKeyword: [],

        inputStatusDesign: '',
      },
      MasterData,
    }
  },

  created() {
    this.init()
  },

  methods: {
    // 初期化
    init() {
      if (this.value) {
        const image = {
          logo: {
            logoUrl:          this.value.logo.logoUrl       ?? this.template.logo.logoUrl,
            logoMeaning:      this.value.logo.logoMeaning   ?? this.template.logo.logoMeaning,
          },
          mainColor:          this.value.mainColor          ?? this.template.mainColor,
          sub1Color:          this.value.sub1Color          ?? this.template.sub1Color,
          sub2Color:          this.value.sub2Color          ?? this.template.sub2Color,
          sub3Color:          this.value.sub3Color          ?? this.template.sub3Color,
          referenceImages:    this.value.referenceImages    ?? this.template.referenceImages,
          taste:              this.value.taste              ?? this.template.taste,
          font:               this.value.font               ?? this.template.font,
          designKeyword:      this.value.designKeyword      ?? this.template.designKeyword,

          inputStatusDesign:  this.value.inputStatusDesign   ?? this.template.inputStatusDesign
        }
        this.$emit('input', image)
      } else {
        const image = JSON.parse(JSON.stringify(this.template))
        this.$emit('input', image)
      }
    },

    // データの更新
    update(value, key) {
      let image = JSON.parse(JSON.stringify(this.value))
      image[key] = value
      this.$emit('input', image)
    }
  },
}
</script>

