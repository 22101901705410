/**
 * 目標数値-業績(汎用)情報 Request Item Class.
 *
 */

export default class TargetValuesGeneralItem {

  // 年間売上
  annualSales = null;
  // 月間売上
  monthlySales = null;
  // 月間顧客数
  monthlyCustomer = null;
  // 1日顧客数
  dailyCustomer = null;
  // 月間新規顧客数
  monthlyNewCustomer = null;
  // 途中解約数
  cancellationCustomer = null;

}