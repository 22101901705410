<template>
  <div>
    <div class="d-flex justify-end mb-2">
      <div class="mr-2">
        <v-btn v-show="!refMode && hasWritePermission" @click="initDialog = true" small color="btPrimary" class="white--text" depressed>
          デフォルトの項目にもどす
        </v-btn>
        
      </div><input-sample v-if="!refMode" class="input-sample" :refDoc="true" reference="Service_service.txt" label="サービス内容"></input-sample>
    </div>
    <v-card class="service-info-wrap mx-1 my-1">
      <v-card-text>
        <v-tabs
          v-model="tabs"
          vertical
          @change="tabChange($event)"
        >
          <v-tab
            v-for="(data, i) in serviceInfo"
            :key="data.id"
            class="justify-start tab"
          >
            <div class="service-name">{{ data.serviceName }}</div>
            <div class="d-flex">
              <v-btn
                v-show="!readonly"
                :disabled="i == 0"
                x-small
                color="gray"
                plain
                fab
                @click.stop="inFront(i)"
                width="25"
              >
                <v-icon
                  color="gray"
                >mdi-arrow-up-bold</v-icon>
              </v-btn>
              <v-btn
                v-show="!readonly"
                :disabled="i == serviceInfo.length - 1"
                x-small
                color="gray"
                plain
                fab
                @click.stop="behind(i)"
                width="25"
              >
                <v-icon
                  color="gray"
                >mdi-arrow-down-bold</v-icon>
              </v-btn>
              <v-btn
                v-show="!readonly"
                x-small
                color="gray"
                plain
                fab
                @click="showDialog(i)"
                width="25"
              >
                <v-icon
                  color="gray"
                >mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </v-tab>
          <!-- dialog -->
          <v-dialog
            v-model="dialog"
            persistent
            max-width="360px"
          >
            <v-card>
              <v-card-title class="headline"></v-card-title>
              <v-card-text>本当にサービスを削除してもよろしいですか？</v-card-text>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  class="pa-3 mr-3 text-body-1"
                  width="126"
                  color="primary"
                  large
                  depressed
                  @click="dialog = false; deleteService()"
                >
                  削除
                </v-btn>
                <v-btn
                  class="pa-3 text-body-1"
                  width="126"
                  color="grey lighten-2"
                  large
                  depressed
                  @click="dialog = false"
                >
                  キャンセル
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- dialog -->
          <div class="justify-start mt-3">
            <v-btn
              v-show="!readonly"
              depressed
              tile
              color="btPrimary"
              class="white--text"
              @click="addService()"
            >
              サービス追加
              <v-icon
                small
                color="white"
                class="ml-1"
              >mdi-plus-thick</v-icon>
            </v-btn>
          </div>
          <v-tab-item
            v-for="(data, idx) in serviceInfo"
            :key="idx"
            class="tab-item-border"
            transition="slide-y-transition"
          >
            <v-card flat>
              <v-card-text class="py-0">
                <v-responsive class="d-flex flex-column pl-6">
                  <span class="text-caption py-1 black--text">・サービス名</span>
                  <v-text-field
                    hide-details
                    v-model="data.serviceName"
                    outlined
                    :readonly="readonly"
                    class="mb-5 pt-1"
                  ></v-text-field>
                  <span class="text-caption py-1 black--text">・サービス内容</span>
                  <v-textarea
                    v-model="data.serviceContents"
                    :readonly="readonly"
                    rows="4"
                    outlined
                    no-resize
                    hide-details
                  ></v-textarea>
                  <v-checkbox
                    v-model="data.focusOnService"
                    label="注力"
                    color="btPrimary"
                    hide-details
                    :readonly="readonly"
                    class="mb-5"
                  />
                  <span class="text-caption py-1 black--text">・実績</span>
                  <v-textarea
                    v-model="data.actualResults"
                    :readonly="readonly"
                    rows="3"
                    outlined
                    no-resize
                  ></v-textarea>
                  <span class="text-caption py-1 black--text">・価格帯</span>
                  <v-textarea
                    v-model="data.priceRange"
                    :readonly="readonly"
                    rows="3"
                    outlined
                    no-resize
                  ></v-textarea>
                </v-responsive>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>

    <yes-no-dialog
      v-model="initDialog"
      message="現在の入力内容は破棄されますがよろしいですか？"
      yesText="元に戻す"
      noText="キャンセル"
      @clickYes="initYes()"
      @clickNo="initDialog = false"
    />
  </div>
</template>
<script>
import CustomerJourneyMap from '../organisms/CustomerJourneyMap';
import InputSample from '../atoms/InputSample.vue';
import YesNoDialog from '../atoms/YesNoDialog.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    CustomerJourneyMap,
    InputSample,
    YesNoDialog,
  },
  props: ['value', 'readonly', 'refMode'],
  data () {
    return {
      tabs: 0,
      currentTab: 0,

      serviceInfo: [
        {
          serviceName: '',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 0,
        }
      ],

      tab: null,
      dialog: false,
      delete_data: null,

      initDialog: false,

      // 不動産初期データ
      initValRealEstate: [
        {
          serviceName: '売却',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 0,
        },
        {
          serviceName: '買取',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 1,
        },
        {
          serviceName: '債権',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 2,
        },
        {
          serviceName: '相続',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 3,
        },
        {
          serviceName: '空き家対策',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 4,
        },
        {
          serviceName: '収益物件',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 5,
        },
        {
          serviceName: 'リフォーム',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 6,
        },
      ],

      // 建築初期データ
      initValBuilding: [
        {
          serviceName: 'フルオーダー住宅',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 0,
        },
        {
          serviceName: 'セミオーダー住宅',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 1,
        },
        {
          serviceName: '規格住宅',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 2,
        },
        {
          serviceName: '建売住宅',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 3,
        },
        {
          serviceName: 'リフォーム',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 4,
        },
        {
          serviceName: 'アフターサービス',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 5,
        },
        {
          serviceName: '土地販売',
          serviceContents: '',
          focusOnService: false,
          actualResults: '',
          priceRange: '',
          seq: 6,
        },
      ],

    }
  },
  computed: {
    ...mapGetters(['project', "hasWritePermission"])
  },
  created(){
    this.serviceInfo = this.value
    if(this.value.length) {
      this.sort();
    } else if(this.project.industry == "不動産" || this.project.industry == "建築") {
      // 建築/不動産の場合は初期データを登録する
      this.initService();
    } else {
      this.addService();
    }
  },
  watch: {
    value(val) {
      this.serviceInfo = val
    },
  },
  methods: {
    deleteService: function(){
      this.serviceInfo.splice(this.delete_data, 1)
    },
    addService: function(){
      this.serviceInfo.push(
          {
            serviceName: 'サービス',
            serviceContents: '',
            focusOnService: false,
            actualResults: '',
            priceRange: '',
            seq: this.serviceInfo.length ? this.serviceInfo[this.serviceInfo.length - 1].seq + 1 : 0
          }
        )

      this.tabs = this.serviceInfo.length-1
      this.tabChange(this.serviceInfo.length-1)
    },
    showDialog: function(i){
      this.dialog = true
      this.delete_data = i
    },
    tabChange: function(i) {
      if (typeof i !== 'undefined') {
        this.currentTab = i
      }
    },
    initYes() {
      this.initService();
      this.initDialog = false;
    },
    initService() {
      switch(this.project.industry) {
        case "不動産":
          this.$emit('input', new Array(...this.initValRealEstate)) ;
          break;
        case "建築":
          this.$emit('input', new Array(...this.initValBuilding)) ;
          break;
      }
    },
    inFront(idx) {
      [this.serviceInfo[idx].seq, this.serviceInfo[idx - 1].seq] = [this.serviceInfo[idx - 1].seq, this.serviceInfo[idx].seq]
      this.sort()
    },
    behind(idx) {
      [this.serviceInfo[idx].seq, this.serviceInfo[idx + 1].seq] = [this.serviceInfo[idx + 1].seq, this.serviceInfo[idx].seq]
      this.sort()
    },
    sort() {
      this.serviceInfo.sort((a,b) => {
        return (a.seq < b.seq) ? -1 : 1;
      });
    }
    

  },
}
</script>
<style lang="scss" scoped>
.service-info-wrap {
  min-width: 800px;
  max-width: 1040px;
  padding: 15px;
}
.input-sample {
  margin-top: -3px;
}
</style>
<style lang="scss">
.service-info-wrap {
  .tab{
    max-width: 260px!important;
    min-width: 260px!important;
  }
}
.service-name{
    width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.tab-item-border{
  border-left: solid 3px #ECEFF1;
}
.v-avatar img {
  object-fit: cover; 
}
@media screen and (max-width: 1024px) {
  .tab{
    width: 200px!important;
  }
}
/* セレクトボックスの選択肢の色をreadonly時に変化させない対応 */
.theme--light .v-select .v-select__selection--disabled {
  color: rgba(0,0,0,.87)
}
</style>