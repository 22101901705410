/**
 * プロフィール Request Item Class.
 *
 */

export default class ProfileRequestItem {

  // 立地1
  location1 = null;
  // 立地属性1
  locationProperty1 = null;
  // 立地属性2
  locationProperty2 = null;
  // 立地属性3
  locationProperty3 = null;
  // 立地属性4
  locationProperty4 = null;
  // 立地理由
  locationReason = null;
  // 年間売上(数値)
  annualSales = null;
  // 月間売上(数値)
  monthlySales = null;
  // 月間顧客数(数値)
  monthlyCustomer = null;
  // 1日顧客数(数値)
  dailyCustomer = null;
  // 月間新規顧客数(null)
  monthlyNewCustomer = null;
  // 途中解約数(数値)
  cancellationCustomer = null;
  // 自費率(数値)
  selfFinancedRate = null;
  // リコール率(数値)
  recallRate = null;
  // 稼働率(数値)
  occupancyRate = null;
  // キャンセル率(数値)
  cancellationRate = null;

  // 数値情報
  numInfo = []

  // 組織情報
  organizationInfo = {
    orgFeatures: null,
    orgIssues: null,
    adagencyFlag: null,
    adagencyName: null,
    webdevelopperFlag: null,
    webdevelopperName: null,
    seoconsultantFlag: null,
    seoconsultantName: null,
  }

  // プロフィールの入力ステータス
  inputStatusProfile = null;

}