import { render, staticRenderFns } from "./PartsInputTextarea.vue?vue&type=template&id=104e8bc2&scoped=true&"
import script from "./PartsInputTextarea.vue?vue&type=script&lang=js&"
export * from "./PartsInputTextarea.vue?vue&type=script&lang=js&"
import style0 from "./PartsInputTextarea.vue?vue&type=style&index=0&id=104e8bc2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104e8bc2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
