<template>
  <div class="sheet-info">
    <v-row class="d-flex justify-end pt-2">
      <div class="d-flex justify-end">
        <div class="text-caption mx-2 mb-0 text-no-wrap"
          >登録日時：
          <span class="font-italic">{{ $dayjs(sheetInfo.createdAt).format("YYYY/MM/DD") }}</span>
        </div>
        <span class="text-caption mx-2 mb-0 text-no-wrap"
          >登録者：{{
            sheetInfo.createdUserName
          }}</span
        >
      </div>
      <div class="d-flex justify-end">
        <span class="text-caption mx-2 mb-0 text-no-wrap"
          >更新日時：
          <span class="font-italic">{{ $dayjs(sheetInfo.updatedAt).format("YYYY/MM/DD") }}</span>
        </span>
        <span class="text-caption mx-2 mb-0 text-no-wrap"
          >更新者：{{
            sheetInfo.updatedUserName
          }}</span
        >
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ['value'],
  data () {
    return {
      sheetInfo: {
        createdAt: null,
        createdUserName: null,
        updatedAt: null,
        updatedUserName: null,
      },
    }
  },
  created(){
    if(this.value) {
      this.sheetInfo = this.value
    }
  },
  mounted() {
    this.$emit('mounted')
  },
}
</script>
<style lang="scss" scoped>
.sheet-info{
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;

  .head{
    font-weight: 600;
  }
}
</style>
