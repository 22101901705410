<template>
  <!-- いいね！ -->
  <v-card
    class="px-2 py-3"
    color="grey lighten-3"
    elevation="0"
    min-width="568"
    max-width="600"
  >
    <v-row>
      <v-col sm="8" md="8" align="right" align-self="center">
        <span
          class="text-body-2 font-weight-bold"
          style="color: #424242"
          >このプロジェクトを気に入ったら</span
        >
        <span
          class="text-body-2 font-weight-bold"
          style="color: rgb(84, 124, 188)"
          >いいね！</span
        >
        <span
          class="text-body-2 font-weight-bold mr-2"
          style="color: #424242"
          >をクリック</span
        >
      </v-col>
      <v-col sm="3" md="4" align="left" align-self="center">
        <!-- 押下前 -->
        <v-btn
          v-if="!userHowNiceFlg"
          class="ml-2 font-weight-bold"
          color="rgb(84, 124, 188)"
          height="36"
          outlined
          @click="_click()"
        >
          <v-icon left dark color="rgb(84, 124, 188)">mdi-thumb-up-outline</v-icon>
          <span style="color: rgb(84, 124, 188)">いいね！</span>
        </v-btn>
        <!-- 押下後 -->
        <v-btn
          v-else
          class="ml-2 font-weight-bold"
          color="pink lighten-1"
          height="36"
          depressed
          @click="_click()"
        >
          <v-icon left dark color="white">mdi-thumb-up</v-icon>
          <span class="white--text">いいね！</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'howNiceCount', 'userHowNiceFlg'
    ])
  },
  methods: {
    ...mapActions(['howNice']),

    _click() {
      this.howNice()
    },
  },
};
</script>
