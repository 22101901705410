<template>
  <div>
    <v-textarea
      :value="value.designNote"
      @input="update($event, 'designNote')"
      class="mt-4"
      outlined
      hide-details
      auto-grow
      :readonly="readonly || refMode"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    readonly: Boolean,
    refMode: Boolean,
  },

  data() {
    return {
      template: {
        designNote: '',

        inputStatusNote: '',
      }
    }
  },

  created() {
    this.init()
  },

  methods: {
    // 初期化
    init() {
      if (this.value) {
        // データが指定されている場合
        const note = {
          designNote:             this.value.designNote             ?? this.template.designNote,

          inputStatusNote:  this.value.inputStatusNote  ?? this.template.inputStatusNote,
        }
        this.$emit('input', note)

      } else {
        // データが指定されていない場合
        const note = JSON.parse(JSON.stringify(this.template))
        this.$emit('input', note)
      }
    },

    // データの更新
    update(value, key) {
      const note = JSON.parse(JSON.stringify(this.value))
      note[key] = value
      this.$emit('input', note)
    }
  }
}
</script>