/**
 * ターゲット情報 Request Item Class.
 *
 */

export default class TargetRequestItem {

  // デモグラ(メイン)
  mainDemogra = null;
  // 特長(メイン)
  mainFeatures = null;
  // ニーズ(メイン)
  mainNeeds = null;
  // SubTargetItem の配列
  subTarget = [];
  // 入力ステータス（ターゲット）
  inputStatusTarget= null;

}