<template>
  <div class="pt-5 target-user-wrap">
    <div class="mt-0 mb-6 text-h6 text--secondary">メインターゲット</div>
    <v-row>
      <v-col sm="9" md="4">
        <v-row class="ma-0 pa-0">
          <v-col align="right" class="ma-0 pa-0">
            <input-sample class="input-sample" :refDoc="true" reference="TargetUser_demogra.txt" label="デモグラ"></input-sample>
          </v-col>
        </v-row>
        <v-textarea
          v-model="target.mainDemogra"
          class="text-body-2"
          outlined
          label="デモグラ"
          :clearable="!readonly"
          :readonly="readonly"
          auto-grow row-height="12" no-resize
        ></v-textarea>
      </v-col>
      <v-col sm="12" md="4">
        <v-row class="ma-0 pa-0">
          <v-col align="right" class="ma-0 pa-0">
            <input-sample class="input-sample" :refDoc="true" reference="TargetUser_features.txt" label="ターゲット特長"></input-sample>
          </v-col>
        </v-row>
        <v-textarea
          v-model="target.mainFeatures"
          class="text-body-2"
          outlined
          label="ターゲット特長"
          :clearable="!readonly"
          :readonly="readonly"
          auto-grow row-height="12" no-resize
        ></v-textarea>
      </v-col>
      <v-col sm="12" md="4">
        <v-row class="ma-0 pa-0">
          <v-col align="right" class="ma-0 pa-0">
            <input-sample class="input-sample" :refDoc="true" reference="TargetUser_needs.txt" label="ニーズ"></input-sample>
          </v-col>
        </v-row>
        <v-textarea
          v-model="target.mainNeeds"
          class="text-body-2"
          outlined
          label="ニーズ"
          :clearable="!readonly"
          :readonly="readonly"
          auto-grow row-height="12" no-resize
        ></v-textarea>
      </v-col>
    </v-row>
    <div class="mt-0 mb-6 text-h6 text--secondary">サブターゲット</div>

    <div v-for="(item, i) in target.subTarget" :key="item.id">
      <v-divider class="mb-3"></v-divider>
      <div class="d-flex justify-end mb-3">
         <v-btn
          v-show="!readonly"
          depressed
          small
          color="primary"
          @click="deleteSubTarget(i)"
        >
          <v-icon left dark dense>mdi-trash-can</v-icon>
          削除
        </v-btn>

      </div>

      <v-row>
        <v-col sm="9" md="4">
          <v-row class="ma-0 pa-0">
            <v-col align="right" class="ma-0 pa-0">
              <input-sample class="input-sample" :refDoc="true" reference="TargetUser_demogra.txt" label="デモグラ"></input-sample>
            </v-col>
          </v-row>
          <v-textarea
            v-model="item.subDemogra"
            class="text-body-2"
            outlined
            label="デモグラ"
            :clearable="!readonly"
            :readonly="readonly"
            auto-grow row-height="12" no-resize
          ></v-textarea>
        </v-col>
        <v-col sm="12" md="4">
          <v-row class="ma-0 pa-0">
            <v-col align="right" class="ma-0 pa-0">
              <input-sample class="input-sample" :refDoc="true" reference="TargetUser_features.txt" label="ターゲット特長"></input-sample>
            </v-col>
          </v-row>
          <v-textarea
            v-model="item.subFeatures"
            class="text-body-2"
            outlined
            label="ターゲット特長"
            :clearable="!readonly"
            :readonly="readonly"
            auto-grow row-height="12" no-resize
          ></v-textarea>
        </v-col>
        <v-col sm="12" md="4">
          <v-row class="ma-0 pa-0">
            <v-col align="right" class="ma-0 pa-0">
              <input-sample class="input-sample" :refDoc="true" reference="TargetUser_needs.txt" label="ニーズ"></input-sample>
            </v-col>
          </v-row>
          <v-textarea
            v-model="item.subNeeds"
            class="text-body-2"
            outlined
            label="ニーズ"
            :clearable="!readonly"
            :readonly="readonly"
            auto-grow row-height="12" no-resize
          ></v-textarea>
        </v-col>
      </v-row>
    </div>


    <div v-if="target && target.subTarget && target.subTarget.length < 3" class="d-flex justify-center">
      <v-btn
        v-show="!readonly"
        fab
        outlined
        color="primary"
        depressed
        @click="addSubTarget"
        width="45"
        height="45"
      >
        <v-icon color="primary">mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import InputSample from '../atoms/InputSample.vue';

export default {
  components: {
    InputSample,
  },
  props: ['value', 'readonly'],
  data () {
    return {
      target: {
        mainDemogra: null,
        mainFeatures: null,
        mainNeeds: null,

        subTarget: [
          {
            subDemogra: null,
            subFeatures: null,
            subNeeds: null,
          },
        ],
      },
    }
  },
  created(){
    if(this.value) {
      this.target = this.value
    }
  },
  watch: {
    value(val) {
      this.target = val
    },
  },
  methods: {
    addSubTarget: function(){
      this.target.subTarget.push({ subDemogra: null, subFeatures: null, subNeeds: null, })
    },
    deleteSubTarget: function(i){
      this.target.subTarget.splice(i, 1)
    }
  },
}
</script>
<style lang="scss" scoped>
.target-user-wrap {
  .input-sample{
    margin-top: -3px;
  }
}
</style>