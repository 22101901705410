import { render, staticRenderFns } from "./ThemeColors.vue?vue&type=template&id=23ea5752&scoped=true&"
import script from "./ThemeColors.vue?vue&type=script&lang=js&"
export * from "./ThemeColors.vue?vue&type=script&lang=js&"
import style0 from "./ThemeColors.vue?vue&type=style&index=0&id=23ea5752&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ea5752",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VColorPicker,VMenu,VResponsive,VRow})
