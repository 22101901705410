/**
 * 4P4C分析情報 Request Item Class.
 *
 */

export default class FourPsFourCsRequestItem {
  // 製品
  product = null;
  // 価格
  price = null;
  // 流通
  place = null;
  // 販促
  promotion = null;
  // 顧客価値
  customerValue = null;
  // 顧客にとっての経費
  cost = null;
  // 顧客利便性
  convenience = null;
  // 顧客とのコミュニケーション
  communication = null;
  // 4P-4Cまとめ
  fourPsFourCsSummary = null;
  // 入力ステータス
  inputStatusFourPsFourCs = null;
}