export class StrategyPartsMetaDictItem {
  comp = null;
  getterKey = null;
  inputDataKey = null;
  fetchAction = null;
  updateAction = null;
  initData = null;

  constructor(comp, getterKey, inputDataKey, fetchAction, updateAction, initData){
    // コンポーネント名
    this.comp = comp; 

    // getter呼び出しのキー文字列
    this.getterKey= getterKey;

    // 各セクションのデータの入力データ部分のキー
    // data: {
    //   xxxxx: {}, ←これ
    //   sheetInfo: {}
    // } 
    this.inputDataKey = inputDataKey;

    // 取得処理のアクション名
    this.fetchAction = fetchAction;

    // 更新処理のアクション名
    this.updateAction = updateAction;

    // 初期化データ
    this.initData = initData;
  }
}

const strategyPartsMetaDict = {
  // BP 経営戦略
  philosophy: new StrategyPartsMetaDictItem('ManegementPhilosophy', 'brand', 'philosophy', 'fetchBrand', 'updateBrand' ),

  // BP 組織情報
  organization: new StrategyPartsMetaDictItem('BrandNumInfo', 'brand', 'profile', 'fetchBrand', 'updateBrand'),

  // BP ビジョン
  mvv: new StrategyPartsMetaDictItem('CompanyVision', 'brand', 'vision', 'fetchBrand', 'updateBrand' ),

  // BP 現状の戦略と実施施策
  strategy: new StrategyPartsMetaDictItem('CurrentStrategyAndAction', 'brand', 'current', 'fetchBrand', 'updateBrand'),

  // BP サービス
  service: new StrategyPartsMetaDictItem('Service', 'bpService', 'bpService', 'fetchService', 'updateService'),

  // BP 競合分析
  competitor: new StrategyPartsMetaDictItem('Competitors', 'brand', 'competitors', 'fetchBrand', 'updateBrand'),

  // SWOT分析
  swot: new StrategyPartsMetaDictItem('SwotAnalysis', 'swotData', 'swot', 'fetchSwot', 'updateSwot'),

  // 3C分析
  "3c": new StrategyPartsMetaDictItem('ThreeCsAnalysis', 'threeCsData', 'threeCs', 'fetchThreeCs', 'updateThreeCs'),

  // STP分析
  stp: new StrategyPartsMetaDictItem('StpAnalysis', 'stpData', 'stp', 'fetchStp', 'updateStp'),

  // 5Force分析
  "5forces": new StrategyPartsMetaDictItem('FiveForcesAnalysis', 'fiveForcesData', 'fiveForces', 'fetchFiveForces', 'updateFiveForces'),

  // PEST分析
  pest: new StrategyPartsMetaDictItem('PestAnalysis', 'pestData', 'pest', 'fetchPest', 'updatePest'),

  // 4P4C分析
  "4p4c": new StrategyPartsMetaDictItem('FourPsFourCsAnalysis', 'fourPsFourCsData', 'fourPsFourCs', 'fetchFourPsFourCs', 'updateFourPsFourCs'),

  // 4P競合比較分析
  "4p-comparison": new StrategyPartsMetaDictItem('FourPsComparisonAnalysis', 'fourPsComparisonData', 'fourPsComparison', 'fetchFourPsComparison', 'updateFourPsComparison'),

  // ビジネスモデルキャンバス
  businessmodel: new StrategyPartsMetaDictItem('BusinessModelCanvas', 'businessModelCanvasData', 'businessModelCanvas', 'fetchBusinessModelCanvas', 'updateBusinessModelCanvas'),

  // ターゲットユーザ
  target: new StrategyPartsMetaDictItem('TargetUser', 'user', 'target', 'fetchUser', 'updateUser'),

  // ペルソナ
  'persona-cjm': new StrategyPartsMetaDictItem('PersonaInfo', 'user', 'persona', 'fetchUser', 'updateUser'),

  // デザイン要件
  design: new StrategyPartsMetaDictItem('DesignRequirements', 'design', 'design', 'fetchDesign', 'updateDesign'),
  
  // 目標数値
  targetgoal: new StrategyPartsMetaDictItem('TargetValues', 'targetValuesData', 'targetValues', 'fetchTargetValues', 'updateTargetValues'),
  
  // プランニング
  planning: new StrategyPartsMetaDictItem('PlanningSheet', 'plan', null, 'fetchPlan', 'updatePlan'),
}

export default strategyPartsMetaDict;