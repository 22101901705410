<template>
  <div class="pt-3 stp-analysis-wrap sp-common-width-wide ref-mode">
    <div class="text-center" v-show="!refMode && !_competitors.length">
      <div class="btPrimary--text">
        ※競合情報が登録されていません。事前に競合情報の登録をおススメします。
      </div>
      <div v-show="hasWritePermission">
        <router-link
          :to="{
            name: 'StrategyPartsEditPage',
            params: { partsName: 'competitor' },
          }"
        >
          <span>→競合情報を登録する</span>
        </router-link>
      </div>
    </div>

    <div
      class="pt-10"
      v-show="isShowRefs(refMode, [stp.stpSegmentation, stp.stpTarget])"
    >
      <parts-element-label
        label="セグメンテーションとターゲティング"
        description="顧客を分類する要素とクライアントにふさわしいターゲティングを整理してください"
        :readonly="!hasWritePermission || refMode"
        class="mb-3"
      />
      <div class="mb-3" v-show="isShowRef(refMode, stp.stpSegmentation)">
        <div class="text-caption">・セグメンテーション</div>
        <v-textarea-with-link
          v-model="stp.stpSegmentation"
          :readonly="readonly"
          reference="StpAnalysis_stpSegmentation.txt"
          :refDoc="true"
          :refMode="!hasWritePermission || refMode"
        ></v-textarea-with-link>
      </div>
      <div v-show="isShowRef(refMode, stp.stpTarget)">
        <div class="text-caption">・ターゲティング</div>
        <v-textarea-with-link
          v-model="stp.stpTarget"
          :readonly="readonly"
          reference="StpAnalysis_stpTarget.txt"
          :refDoc="true"
          :refMode="refMode"
        ></v-textarea-with-link>
      </div>
    </div>

    <div
      class="pt-10 ref-mode"
      v-if="isShowRef(refMode, stp.stpDifferentiation)"
    >
      <parts-element-label
        label="お客様がクライアントを選ぶ理由（他社との差別化要素）"
        description="お客様がクライアントを選定する理由を複数記入してください"
        :readonly="refMode"
        class="mb-3"
      />
      <v-combobox
        v-model="stp.stpDifferentiation"
        chips
        outlined
        :items="MasterData.stpDifferentiation"
        item-text="stpDifferentiation"
        item-value="stpDifferentiation"
        :return-object="false"
        multiple
        :readonly="!hasWritePermission || refMode"
      >
      </v-combobox>
    </div>

    <div class="pt-10" v-show="isShowRef_Competitors(refMode, competitors)">
      <parts-element-label label="比較される競合他社" class="mb-3" />
      <div v-if="!refMode" class="d-flex align-center mb-10">
        <div class="mr-10">
          <div>よく比較される競合名を記入してください</div>
        </div>
        <div>
          <v-btn
            depressed
            small
            tile
            color="btPrimary"
            class="white--text"
            @click="loadCompetitorsDialog = true"
            v-show="!refMode && hasWritePermission"
          >
            登録済み競合情報を呼び出す
          </v-btn>
        </div>
      </div>
      <div class="px-2">
        <div class="mb-5">
          <div
            v-for="(item, i) in competitors"
            :key="i"
            class="d-flex align-center mb-3"
          >
            <div class="mr-10">{{ `競合名${i + 1}` }}</div>
            <v-text-field
              :value="competitors[i]"
              @blur="$set(competitors, i, $event.target._value)"
              placeholder="競合名を入力"
              outlined
              hide-details
              :class="{ 'mr-10': !(!hasWritePermission || refMode) }"
              dense
              :readonly="!hasWritePermission || refMode"
            ></v-text-field>
            <v-btn
              v-show="!refMode && hasWritePermission"
              icon
              @click="competitors.splice(i, 1)"
              ><v-icon>mdi-trash-can</v-icon></v-btn
            >
          </div>
        </div>
        <div v-show="!refMode && hasWritePermission" class="text-center">
          <common-add-btn color="btPrimary" @click="add()" />
        </div>
      </div>
    </div>

    <div class="pt-10"></div>

    <parts-element-label label="ポジショニングマップ" class="mb-3" />
    <positioning-map-tab
      v-if="stp.positioning"
      v-model="stp.positioning"
      :competitors="competitors"
      :noteMode="noteMode"
      :readonly="readonly"
      :refMode="refMode"
      :differentiation="stp.stpDifferentiation"
    />
    <v-row class="mt-6" v-show="isShowRef(refMode, stp.stpSummary)">
      <v-col cols="12">
        <parts-input-textarea
          v-model="stp.stpSummary"
          label="STP分析まとめ"
          :readonly="readonly"
          :background-color="bgColor"
          :refMode="refMode"
        ></parts-input-textarea>
      </v-col>
    </v-row>

    <!-- 競合情報呼び出し確認ダイアログ -->
    <yes-no-dialog
      v-model="loadCompetitorsDialog"
      yesText="呼び出す"
      noText="戻る"
      @clickYes="loadCompetitors()"
      @clickNo="loadCompetitorsDialog = false"
      center
    >
      競合情報から競合名を取得します。<br />
      現在入力している競合名は破棄されます。
    </yes-no-dialog>
  </div>
</template>
<script>
import InputSample from "@/components/atomic/atoms/InputSample.vue";
import PartsInputTextarea from "@/components/atomic/molecules/PartsInputTextarea.vue";
import PartsElementLabel from "../../atoms/PartsElementLabel.vue";
import VTextareaWithLink from "../../atoms/VTextareaWithLink.vue";
import YesNoDialog from "../../atoms/YesNoDialog.vue";
import PositioningMapTab from "../PositioningMapTab.vue";
import CommonAddBtn from "../../atoms/CommonAddBtn.vue";

import { mapActions, mapGetters } from "vuex";
import MasterData from "../../../../utils/MasterData";

export default {
  components: {
    InputSample,
    PartsInputTextarea,
    PartsElementLabel,
    VTextareaWithLink,
    YesNoDialog,
    PositioningMapTab,
    CommonAddBtn,
  },
  props: ["value", "readonly", "refMode", "noteMode"],
  data() {
    return {
      stp: {
        stpSegmentation: null,
        stpTarget: null,
        stpDifferentiation: [],
        stpCompetitor: [],
        stpVertical: null,
        stpHorizontal: null,
        stpPositioningmap: null,
        stpSummary: null,
      },
      competitors: ["", "", ""],
      loadCompetitorsDialog: false,
      bgColor: { default: "white" },
      MasterData,
    };
  },
  computed: {
    ...mapGetters(["brand", "hasWritePermission"]),

    // 競合情報
    _competitors() {
      return this.brand?.competitors?.addItems?.map((item) => item.targetName);
    },
  },

  methods: {
    ...mapActions(["fetchBrand"]),

    async loadCompetitors() {
      await this.fetchBrand();
      this.competitors = this._competitors;
      this.loadCompetitorsDialog = false;
    },

    add() {
      this.competitors.push("");
    },

    isShowRef_Competitors(refMode, value) {
      return !refMode || value.some((e) => e !== "");
    },
  },

  async created() {
    if (this.value) {
      this.stp = this.value;
      this.competitors = this.stp.stpCompetitor;
    }

    // 競合情報を読み込み
    await this.fetchBrand();
  },
  watch: {
    value(val) {
      this.stp = val;
      this.competitors = this.stp.stpCompetitor;
    },
    competitors(to) {
      const COMPETITOR_MIN_LEN = 3;

      // 最低でも3つ分の入力欄は作っておく
      while (to.length < COMPETITOR_MIN_LEN) {
        this.competitors.push("");
      }

      this.$set(
        this.stp,
        "stpCompetitor",
        to.filter((i) => i.trim())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.stp-analysis-wrap {
  .input-sample {
    margin-top: -3px;
  }
}
</style>
