/**
 * プラン情報 Request Item Class.
 *
 */

export default class PlanRequestItem {

  // サマリ
  summary = null;
  // 入力ステータス（まとめ）
  inputStatusPlan = null;

}