<template>
  <div class="swot-matrix">
    <table class="swot-table mb-6">
      <colgroup>
        <col style="width: 27px">
        <col style="width: 27px">
        <col style="width: 32%">
        <col style="width: 32%">
        <col style="width: 32%">
      </colgroup>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <th colspan="2" class="big-th">内部環境</th>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <th class="mini-th">強み</th>
        <th class="mini-th">弱み</th>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td class="td-outer" valign="top">
          <div class="td-inner">
            <p class="ma-0 pt-1 pl-1 text-subtitle-2 font-weight-bold">強み</p>
            <v-textarea
              class="bg-light-blue text-body-2 mb-4"
              v-model="swotMatrix.swotStrengths"
              :readonly="readonly"
              hide-details
              rows="6" no-resize
              flat
              solo
            ></v-textarea>
          </div>
        </td>
        <td class="td-outer" valign="top">
          <div class="td-inner">
            <p class="ma-0 pt-1 pl-1 text-subtitle-2 font-weight-bold">弱み</p>
            <v-textarea
              class="bg-light-blue text-body-2 mb-4"
              v-model="swotMatrix.swotWeakness"
              :readonly="readonly"
              hide-details
              rows="6" no-resize
              flat
              solo
            ></v-textarea>
          </div>
        </td>
      </tr>
      <tr>
        <th rowspan="2" class="big-th pa-1">
          <span class="th-text-vertical">外部環境</span>
        </th>
        <th class="mini-th pa-1">
          <span class="th-text-vertical">機会</span>
        </th>
        <td class="td-outer" valign="top">
          <div class="td-inner">
            <p class="ma-0 pt-1 pl-1 text-subtitle-2 font-weight-bold">機会</p>
            <v-textarea class="bg-light-blue text-body-2 mb-4"
              v-model="swotMatrix.swotOpportunity"
              :readonly="readonly"
              hide-details
              rows="6" no-resize
              flat
              solo
            ></v-textarea>
          </div>
        </td>
        <td class="td-outer" valign="top">
          <div class="td-inner">
            <p class="ma-0 pt-1 pl-1 text-subtitle-2 font-weight-bold">強み × 機会</p>
            <v-textarea class="border-blue text-body-2 mb-4"
              v-model="swotMatrix.swotStrengthsOpportunity"
              :readonly="readonly"
              hide-details
              rows="6" no-resize
              flat
              solo
            ></v-textarea>
          </div>
        </td>
        <td class="td-outer" valign="top">
          <div class="td-inner">
            <p class="ma-0 pt-1 pl-1 text-subtitle-2 font-weight-bold">弱み × 機会</p>
            <v-textarea
              class="border-blue text-body-2 mb-4"
              v-model="swotMatrix.swotWeaknessOpportunity"
              :readonly="readonly"
              hide-details
              rows="6" no-resize
              flat
              solo
            ></v-textarea>
          </div>
        </td>
      </tr>
        <tr>
        <th class="mini-th pa-1">
          <span class="th-text-vertical">脅威</span>
        </th>
        <td class="td-outer" valign="top">
          <div class="td-inner">
            <p class="ma-0 pt-1 pl-1 text-subtitle-2 font-weight-bold">脅威</p>
            <v-textarea
              class="bg-light-blue text-body-2 mb-4"
              v-model="swotMatrix.swotThreat"
              :readonly="readonly"
              hide-details
              rows="6" no-resize
              flat
              solo
            ></v-textarea>
          </div>
        </td>
        <td class="td-outer" valign="top">
          <div class="td-inner">
            <p class="ma-0 pt-1 pl-1 text-subtitle-2 font-weight-bold">強み × 脅威</p>
            <v-textarea
              class="border-blue text-body-2 mb-4"
              v-model="swotMatrix.swotStrengthsThreat"
              :readonly="readonly"
              hide-details
              rows="6" no-resize
              flat
              solo
            ></v-textarea>
          </div>
        </td>
        <td class="td-outer" valign="top">
          <div class="td-inner">
            <p class="ma-0 pt-1 pl-1 text-subtitle-2 font-weight-bold">弱み × 脅威</p>
            <v-textarea
              class="border-blue text-body-2 mb-4"
              v-model="swotMatrix.swotWeaknessThreat"
              :readonly="readonly"
              hide-details
              rows="6" no-resize
              flat
              solo
            ></v-textarea>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: ['value', 'readonly'],
  data () {
    return {
      swotMatrix: {
        swotStrengths: null,
        swotWeakness: null,
        swotOpportunity: null,
        swotThreat: null,
        swotStrengthsOpportunity: null,
        swotWeaknessOpportunity: null,
        swotStrengthsThreat: null,
        swotWeaknessThreat: null,
      }
    }
  },
  created(){
    if(this.value){
      this.swotMatrix = this.value
    }
  },
  methods: {
  },
  watch: {
    value(val){
      this.swotMatrix = val
    }
  }
}
</script>
<style lang="scss" scoped>
.swot-matrix{
  .swot-table{
    width: 100%;
  }
  .swot-head-x{
    text-align: center;
  }


  .table tr:not(:first-child) th span {
    writing-mode: vertical-lr;
  }

  .mini-th{
    border-radius: 5px;
    background-color:#039BE5;
    color: white;
    line-height: 1.7;
  }
  .big-th{
    border-radius: 5px;
    background-color: #0D47A1;
    color: white;
    line-height: 1.7;
  }
  .td-outer{
    border: 1px grey solid;
    border-radius: 5px;
    background-color: white;
  }
  .td-inner{
    padding: 6px 6px 0 6px;
    .v-textarea{
      &.bg-light-blue{
        .v-input__slot{
          fieldset{
            background-color: #E0F0F7;
          }
        }
      }
      &.border-blue{
        .v-input__slot{
          fieldset{
            border: solid #0D47A1;
          }
        }
      }
    }
  }
}
</style>