<template>
  <div>
    <v-card color="grey lighten-3">
      <div v-show="isOldVer" class="text-caption red--text py-2 pl-3">データのバージョンが古いため、従来のエディタが使われています。新しいエディタを使用する場合はデータをリセットしてください。</div>
      <v-toolbar class="x-scroll" elevation="0">
        <v-btn depressed tile :disabled="readonly || sectionReadonly" :color="editable ? 'btAccent' : 'btPrimary'" class="mr-5 white--text" @click="saveOrEditStart()">
          <v-icon>mdi-content-save-edit</v-icon>
          {{ editable ? '終了' : (isOldVer ? '編集' : '競合を読み込んで編集') }}
        </v-btn>
        <v-btn depressed tile :disabled="!editMode || sectionReadonly" color="btCancel" class="mr-5" @click="openResetDialog()">
          RESET
        </v-btn>
        <v-btn v-show="isOldVer" :disabled="!editMode || sectionReadonly" text class="mr-3" @click="addCircle(5, 5, ' ')">
          <v-icon>mdi-shape-circle-plus</v-icon>
          ADD
        </v-btn>
        <v-btn v-show="isOldVer" :disabled="!editMode || sectionReadonly" text class="mr-3" @click="addLabel(5, 5, 'ラベル')">
          <v-icon class="mr-1">mdi-alphabetical</v-icon>
          LABEL
        </v-btn>
        <v-btn text class="mr-3" :disabled="!editMode || !isSelectedObject || sectionReadonly" @click="removeObject()">
          <v-icon>mdi-trash-can-outline</v-icon>
          REMOVE
        </v-btn>
        <v-btn v-show="isOldVer" text class="mr-3" :disabled="!editMode || !isSelectedObject || sectionReadonly" @click="openDialog(canvas.getActiveObject())">
          <v-icon>mdi-card-text-outline</v-icon>
          EDIT
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="changeColor('rgb(128,128,128)', 'rgba(255,255,255,0.8)')" class="mr-1" color="grey" outlined width="28" min-width="28" height="28" :disabled="sectionReadonly"></v-btn>
        <v-btn @click="changeColor('rgb(200,200,200)', 'rgba(220,220,220,0.8)')" class="mr-1" color="grey" depressed width="28" min-width="28" height="28" :disabled="sectionReadonly"></v-btn>
        <v-btn @click="changeColor('rgb(255,0,0)', 'rgba(250,170,170,0.8)')" class="mr-1" color="red" depressed width="28" min-width="28" height="28" :disabled="sectionReadonly"></v-btn>
        <v-btn @click="changeColor('rgb(0,255,0)', 'rgba(170,250,170,0.8)')" class="mr-1" color="green" depressed width="28" min-width="28" height="28" :disabled="sectionReadonly"></v-btn>
        <v-btn @click="changeColor('rgb(0,0,255)', 'rgba(170,170,250,0.8)')" class="mr-1" color="blue" depressed width="28" min-width="28" height="28" :disabled="sectionReadonly"></v-btn>
        <v-btn @click="changeColor('rgb(255,255,0)', 'rgba(250,250,170,0.8)')" class="mr-1" color="yellow" depressed width="28" min-width="28" height="28" :disabled="sectionReadonly"></v-btn>
        <v-btn @click="changeStrokeWidth(1)" class="mr-1" color="grey" fab x-small depressed outlined :disabled="sectionReadonly">細</v-btn>
        <v-btn @click="changeStrokeWidth(3)" class="mr-1" color="grey" fab x-small depressed outlined :disabled="sectionReadonly">太</v-btn>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <div>
            <v-btn @click="imgCopy()" class="btPrimary" small icon>
              <v-icon color="white" small>mdi-content-copy</v-icon>
            </v-btn>
          </div>
          <div class="ml-3">
            <v-btn @click="download()" class="btPrimary" small icon>
              <v-icon color="white" small>mdi-download-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- <a @click="download()" download="" :href="fileImage" class="black--text">DOWNLOAD</a> -->
      </v-toolbar>
      <div class="x-scroll" style="overflow-x: auto">
        <v-card color="transparent" tile elevation="0" :disabled="sectionReadonly">
          <div class="d-flex justify-center pa-10">
            <canvas :ref="'positioning-map-editor-canvas-' + _uid" class="canvas" width="800" height="500"></canvas>
          </div>
        </v-card>
      </div>
      <v-dialog v-model="dialog" max-width="300">
        <v-card class="pb-2">
          <v-text-field v-model="selectedItemLabel" label="ラベルを入力してください" hide-details="auto" class="pa-5" autofocus></v-text-field>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" depressed @click="editLabel()">
              OK
            </v-btn>
            <v-btn color="grey lighten-2" depressed @click="closeDialog()">
              CANCEL
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="reset" max-width="340">
        <v-card class="pb-2">
          <v-card-text class="pb-1">
            <div class="px-3 py-5 text-center">
              <div>入力内容を消去してもよろしいですか？</div>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" depressed @click="resetOK()">
              OK
            </v-btn>
            <v-btn color="grey lighten-2" depressed @click="resetCancel()">
              CANCEL
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <!-- コピーした通知 -->
    <v-snackbar v-model="notice" timeout="2000" top>
      <div class="text-center">{{ message }}</div>
    </v-snackbar>
  </div>
</template>
<script>
import VCapture from "../atoms/VCapture.vue"

import { mapGetters } from 'vuex'
import { fabric } from 'fabric';


export default {
  props: {
    value: {},
    sectionReadonly: Boolean,
    labelVertical: {
      default: "軸１",
    },
    labelHorizontal: {
      default: "軸２",
    },
    competitors: {
      default: [],
    },
    noteMode: Boolean
  },
  components: {
    VCapture,
  },
  data() {
    return {
      readonly: false,
      editable: false,

      version: 0,
      items: [],
      labels: [],
      selectedItem: null,
      selectedItemLabel: null,
      dialog: false,

      canvas: null,
      isSelectedObject: false,
      fileImage: null,

      reset: false,
      reset_callback: null,
      reset_cancel_callback: null,

      axisV: null,
      axisH: null,

      FONT_SIZE: 18,

      // jsonデータのバージョン
      VER_PH1: 1, //ph1
      VER_PH2: 2, //ph2

      notice: false,
      message: "クリップボードにコピーしました。",

    }
  },
  mounted() {
    this.init()
  },
  computed: {
    editMode() {
      return  !this.readonly && this.editable
    },
    isOldVer(){ return this.version < this.VER_PH2 }
  },
  methods: {
    init() {
      this.$store.commit('editablePositioningMap', false);

      const ref = this.$refs['positioning-map-editor-canvas-' + this._uid];
      this.canvas = new fabric.Canvas(ref);
      this.canvas.preserveObjectStacking = true

      this.canvas.on({
        'object:moving': this._ajustTextPos,
        'object:scaling': this._ajustTextPos,
        'mouse:dblclick': obj => this.openDialog(obj.target),
        'selection:cleared': () => {
          this.isSelectedObject = false;
        },
        'selection:updated': () => {
          this.isSelectedObject = true;
        },
        'selection:created': () => {
          this.isSelectedObject = true;
        },
      });

      if(this.value) {
        const _obj = JSON.parse(this.value)
        this.version = ("version" in _obj) && (_obj?.version >= this.VER_PH2) ? this.VER_PH2 : this.VER_PH1;
        this.initCanvas(false);

        _obj.items.forEach(i => {
          this.addCircle(
            i.left, 
            i.top, 
            i.text, 
            i.width, 
            i.height, 
            i.scaleX, 
            i.scaleY, 
            i.fill, 
            i.stroke, 
            i.strokeWidth,
          )
        })

        _obj.labels.forEach(i => {
          this.addLabel(
            i.left, 
            i.top, 
            i.text, 
            i.fill, 
            i.stroke, 
            true,
            i.editable,
          )
        })

        this._changeEditable(false)
      } else {
        this.version = this.VER_PH2;
        this.initCanvas(true);
      }
    },

    _changeEditable(val) {
      if(!val) this.canvas.discardActiveObject().renderAll();
      
      this.canvas.forEachObject(o => {
        if(o._l_type) {
          o.selectable = val
        }
      })

    },

    saveOrEditStart() {
      if(this.editable) {
        console.log('items', this.items.filter(i => !i || !i.c))
        console.log('laels', this.labels.filter(i => !i))

        this.save();
        this.editable = false;
      } else {
        // 競合情報を読み込む
        if(!this.isOldVer) this.loadCompetitors();
        this.editable = true;
      }
      this._changeEditable(this.editable)
      this.$store.commit('editablePositioningMap', this.editable);
    },

    save() {
      if(!this.editable) return;

      const _obj = {
        version: this.version,
        items: this.items.filter(i => i).map(i => {
          return {
            left: i.c.left, 
            top: i.c.top, 
            text: i.t.text, 
            width: i.c.width, 
            height: i.c.height, 
            scaleX: i.c.scaleX, 
            scaleY: i.c.scaleY, 
            fill: i.c.fill, 
            stroke: i.c.stroke, 
            strokeWidth: i.c.strokeWidth,
          }
        }),
        labels: this.labels.filter(i => i).map(i => {
          return {
            left: i.left, 
            top: i.top, 
            text: i.text, 
            fill: i.fill, 
            stroke: i.stroke, 
            editable: i.editable,
          }
        }),
        image: this.canvas.toDataURL({format: 'png'})
      }

      this.$emit('input', JSON.stringify(_obj))
    },
    initCanvas(withLabel, reset = false) {
      if(!this.isOldVer || reset) this.initData();

      this.canvas.clear()
      this.canvas.backgroundColor = 'rgb(255,255,255)'
      this.canvas.add(new fabric.Line([400, 10, 400, 490], {
        fill: 'gray',
        stroke: 'gray',
        strokeWidth: 1,
        selectable: false,
        evented: false,
      }));
      this.canvas.add(new fabric.Line([10, 250, 790, 250], {
        fill: 'gray',
        stroke: 'gray',
        strokeWidth: 1,
        selectable: false,
        evented: false,
      }));

      if(withLabel) {
        if(this.isOldVer) {
          this.addLabel(405, 10, '軸タイトル')
          this.addLabel(405, 470, '軸タイトル')
          this.addLabel(10, 230, '軸タイトル')
          this.addLabel(680, 230, '軸タイトル')
        }
      } 

      if(!this.isOldVer) {
        if(reset || !this.value) {
          this.addLabel(405, 40, '高', '', '', true, true)
          this.addLabel(405, 470, '低', '', '', true, true)
          this.addLabel(10, 230, '低', '', '', true, true)
          this.addLabel(770, 230, '高', '', '', true, true)
        }

        const labelV = this.labelVertical ?? "軸１";
        const labelH = this.labelHorizontal ?? "軸２";
        this.axisV = this.addLabel(405, 10, labelV, '', '', false)
        this.axisH = this.addLabel(this.calcOffset(labelH), 200, labelH, '', '', false);
      }
    },
    initData() {
      this.version = this.VER_PH2;
      if(this.version >= this.VER_PH2) {
        this.items = [];
        this.labels = [];
      }
    },
    addCircle(left, top, text, width, height, scaleX, scaleY, fill, stroke, strokeWidth) {
      width = width ? width : 140
      height = height ? height : 140
      scaleX = scaleX ? scaleX : 1
      scaleY = scaleY ? scaleY : 1
      fill = fill ? fill : 'rgba(255,255,255,0.8)'
      stroke = stroke ? stroke : 'rgb(128,128,128)'
      strokeWidth = strokeWidth ? strokeWidth : 1

      const c = new fabric.Circle({
        _l_id: 'circle-' + this.items.length,
        _l_type: 'circle',
        _l_index: this.items.length,
        left: left,
        top: top,
        width: width,
        height: height,
        scaleX: scaleX,
        scaleY: scaleY,
        radius: 70,
        stroke: stroke,
        strokeWidth: strokeWidth,
        fill: fill,
        cornerColor: 'gray',
        cornerSize: 6,
        transparentCorners: false,
        hasBorders: false,

      });
      c.setControlVisible('mtr', false);

      const t = new fabric.Text(text, {
        _l_id: 'text-' + this.items.length,
        _l_type: 'text',
        _l_index: this.items.length,
        fill: '#000',
        left: 0,
        top: 0,
        padding: 5,
        fontSize: this.FONT_SIZE,
        hasControls: false,
        selectable: false,
        textAlign: 'center',
        fontFamily: 'Noto Sans JP',
      });

      this.canvas.add(c);
      this.canvas.add(t);

      this.items.push({
        c: c,
        t: t,
      });

      this.canvas.fire('object:moving', {target: t});
    },
    addLabel(left, top, text, fill, stroke, save = true, editable = false) {
      fill = fill ? fill : 'rgb(128,128,128)'
      stroke = stroke ? stroke : 'rgb(128,128,128)'

      const l = new fabric.Text(text, {
        _l_id: 'label-' + this.labels.length,
        _l_type: 'label',
        _l_index: this.labels.length,
        fill: fill,
        stroke: stroke,
        left: left,
        top: top,
        padding: 5,
        fontSize: this.FONT_SIZE,
        hasControls: false,
        // selectable: false,
        textAlign: 'left',
        fontFamily: 'Noto Sans JP',
        save: save,
        editable: editable,
      });
      this.canvas.add(l);
      if(save) this.labels.push(l);

      return l;
    },
    removeObject() {
      const activeObject = this.canvas.getActiveObject();
      if(activeObject && activeObject._l_type) {
        this.canvas.remove(activeObject);
        if(activeObject._l_type == 'label') {
          this.labels[activeObject._l_index] = null;
        } else {
          this.canvas.remove(this.items[activeObject._l_index].t);
          this.items[activeObject._l_index] = null;
        }
      }
    },
    editLabel() {
      if(this.selectedItem._l_type == 'label') {
        this.selectedItem.text = this.selectedItemLabel;
        this.canvas.renderAll();
      } else {
        this.selectedItem.t.text = this.selectedItemLabel;
        this.canvas.renderAll();
        this.canvas.fire('object:moving', {target: this.selectedItem.t});
        // this.canvas.requestRenderAll();
      }
      this.closeDialog();
    },
    openDialog(obj) {
      if(!obj || !this.editable) return;
      if(!this.isOldVer && !('editable' in obj)) return;
      if(!this.isOldVer && !obj.editable) return;

      if(obj._l_type) {
        if(obj._l_type == 'label') {
          this.selectedItem = this.labels[obj._l_index];
          this.selectedItemLabel = this.selectedItem.text;
          this.dialog = true;
        } else {
          this.selectedItem = this.items[obj._l_index];
          this.selectedItemLabel = this.selectedItem.t.text;
          this.dialog = true;
        }
      }
    },
    closeDialog() {
      this.dialog = false;
      this.selectedItem = null;
      this.selectedItemLabel = null;
      this.editable = true;
    },
    changeColor(stroke, fill) {
      if(!this.editMode) return

      const activeObject = this.canvas.getActiveObject();
      if(activeObject && activeObject._l_type) {
        if(activeObject._l_type == 'circle') {
          activeObject.set('stroke', stroke)
          activeObject.set('fill', fill)
        } else {
          activeObject.set('stroke', stroke)
          activeObject.set('fill', stroke)
        }

        this.canvas.requestRenderAll();
      }
    },
    changeStrokeWidth(val) {
      if(!this.editMode) return

      const activeObject = this.canvas.getActiveObject();
      if(activeObject && activeObject._l_type) {
        if(activeObject._l_type == 'circle') {
          activeObject.set('strokeWidth', val)
        }

        this.canvas.requestRenderAll();
      }
    },

    _ajustTextPos(obj) {
      if(obj.target._l_type && (obj.target._l_type == 'circle' || obj.target._l_type == 'text')) {
        const item = this.items[obj.target._l_index];
        const c = item.c;
        const t = item.t;
        t.top = c.top + (c.height*c.scaleY - t.height) / 2;
        t.left = c.left + (c.width*c.scaleX - t.width) / 2;
      this.canvas.requestRenderAll();
      }
    },
    download() {
      // this.fileImage = this.canvas.toDataURL({format: 'png'});
      var dt = new Date();
      var y = dt.getFullYear();
      var m = ("00" + (dt.getMonth()+1)).slice(-2);
      var d = ("00" + dt.getDate()).slice(-2);
      var dtStr = y + m + d;
      
      let link = document.createElement("a");
      link.href = this.canvas.toDataURL("image/png");
      link.download = `PositioningMap_${dtStr}`;
      link.click();
    },

    imgCopy() {
      let canvas = this.$refs['positioning-map-editor-canvas-' + this._uid];
      canvas.toBlob((blob) => {
        // http環境ではクリップボードの使用が許可されていないので動かない
        if (window.navigator.clipboard === undefined) {
          console.log("クリップボードの使用が許可されていません");
          return;
        }

        // クリップボードにコピー
        window.navigator.clipboard
          .write([new ClipboardItem({ "image/png": blob })])
          .then(() => {
            this.notice = true;
          });
      });
    },

    openResetDialog() {
      this.reset = true;
    },
    resetOK() {
      const withLabel = this.isOldVer ? false : true;
      this.initCanvas(withLabel, true);
      if(!this.isOldVer) this.loadCompetitors();
      this.reset = false
    },
    resetCancel() {
      this.reset = false
    },
    calcOffset(txt) {
      return (790 - txt.length * this.FONT_SIZE);
    },
    loadCompetitors() {
      // 競合を順番に追加する
      this.competitors.forEach(competitor => {
        if(!competitor) return;
        // 同じ競合が追加されている場合には何もしない
        if(this.items.filter(i => i).filter(item => item.t.text == competitor).length) return;
        this.addCircle(5, 5, competitor);
      })

      // 自社を追加する
      let myself = "自社"
      if(this.items.filter(i => i).filter(item => item.t.text == myself).length) return;
      this.addCircle(5, 5, myself);
    }
  },
  watch: {
    readonly(val) {
      this._changeEditable(!val)
    },
    labelHorizontal(to) {
      if(this.version < this.VER_PH2) return;
      to = to ?? "軸２";
      this.axisH.text = to;
      this.axisH.left = this.calcOffset(to);
      this.canvas.renderAll();
    },
    labelVertical(to) {
      if(this.version < this.VER_PH2) return;
      to = to ?? "軸１";
      this.axisV.text = to;
      this.canvas.renderAll();
    },
  },
}
</script>
<style lang="scss" scoped>
.canvas {
  background-color: #fff;
  // width: 100%;
  // border: 1px solid black;
}
.x-scroll {
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar{
      height: 8px;
      border-radius: 2.5px;
  }

  &::-webkit-scrollbar-track{
      background: #fff;
      border: none;
      border-radius: 2.5px;
  }

  &::-webkit-scrollbar-thumb{
      background: #ccc;
      border-radius: 10px;
      box-shadow: none;
  }

}

</style>