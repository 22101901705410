<template>
  <div>
    <div class="parts-element-label d-flex justify-space-between align-center mb-1">
      <span class="text-h6 ">{{ label }}</span>
      <v-btn
        v-show="reference"
        icon
        color="btPrimary"
        dark
        @click="clickAppendIcon"
        v-if="!readonly"
      >
        <v-icon size="32">mdi-help-circle-outline</v-icon>
      </v-btn>
    </div>
    <div v-if="!readonly">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PartsElementLabel",
  props: {
    label: String, 
    description: String,
    reference: String, 
    refDoc: Boolean, 
    refImg: Boolean, 
    readonly: {
      default: false
    }
  },
  methods: {
    clickAppendIcon(e) {
      if (this.refDoc) {
        this.$store.commit("open_referenceDocument", {
          top: e.clientY,
          left: e.clientX,
          id: this.reference,
          label: this.label,
        });
      }
      if (this.refImg) {
        this.$store.commit("open_referenceImage", {
          top: e.clientY,
          left: e.clientX,
          id: this.reference,
          label: this.label,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.parts-element-label {
  border-left: 10px solid #d1d1d1;
  border-bottom: 5px solid #d1d1d1;
  padding: 0 0 3px 10px;
}
</style>
