<template>
  <div class="ref-mode pt-1 px-0" :style="!refMode ? '' : { width: '100%' }">
    <!-- 横スクロールバー上 -->
    <div
      id="BusinessModelCanvas_topbar"
      class="x-scroll mb-1"
      :style="!refMode ? scrollAreaStyle : ''"
      v-scroll.self="onScrollXTopBar"
    >
      <div :style="!refMode ? contentAreaStyle : { width: '100%' }">
        <div
          :style="{ width: '1880px', maxWidth: 'none', height: '1px' }"
        ></div>
      </div>
    </div>
    <!-- ビジネスモデルキャンバス ＋ 横スクロールバー下 -->
    <div
      id="BusinessModelCanvas_content"
      class="x-scroll"
      :style="!refMode ? scrollAreaStyle : ''"
      v-scroll.self="onScrollXContent"
    >
      <div :style="!refMode ? contentAreaStyle : ''">
        <v-container :style="{ width: '1880px', maxWidth: 'none' }">
          <v-capture :visible="noteMode">
            <v-row class="px-5">
              <v-col class="borderd-col col-full bg-white">
                <div class="box-title">
                  <span class="text-h6 mr-1">主要パートナー</span>
                  <input-sample
                    v-if="!readonly"
                    class="input-sample"
                    :refDoc="true"
                    reference="BusinessModelCanvas_keyPartners.txt"
                    label="主要パートナー"
                  ></input-sample>
                  <p class="text-caption">Key Partners</p>
                </div>
                <v-textarea
                  v-model="businessModelCanvas.keyPartners"
                  class="text-body-2"
                  rows="16"
                  no-resize
                  outlined
                  hide-details
                  :readonly="readonly"
                  flat
                  solo
                ></v-textarea>
              </v-col>
              <v-col class="col-full bg-white">
                <v-row>
                  <v-col cols="12" class="borderd-col col-half">
                    <div class="box-title">
                      <span class="text-h6 mr-1">主要な活動</span>
                      <input-sample
                        v-if="!readonly"
                        class="input-sample"
                        :refDoc="true"
                        reference="BusinessModelCanvas_keyActivities.txt"
                        label="主要な活動"
                      ></input-sample>
                      <p class="text-caption">Key Activities</p>
                    </div>
                    <v-textarea
                      v-model="businessModelCanvas.keyActivities"
                      class="text-body-2"
                      height="189"
                      rows="6"
                      no-resize
                      outlined
                      hide-details
                      :readonly="readonly"
                      flat
                      solo
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    class="borderd-col col-half col-toprow-bottom"
                  >
                    <div class="box-title">
                      <span class="text-h6 mr-1">主要な資源</span>
                      <input-sample
                        v-if="!readonly"
                        class="input-sample"
                        :refDoc="true"
                        reference="BusinessModelCanvas_keyResources.txt"
                        label="主要な資源"
                      ></input-sample>
                      <p class="text-caption">Key Resources</p>
                    </div>
                    <v-textarea
                      v-model="businessModelCanvas.keyResources"
                      class="text-body-2"
                      height="189"
                      rows="6"
                      no-resize
                      outlined
                      hide-details
                      :readonly="readonly"
                      flat
                      solo
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="borderd-col col-full bg-white">
                <div class="box-title">
                  <span class="text-h6 mr-1">提供価値</span>
                  <input-sample
                    v-if="!readonly"
                    class="input-sample"
                    :refDoc="true"
                    reference="BusinessModelCanvas_valuePropositions.txt"
                    label="提供価値"
                  ></input-sample>
                  <p class="text-caption">Value Propositions</p>
                </div>
                <v-textarea
                  v-model="businessModelCanvas.valuePropositions"
                  class="text-body-2"
                  rows="16"
                  no-resize
                  outlined
                  hide-details
                  :readonly="readonly"
                  flat
                  solo
                ></v-textarea>
              </v-col>
              <v-col class="col-full bg-white">
                <v-row>
                  <v-col cols="12" class="borderd-col col-half">
                    <div class="box-title">
                      <span class="text-h6 mr-1">顧客との関係</span>
                      <input-sample
                        v-if="!readonly"
                        class="input-sample"
                        :refDoc="true"
                        reference="BusinessModelCanvas_customerRelationships.txt"
                        label="顧客との関係"
                      ></input-sample>
                      <p class="text-caption">Customer Relationships</p>
                    </div>
                    <v-textarea
                      v-model="businessModelCanvas.customerRelationships"
                      class="text-body-2"
                      height="189"
                      rows="6"
                      no-resize
                      outlined
                      hide-details
                      :readonly="readonly"
                      flat
                      solo
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    class="borderd-col col-half col-toprow-bottom"
                  >
                    <div class="box-title">
                      <span class="text-h6 mr-1">チャネル/販路</span>
                      <input-sample
                        v-if="!readonly"
                        class="input-sample"
                        :refDoc="true"
                        reference="BusinessModelCanvas_channels.txt"
                        label="チャネル/販路"
                      ></input-sample>
                      <p class="text-caption">Channels</p>
                    </div>
                    <v-textarea
                      v-model="businessModelCanvas.channels"
                      class="text-body-2"
                      height="189"
                      rows="6"
                      no-resize
                      outlined
                      hide-details
                      :readonly="readonly"
                      flat
                      solo
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="borderd-col col-full col-toprow-right bg-white">
                <div class="box-title">
                  <span class="text-h6 mr-1">顧客セグメント</span>
                  <input-sample
                    v-if="!readonly"
                    class="input-sample"
                    :refDoc="true"
                    reference="BusinessModelCanvas_customerSegments.txt"
                    label="顧客セグメント"
                  ></input-sample>
                  <p class="text-caption">Customer Segments</p>
                </div>
                <v-textarea
                  v-model="businessModelCanvas.customerSegments"
                  class="text-body-2"
                  rows="16"
                  no-resize
                  outlined
                  hide-details
                  :readonly="readonly"
                  flat
                  solo
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="px-5">
              <v-col cols="6" class="borderd-col col-bottomrow-bottom bg-white">
                <div class="box-title">
                  <span class="text-h6 mr-1">コスト構造</span>
                  <input-sample
                    v-if="!readonly"
                    class="input-sample"
                    :refDoc="true"
                    reference="BusinessModelCanvas_costStructure.txt"
                    label="コスト構造"
                  ></input-sample>
                  <p class="text-caption">Cost Structure</p>
                </div>
                <v-textarea
                  v-model="businessModelCanvas.costStructure"
                  class="text-body-2"
                  rows="6"
                  no-resize
                  outlined
                  hide-details
                  :readonly="readonly"
                  flat
                  solo
                ></v-textarea>
              </v-col>
              <v-col cols="6" class="borderd-col col-bottomrow-right bg-white">
                <div class="box-title">
                  <span class="text-h6 mr-1">収益の流れ</span>
                  <input-sample
                    v-if="!readonly"
                    class="input-sample"
                    :refDoc="true"
                    reference="BusinessModelCanvas_revenueStreams.txt"
                    label="収益の流れ"
                  ></input-sample>
                  <p class="text-caption">Revenue Streams</p>
                </div>
                <v-textarea
                  v-model="businessModelCanvas.revenueStreams"
                  class="text-body-2"
                  rows="6"
                  no-resize
                  outlined
                  hide-details
                  :readonly="readonly"
                  flat
                  solo
                ></v-textarea>
              </v-col>
            </v-row>
          </v-capture>
        </v-container>
      </div>
    </div>
    <div
      v-show="isShowRef(refMode, businessModelCanvas.bmcSummary)"
      class="sp-common-width-narrow mt-8"
    >
      <parts-input-textarea
        v-model="businessModelCanvas.bmcSummary"
        :readonly="readonly"
        label="ビジネスモデルキャンパス概要"
        reference="BusinessModelCanvas_bmcSummary.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import VCapture from "../../atoms/VCapture.vue";
import InputSample from "../../atoms/InputSample.vue";
import PartsInputTextarea from "../../molecules/PartsInputTextarea.vue";

export default {
  props: ["value", "readonly", "refMode", "noteMode"],
  components: {
    VCapture,
    InputSample,
    PartsInputTextarea,
  },
  data() {
    return {
      businessModelCanvas: {
        keyPartners: null,
        keyActivities: null,
        keyResources: null,
        ValuePropositions: null,
        customerRelationships: null,
        channels: null,
        customerSegments: null,
        costStructure: null,
        revenueStreams: null,
        bmcSummary: null,
      },

      message: "クリップボードにコピーしました。",
    };
  },
  computed: {
    ...mapGetters("relativeScrollableArea", [
      "scrollAreaStyle",
      "contentAreaStyle",
    ]),
  },
  created() {
    if (this.value) {
      this.businessModelCanvas = this.value;
    }
  },
  methods: {
    onScrollXTopBar(e) {
      document
        .getElementById("BusinessModelCanvas_content")
        .scrollTo(e.target.scrollLeft, 0);
    },
    onScrollXContent(e) {
      document
        .getElementById("BusinessModelCanvas_topbar")
        .scrollTo(e.target.scrollLeft, 0);
    },
  },
  watch: {
    value(val) {
      this.businessModelCanvas = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.borderd-col {
  border-width: 1px 0 1px 1px;
  border-color: black;
  border-style: solid;
}
.col-full {
  height: 556px !important;
}
.col-half {
  height: 278px !important;
}
.col-toprow-right {
  border-width: 1px 1px 1px 1px;
}
.col-bottomrow-right {
  border-width: 0 1px 1px 1px;
}
.col-toprow-bottom,
.col-bottomrow-bottom {
  border-width: 0 0 1px 1px;
}
.col-bottomrow-bottom,
.col-bottomrow-right {
  height: 275px !important;
}
.box-title {
  height: 60px;
}
.bg-white {
  background-color: #fff;
}
.x-scroll {
  padding-bottom: 8px;
  overflow-y: hidden;
  overflow-x: auto;
  left: 0 !important;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border: none;
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    box-shadow: none;
  }
}
.input-sample {
  float: right;
  margin-top: -3px;
}
</style>
