<template>
  <v-card outlined elevation="5">
    <v-card-title v-show="showLabel"> 追加のヒアリング事項 </v-card-title>

    <v-card-text class="py-0">
      <v-divider v-show="showLabel"></v-divider>
      <div class="d-flex flex-wrap">
        <div
          v-for="(item, i) in hearingAddItems"
          :key="item.id"
          class="hearing-item"
        >
          <v-row class="align-center">
            <v-col cols="10" md="8">
              <v-text-field
                v-model="item.label"
                placeholder="ヒアリング項目のタイトルを入力してください"
                class="hearing-item-title"
                :readonly="readonly"
              ></v-text-field>
            </v-col>
            <v-col cols="2" md="4" class="text-right">
              <v-btn
                v-show="!readonly"
                depressed
                small
                color="primary"
                @click="deleteHearingAddItem(i)"
              >
                <v-icon left dark dense>mdi-trash-can</v-icon>
                削除
              </v-btn>
            </v-col>
          </v-row>
          <v-textarea
            outlined
            auto-grow
            hide-details
            v-model="item.text"
            :readonly="readonly"
          ></v-textarea>
        </div>
        <v-col class="d-flex justify-center align-center my-5" cols="12">
          <common-add-btn
            v-if="!readonly"
            color="btPrimary"
            @click="addHearingItem"
          />
        </v-col>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import CommonAddBtn from "../atoms/CommonAddBtn.vue";

export default {
  components: {
    CommonAddBtn,
  },
  props: {
    showLabel: { default: true },
    value: Array,
    readonly: Boolean,
    refMode: Boolean,
  },
  data() {
    return {
      hearingAddItems: [],
    };
  },
  created() {
    if (this.value) {
      if (this.refMode) {
        this.hearingAddItems = this.value.filter((e) => e.label || e.text);
      } else {
        this.hearingAddItems = this.value;
      }
    }
  },
  methods: {
    addHearingItem: function () {
      this.hearingAddItems.push({ label: null, text: null });
    },
    deleteHearingAddItem: function (i) {
      this.hearingAddItems.splice(i, 1);
    },
  },
  watch: {
    value(val) {
      if (this.refMode) {
        this.hearingAddItems = val.filter((e) => e.label || e.text);
      } else {
        this.hearingAddItems = val;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.hearing-item-title {
  max-width: 700px;
}
.hearing-item {
  min-width: 300px;
  width: 100%;
}
</style>
