/**
 * 4P競合比較分析情報 Request Item Class.
 *
 */

export default class FourPsComparisonRequestItem {
  // 商品・サービス
  product = null;
  // 価格帯
  price = null;
  // 立地・流通方法
  place = null;
  // プロモーション
  promotion = null;
  // 4P比較まとめ
  summary = null;
  // 競合データ
  competitors = [];
  // 入力ステータス
  inputStatusFourPsComparison = null;
}