/**
 * 作成者情報 Request Item Class.
 *
 */

export default class SheetInfoRequestItem {

  // 作成日時(文字列（日時）)
  createdAt = null;
  // 作成者(数値)
  createdUser = null;
  // 作成者名
  createdUserName = null;
  // 更新日時(文字列（日時）)
  updatedAt = null;
  // 更新者(数値)
  updatedUser = null;
  // 更新者名
  updatedUserName = null;

}