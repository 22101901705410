<template>
  <v-text-field
    hide-details
    v-model="numVal"
    :label="label" :prefix="prefix" :suffix="suffix" outlined
    inputmode="numeric"
    :readonly="readonly"
    type="tel"
	  maxlength=5
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: [Number, String],
    label: String,
    prefix: String,
    suffix: String,
    readonly: Boolean,
  },
  computed: {
    numVal: {
      get() {
        return this.value ? Math.floor(this.value*100) : 0
      },
      set(val) {
        this.$emit('input', val ? Number(val.replace(/,/g, ''))/100 : 0)
      },
    },
  },
  methods: {
  },
}
</script>
