<template>
  <div class="sp-common-width-wide pt-5 company-vision-wrap ref-mode">
    <!-- ビジョンのマトリクス -->
    <div class="mb-12">
      <v-row no-gutters>
        <v-col class="pr-2 mb-2">
          <div class="head-title">
            <span class="mr-2">ビジョン</span>
          </div>
          <div class="head-description">
            企業・組織が社会に対して「なすべきこと」
          </div>
        </v-col>
        <v-col class="pr-2 mb-2">
          <div class="head-title">
            <span class="mr-2">売上</span>
          </div>
          <div class="head-description">企業・組織が目指す「あるべき姿」</div>
        </v-col>
        <v-col class="pr-2 mb-2">
          <div class="head-title">
            <span class="mr-2">{{organizationLabel}}</span>
          </div>
          <div class="head-description">企業・組織の構成員が具体的に「やるべきこと」</div>
        </v-col>
      </v-row>

      <div class="ma-0 pt-4 pb-1 text-body-2">1年後</div>
      <v-row no-gutters class="my-0">
        <v-col class="pr-5 ma-0">
          <v-textarea
            v-model="visionItems.vision1"
            class="text-body-2"
            :readonly="readonly"
            outlined
            auto-grow
            hide-details
            background-color="white"
          ></v-textarea>
        </v-col>
        <v-col class="pr-5 ma-0">
          <v-textarea
            v-model="visionItems.performance1"
            class="text-body-2"
            :readonly="readonly"
            outlined
            auto-grow
            hide-details
            background-color="white"
          ></v-textarea>
        </v-col>
        <v-col>
          <v-textarea
            v-model="visionItems.organization1"
            class="text-body-2"
            :readonly="readonly"
            outlined
            auto-grow
            hide-details
            background-color="white"
          ></v-textarea>
        </v-col>
      </v-row>

      <div class="ma-0 pt-4 py-1 text-body-2">3年後</div>
      <v-row no-gutters class="my-0">
        <v-col class="pr-5 ma-0">
          <v-textarea
            v-model="visionItems.vision2"
            class="text-body-2"
            :readonly="readonly"
            outlined
            auto-grow
            hide-details
            background-color="white"
          ></v-textarea>
        </v-col>
        <v-col class="pr-5 ma-0">
          <v-textarea
            v-model="visionItems.performance2"
            class="text-body-2"
            :readonly="readonly"
            outlined
            auto-grow
            hide-details
            background-color="white"
          ></v-textarea>
        </v-col>
        <v-col>
          <v-textarea
            v-model="visionItems.organization2"
            class="text-body-2"
            :readonly="readonly"
            outlined
            auto-grow
            hide-details
            background-color="white"
          ></v-textarea>
        </v-col>
      </v-row>

      <div class="ma-0 pt-4 py-1 text-body-2">5年後</div>
      <v-row no-gutters class="my-0">
        <v-col class="pr-5 ma-0">
          <v-textarea
            v-model="visionItems.vision3"
            class="text-body-2"
            :readonly="readonly"
            outlined
            auto-grow
            hide-details
            background-color="white"
          ></v-textarea>
        </v-col>
        <v-col class="pr-5 ma-0">
          <v-textarea
            v-model="visionItems.performance3"
            class="text-body-2"
            :readonly="readonly"
            outlined
            auto-grow
            hide-details
            background-color="white"
          ></v-textarea>
        </v-col>
        <v-col>
          <v-textarea
            v-model="visionItems.organization3"
            class="text-body-2"
            :readonly="readonly"
            outlined
            auto-grow
            hide-details
            background-color="white"
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
    <!-- ビジョン設計評価 -->
    <div
      class="sp-common-width-narrow"
      v-show="isShowRef(refMode, visionItems.visionEvaluation)"
    >
      <parts-element-label label="ビジョン設計評価" class="mb-3"></parts-element-label>
      <v-select
        v-model="visionItems.visionEvaluation"
        :items="visionEvaluation"
        item-text="visionEvaluation"
        item-value="visionEvaluation"
        outlined
        :readonly="readonly"
        background-color="white"
      ></v-select>
    </div>
  </div>
</template>
<script>
import PartsElementLabel from "@/components/atomic/atoms/PartsElementLabel.vue";
import InputSample from "../../atoms/InputSample.vue";
import MasterData from "../../../../utils/MasterData";
import { mapGetters } from 'vuex';

export default {
  props: ["value", "readonly", "refMode"],
  components: {
    PartsElementLabel,
    InputSample,
  },
  data() {
    return {
      visionEvaluation: [],
      visionItems: {
        vision1: null,
        vision2: null,
        vision3: null,
        performance1: null,
        performance2: null,
        performance3: null,
        organization1: null,
        organization2: null,
        organization3: null,
        visionEvaluation: null,
      },
    };
  },
  computed: {
    ...mapGetters(["project"]),

    organizationLabel() {
      return this.project.industry == "歯科" ? "組織・医院規模" : "組織規模";
    }
  },
  created() {
    if (this.value) {
      this.visionItems = this.value;
    }
  },
  methods: {
    //ビジョン情報セクションの未入力チェック
    isEmpty() {
      if (!this.visionItems) return true;

      const vision = this.visionItems;
      let ret = false;

      //各質問項目をチェック
      const questionKeys = [
        "vision1",
        "vision2",
        "vision3",
        "performance1",
        "performance2",
        "performance3",
        "organization1",
        "organization2",
        "organization3",
        "visionEvaluation",
      ];
      ret = questionKeys.some((i) => vision[i]);
      if (ret) return false;

      return true;
    },

    clickReference_caseStudy(e) {
      this.$store.commit("open_referenceDocument", {
        top: e.clientY,
        left: e.clientX,
        id: "aaaaaaa",
      });
    },
  },
  watch: {
    value(val) {
      this.visionItems = val;
    },
    "visionItems.inputStatusVision"(val) {
      this.$store.commit("brand_input_status_vision", val);
    },
  },
  mounted() {
    this.visionEvaluation = MasterData.visionEvaluation;
  },
};
</script>
<style lang="scss" scoped>
.company-vision-wrap {
  min-width: 900px;
  .head-title {
    font-size: 20px;
    font-weight: 600;
  }
  .head-description {
    display: inline-block;
    font-size: 12px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.87);
  }
  .input-sample {
    margin-top: -3px;
  }
}
</style>
