<template>
  <v-text-field
    hide-details
    :value="numVal()"
    outlined
    inputmode="numeric"
    type="tel"
    :readonly="readonly"
    :maxlength="maxLength"
    v-bind="$attrs"
    @input="onInput"
    @keydown="onKeyDown"
    @blur="onBlur"
    ref="input"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: [Number, String],
    label: String,
    readonly: Boolean,
    maxLength: {
      default: 11
    }
  },
  methods: {
    numVal() {
      let val = !!this.value ? this.value : 0;
      return Number(val).toLocaleString()
    },
    onInput(val) {
      val = '' + val;
      this.$emit('input', !!val ? Number(val.replace(/\D/g, '')) : 0)
    },
    onKeyDown(e) {
      // 数字、enter、delete、backspace、tab以外の入力は無効にする
      const regex = new RegExp('Enter|Delete|Backspace|Tab|[0-9]');
      
      // ctrlキーが押されているときは全てのキー入力を受け付ける
      if(!e.ctrlKey && !regex.test(e.key)) {
        e.preventDefault();
      }
    },
    onBlur() {
      let val = !!this.value ? this.value : 0;
      val = Number(val).toLocaleString()
      this.$refs.input.lazyValue = val;
    },
  }
}
</script>
