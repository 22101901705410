<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn
        color="grey lighten-1"
        text
        small
        class="pr-0 pl-0"
        @click="didClickLink"
      >{{ linkLabel }}</v-btn>
    </div>

    <v-combobox
      :value="value"
      @input="update($event)"
      :items="items"
      :outlined="outlined"
      :label="label"
      :multiple="multiple"
      :chips="chips"
      :deletable-chips="deletableChips"
      :hide-details="hideDetails"
      :clearable="clearable"
      :readonly="readonly"
    ></v-combobox>
  </div>
</template>

<script>
export default {
  props: {
    linkLabel: {
      type: String,
      require: true,
      default: '',
    },
    value: {
      type: Array,
      require: true,
      default: () => [],
    },
    items: {
      type: Array,
      require: true,
      default: () => [],
    },
    label: {
      type: String,
      require: false,
      default: '',
    },
    readonly:       Boolean,
    clearable:      Boolean,
    outlined:       Boolean,
    multiple:       Boolean,
    chips:          Boolean,
    deletableChips: Boolean,
    hideDetails:    Boolean,
    reference:      String,
    refDoc: Boolean,  //参照ファイルがテキストの場合
    refImg: Boolean,  //参照ファイルが画像の場合
  },

  methods: {
    // データの更新
    update(value) {
      this.$emit('input', value)
    },

    // リンクをクリック
    didClickLink(e) {
      if (this.refDoc) {
        this.$store.commit('open_referenceDocument', {top: e.clientY, left: e.clientX, id: this.reference, label: this.label})
      }
      if (this.refImg) {
        this.$store.commit('open_referenceImage', {top: e.clientY, left: e.clientX, id: this.reference, label: this.label})
      }
    }
  },
}
</script>
