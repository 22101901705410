<template>
  <div>
    <parts-element-label
      :label="label"
      :description="description"
      :reference="reference"
      :refDoc="refDoc"
      :refImg="refImg"
      :readonly="readonly"
    ></parts-element-label>
    <v-text-field
      outlined
      hide-details
      :readonly="readonly"
      :value="value"
      :background-color="bgColor"
      @input="$emit('input', $event)"
    ></v-text-field>
  </div>
</template>

<script>
import PartsElementLabel from "../atoms/PartsElementLabel.vue";

export default {
  name: "PartsInputTextField",
  components: {
    PartsElementLabel,
  },
  props: {
    value: String,
    label: String,
    description: String,
    readonly: Boolean,
    reference: String,
    refDoc: Boolean, //参照ファイルがテキストの場合
    refImg: Boolean,
    bgColor: {
      default: "white"
    },
  },
  methods: {
    _input(val) {
      this.$emit("input", val);
    },
  },
};
</script>
