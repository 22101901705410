<template>
  <v-expansion-panel-header color="blue-grey lighten-5" class="py-0">
    <v-row no-gutters align="center">
      <v-col cols="8">
        <h3>{{label}}</h3>
        <span v-if="caption">{{caption}}</span>
      </v-col>
      <v-col cols="4" class="d-flex align-center justify-end" @click.stop>
        <v-switch
          v-model="finish"
          color="indigo"
          inset
          :ripple="false"
          :readonly="readonly"
          :disabled="disabled"
        ></v-switch>
        <section-status :status="status"></section-status>
      </v-col>
    </v-row>
    <template v-slot:actions>
    <v-icon color="primary">
        $expand
    </v-icon>
    </template>
  </v-expansion-panel-header>
</template>
<script>
import SectionStatus from '../molecules/SectionStatus';
export default {
  components: {
    SectionStatus
  },
  props: {
    label: String,
    caption: String,
    status: String,
    readonly: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      finish: false,
    }
  },
  watch: {
    status: {
      handler(val) {
        if(val == 'done') {
          this.finish = true
        }
      },
      immediate: true
    },
    finish(val) {
      this.$emit('finish', val)
    },

  },
}
</script>
