<template>
  <v-container
    class="
      sp-common-width-wide
      four-ps-four-cs-analysis-wrap
      ref-mode
      px-0
      py-0
    "
    v-if="
      isShowRefs(refMode, [
        fourPsFourCs.product,
        fourPsFourCs.price,
        fourPsFourCs.place,
        fourPsFourCs.promotion,
        fourPsFourCs.customerValue,
        fourPsFourCs.cost,
        fourPsFourCs.convenience,
        fourPsFourCs.communication,
        fourPsFourCs.fourPsFourCsSummary,
      ])
    "
  >
    <div
      v-show="
        isShowRefs(refMode, [
          fourPsFourCs.product,
          fourPsFourCs.price,
          fourPsFourCs.place,
          fourPsFourCs.promotion,
          fourPsFourCs.customerValue,
          fourPsFourCs.cost,
          fourPsFourCs.convenience,
          fourPsFourCs.communication,
        ])
      "
    >
      <v-capture :visible="noteMode">
        <v-row no-gutters>
          <v-col class="col-6" align="center">
            <p class="text-h2">
              4<span class="red--text text--darken-1">P</span>
            </p>
            <span>企業視点の要素</span>
          </v-col>
          <v-col class="col-6" align="center">
            <p class="text-h2">
              4<span class="blue--text text--darken-1">C</span>
            </p>
            <span>顧客視点の要素</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-6">
            <v-card class="py-3" outlined>
              <v-card-text>
                <v-row>
                  <v-col>
                    <span class="input-area-title mr-1"
                      ><span class="red--text text--darken-1">P</span
                      >roduct</span
                    >製品
                    <v-textarea-with-link
                      v-model="fourPsFourCs.product"
                      class="text-body-2"
                      outlined
                      hide-details
                      :readonly="readonly"
                      reference="FourPsFourCsAnalysis_product.txt"
                      :refDoc="true"
                    ></v-textarea-with-link>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="input-area-title mr-1"
                      ><span class="red--text text--darken-1">P</span>rice</span
                    >価格
                    <v-textarea-with-link
                      v-model="fourPsFourCs.price"
                      class="text-body-2"
                      outlined
                      hide-details
                      :readonly="readonly"
                      reference="FourPsFourCsAnalysis_price.txt"
                      :refDoc="true"
                    ></v-textarea-with-link>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="input-area-title mr-1"
                      ><span class="red--text text--darken-1">P</span>lace</span
                    >流通
                    <v-textarea-with-link
                      v-model="fourPsFourCs.place"
                      class="text-body-2"
                      outlined
                      hide-details
                      :readonly="readonly"
                      reference="FourPsFourCsAnalysis_place.txt"
                      :refDoc="true"
                    ></v-textarea-with-link>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="input-area-title mr-1"
                      ><span class="red--text text--darken-1">P</span
                      >romotion</span
                    >販促
                    <v-textarea-with-link
                      v-model="fourPsFourCs.promotion"
                      class="text-body-2"
                      outlined
                      hide-details
                      :readonly="readonly"
                      reference="FourPsFourCsAnalysis_promotion.txt"
                      :refDoc="true"
                    ></v-textarea-with-link>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="col-6">
            <v-card class="py-3" outlined>
              <v-card-text>
                <v-row>
                  <v-col>
                    <span class="input-area-title mr-1"
                      ><span class="blue--text text--darken-1">C</span>ustomer
                      Value</span
                    >顧客価値
                    <v-textarea-with-link
                      v-model="fourPsFourCs.customerValue"
                      class="text-body-2"
                      outlined
                      hide-details
                      :readonly="readonly"
                      reference="FourPsFourCsAnalysis_customerValue.txt"
                      :refDoc="true"
                    ></v-textarea-with-link>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="input-area-title mr-1"
                      ><span class="blue--text text--darken-1">C</span>ost</span
                    >顧客にとっての経費
                    <v-textarea-with-link
                      v-model="fourPsFourCs.cost"
                      class="text-body-2"
                      outlined
                      hide-details
                      :readonly="readonly"
                      reference="FourPsFourCsAnalysis_cost.txt"
                      :refDoc="true"
                    ></v-textarea-with-link>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="input-area-title mr-1"
                      ><span class="blue--text text--darken-1">C</span
                      >onvenience</span
                    >顧客利便性
                    <v-textarea-with-link
                      v-model="fourPsFourCs.convenience"
                      class="text-body-2"
                      outlined
                      hide-details
                      :readonly="readonly"
                      reference="FourPsFourCsAnalysis_convenience.txt"
                      :refDoc="true"
                    ></v-textarea-with-link>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="input-area-title mr-1"
                      ><span class="blue--text text--darken-1">C</span
                      >ommunication</span
                    >顧客とのコミュニケーション
                    <v-textarea-with-link
                      v-model="fourPsFourCs.communication"
                      class="text-body-2"
                      outlined
                      hide-details
                      :readonly="readonly"
                      reference="FourPsFourCsAnalysis_communication.txt"
                      :refDoc="true"
                    ></v-textarea-with-link>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-capture>
    </div>
    <div
      v-show="isShowRef(refMode, fourPsFourCs.fourPsFourCsSummary)"
      class="sp-common-width-wide mt-12"
    >
      <parts-input-textarea
        v-model="fourPsFourCs.fourPsFourCsSummary"
        :readonly="readonly"
        label="4P-4Cまとめ"
        reference="fourPsFourCsAnalysis_fourPsFourCsSummary.txt"
        :refDoc="true"
        :refMode="refMode"
      ></parts-input-textarea>
    </div>
  </v-container>
  <text-no-data
    v-else
    classVal="sp-common-width-narrow ref-mode px-3 text-center"
  />
</template>
<script>
import VCapture from "../../atoms/VCapture.vue";
import PartsInputTextarea from "@/components/atomic/molecules/PartsInputTextarea.vue";
import VTextareaWithLink from "../../atoms/VTextareaWithLink.vue";
import TextNoData from "../../atoms/TextNoData.vue";

export default {
  props: ["value", "readonly", "refMode", "noteMode"],
  components: {
    VCapture,
    PartsInputTextarea,
    VTextareaWithLink,
    TextNoData,
  },
  data() {
    return {
      fourPsFourCs: {
        product: null,
        price: null,
        place: null,
        promotion: null,
        customerValue: null,
        cost: null,
        convenience: null,
        communication: null,
        fourPsFourCsSummary: null,
      },
    };
  },
  created() {
    if (this.value) {
      this.fourPsFourCs = this.value;
    }
  },
  watch: {
    value(val) {
      this.fourPsFourCs = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.four-ps-four-cs-analysis-wrap {
  min-width: 980px;
  max-width: 980px;
}
.input-area-title {
  font-size: 1.8rem !important;
}
</style>
