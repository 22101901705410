<template>
  <div>
    <image-select-dialog
      v-model="dialog"
      :src="edit_url"
      @change-url="changeUrl($event)"
      caption="イメージを変更"
      :func="3"
    ></image-select-dialog>

    <v-row class="clinic-interior-wrap">
      <v-col v-for="(image, i) in value" :key="i" cols="3">
        <v-hover v-slot="{ hover }">
          <v-card
            class="clinic-interior-img"
          >
            <v-img
              :src="image"
              height="150"
            ></v-img>

            <v-btn
              :class="{ 'clinic-interior-delete-btn--hover' : !readonly && hover }"
              class="clinic-interior-delete-btn"
              x-small
              fab
              color="primary"
              @click="openDialog(i, image)"
              :disabled="readonly"
            >
              <v-icon
                color="white"
              >mdi-pencil</v-icon>
            </v-btn>

          </v-card>
        </v-hover>

      </v-col>
      <v-col cols="3">
          <v-card
            height="150"
          >
            <v-overlay
              absolute="absolute"
            >
              <v-btn text
                color="white"
                @click="openDialog(value.length, null)"
                :disabled="readonly"
              >
                画像を追加
              </v-btn>
            </v-overlay>
          </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import ImageSelectDialog from '../atoms/ImageSelectDialog'

export default {
  components: {
    ImageSelectDialog,
  },

  props: {
    value: {
      type:     Array,
      require:  false,
      default:  () => [],
    },
    readonly: Boolean,
  },

  data(){
    return{
      dialog: false,
      edit_index: -1,
      edit_url: null,
    }
  },

  methods: {
    openDialog(i, url){
      this.edit_index = i
      this.edit_url = url
      this.dialog = true
    },
    changeUrl(newUrl) {
      this.dialog = false

      if(this.value.length == this.edit_index) {
        //追加の場合

        if(newUrl) {
          let images = JSON.parse(JSON.stringify(this.value))
          images.push(newUrl)
          this.$emit('input', images)
        } else {
          //なにもしない
        }
      } else {
        //変更の場合

        let images = JSON.parse(JSON.stringify(this.value))
        if(newUrl) {
          images[this.edit_index] = newUrl
        } else {
          images.splice(this.edit_index, 1)
        }
        this.$emit('input', images)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.clinic-interior-wrap{
  height: 175px;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar{
      height: 8px;
      border-radius: 2.5px;
  }

  &::-webkit-scrollbar-track{
      background: #fff;
      border: none;
      border-radius: 2.5px;
  }

  &::-webkit-scrollbar-thumb{
      background: #ccc;
      border-radius: 10px;
      box-shadow: none;
  }

  .clinic-interior-img{
    position: relative;
  }
  .clinic-interior-delete-btn{
    position: absolute;
    bottom: 5px;
    right: 5px;
    opacity: 0;

    &--hover{
      opacity: 1;
    }
  }
}
</style>