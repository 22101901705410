<template>
  <div v-if="design" class="sp-common-width-wide design-requirements ref-mode">
    <!-- デザインイメージ -->
    <parts-element-label label="デザインイメージ" class="mb-3" />
    <div>
      <design-image
        v-model="design.image"
        :readonly="!hasWritePermission || refMode"
        :refMode="refMode"
      ></design-image>
    </div>

    <!-- デザインパラメータ -->
    <div class="pt-10">
      <parts-element-label
        label="デザインパラメータ"
        :refDoc="true"
        reference="DesignParameter_designParameter.txt"
        class="mb-3"
      />
      <div>
        <design-parameter
          v-model="design.parameter"
          :readonly="!hasWritePermission || refMode"
          :refMode="refMode"
        ></design-parameter>
      </div>
    </div>

    <!-- 参考サイト -->
    <div class="pt-10">
      <parts-element-label
        label="参考サイト"
        :refDoc="true"
        reference="ReferenceSites_referenceSites.txt"
        class="mb-3"
      />
      <div class="mb-6">
        <reference-sites
          v-model="design.referencesite"
          :readonly="!hasWritePermission || refMode"
          :refMode="refMode"
        ></reference-sites>
      </div>
    </div>

    <!-- レギュレーション -->
    <div class="pt-10" v-show="isShowRef(refMode, design.designRegulation)">
      <parts-element-label label="レギュレーション" class="mb-3" />
      <v-checkbox
        label="レギュレーション有無"
        v-model="design.designRegulation"
        :readonly="!hasWritePermission || refMode"
      ></v-checkbox>
      <div v-show="design.designRegulation">
        <div class="text-caption">・レギュレーション内容</div>
        <v-textarea
          v-model="design.regulationDetail"
          :readonly="!hasWritePermission || refMode"
          outlined
          auto-grow
        ></v-textarea>
      </div>
    </div>

    <!-- 重要コンテンツ -->
    <div class="pt-5" v-show="isShowRef(refMode, design.priorityContents)">
      <parts-element-label label="重要コンテンツ" class="mb-3" />
      <v-textarea
        v-model="design.priorityContents"
        :readonly="!hasWritePermission || refMode"
        outlined
        auto-grow
      ></v-textarea>
    </div>

    <!-- 対策キーワード -->
    <div
      class="pt-5"
      v-show="
        isShowRefs(refMode, [
          design.targetKeyword.mainKeyword,
          design.targetKeyword.mainKeywordAssess,
          design.targetKeyword.subKeyword,
          design.targetKeyword.subKeywordAsses,
        ])
      "
    >
      <parts-element-label label="対策キーワード" class="mb-3" />
      <div
        class="mb-3"
        v-show="isShowRef(refMode, design.targetKeyword.mainKeyword)"
      >
        <div class="text-caption">・メインキーワード</div>
        <v-textarea
          v-model="design.targetKeyword.mainKeyword"
          :readonly="!hasWritePermission || refMode"
          outlined
          auto-grow
          hide-details=""
        ></v-textarea>
      </div>
      <div
        class="mb-3"
        v-show="isShowRef(refMode, design.targetKeyword.mainKeywordAssess)"
      >
        <div class="text-caption">・メインキーワード状況</div>
        <v-textarea
          v-model="design.targetKeyword.mainKeywordAssess"
          :readonly="!hasWritePermission || refMode"
          outlined
          auto-grow
          hide-details=""
        ></v-textarea>
      </div>
      <div
        class="mb-3"
        v-show="isShowRef(refMode, design.targetKeyword.subKeyword)"
      >
        <div class="text-caption">・サブキーワード</div>
        <v-textarea
          v-model="design.targetKeyword.subKeyword"
          :readonly="!hasWritePermission || refMode"
          outlined
          auto-grow
          hide-details=""
        ></v-textarea>
      </div>
      <div
        class="mb-3"
        v-show="isShowRef(refMode, design.targetKeyword.subKeywordAssess)"
      >
        <div class="text-caption">・サブキーワード状況</div>
        <v-textarea
          v-model="design.targetKeyword.subKeywordAssess"
          :readonly="!hasWritePermission || refMode"
          outlined
          auto-grow
          hide-details=""
        ></v-textarea>
      </div>
    </div>

    <!-- デザイン要件備考 -->
    <div class="pt-5" v-show="isShowRef(refMode, design.note.designNote)">
      <parts-element-label label="デザイン要件備考" class="mb-3" />
      <div>
        <design-note
          v-model="design.note"
          :readonly="!hasWritePermission"
          :refMode="refMode"
        ></design-note>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import VExpansionPanelHeaderWithLabel from "../../atoms/VExpansionPanelHeaderWithLabel";
import DesignImage from "../../organisms/DesignImage";
import DesignParameter from "../../organisms/DesignParameter";
import ReferenceSites from "../../organisms/ReferenceSites";
import DesignNote from "../../organisms/DesignNote";
import SheetInfo from "../../organisms/SheetInfo";
import PartsElementLabel from "../../atoms/PartsElementLabel.vue";
import VTextareaWithLink from "../../molecules/VBtnWithExternalLink.vue";

export default {
  components: {
    VExpansionPanelHeaderWithLabel,
    DesignImage,
    DesignParameter,
    ReferenceSites,
    DesignNote,
    SheetInfo,
    PartsElementLabel,
    VTextareaWithLink,
  },

  props: ["value", "refMode"],
  data() {
    return {
      //### データ関連 ###
      design: null, // このタブのメインデータ
    };
  },

  created() {
    // データ初期化
    if (this.value) {
      this.design = this.value;
      this.initSectionData(this.value);
    }
  },

  methods: {
    //各セクションデータの初期化
    initSectionData(val) {
      if (val.image && Object.keys(val.image).length > 0) {
        this.image = val.image;
      }
      if (val.referencesite && Object.keys(val.referencesite).length > 0) {
        this.reference = val.referencesite;
      }
      if (val.parameter && Object.keys(val.parameter).length > 0) {
        this.parameter = val.parameter;
      }
      if (val.note && Object.keys(val.note).length > 0) {
        this.note = val.note;
      }
      if (val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfo = val.sheetInfo;
      }
    },
  },

  computed: {
    ...mapGetters(["hasWritePermission", "userInfo"]),
  },

  watch: {
    value(val) {
      //value変更で、各データを初期化
      this.design = val;
      this.initSectionData(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.design-requirements {
  min-width: 900px;
}
</style>
