<template>
  <div class="sp-common-width-narrow ref-mode label-outside">
    <div class="mb-12">
      <parts-element-label :label="'組織'" :readonly="readonly" class="mb-3" />
      <div class="d-flex mb-5">
        <v-row>
          <v-col cols="4" class="pb-0">
            <div class="ml-3 text-subtitle-2">役割</div>
          </v-col>
          <v-col cols="4" class="pb-0">
            <div class="ml-3 text-subtitle-2">正規</div>
          </v-col>
          <v-col cols="4" class="pb-0">
            <div class="ml-3 text-subtitle-2">非正規</div>
          </v-col>
        </v-row>
      </div>
      <div
        class="d-flex my-3"
        v-for="(item, idx) in companyProfile.numInfo"
        :key="idx"
      >
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="item.brandNumInfoName"
              placeholder="項目名"
              :readonly="readonly"
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <custom-currency-field
              v-model="item.regularEmployment"
              suffix="人"
              :readonly="readonly"
              dense
            ></custom-currency-field>
          </v-col>
          <v-col cols="4">
            <custom-currency-field
              v-model="item.nonRegularEmployment"
              suffix="人"
              :readonly="readonly"
              dense
            ></custom-currency-field>
          </v-col>
        </v-row>
        <div class="ml-2" v-show="!refMode && !readonly">
          <v-btn icon @click="deleteItem(idx)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
      </div>
      <v-row>
        <v-col class="text-center">
          <common-add-btn
            v-show="!(readonly || refMode)"
            @click="add()"
            color="btPrimary"
          />
        </v-col>
      </v-row>

      <v-row
        class="mt-10"
        v-show="
          isShowRef(refMode, companyProfile.organizationInfo.adagencyFlag)
        "
      >
        <v-col cols="4">
          <v-checkbox
            label="代理店取引有無"
            v-model="companyProfile.organizationInfo.adagencyFlag"
            :readonly="readonly"
            hide-details
            color="btPrimary"
          ></v-checkbox>
        </v-col>
        <v-col cols="8" class="pb-0">
          <v-text-field
            v-if="companyProfile.organizationInfo.adagencyFlag"
            v-model="companyProfile.organizationInfo.adagencyName"
            placeholder="代理店名"
            outlined
            :readonly="readonly"
            background-color="white"
            hide-details="auto"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>

      <v-row
        v-show="
          isShowRef(refMode, companyProfile.organizationInfo.webdevelopperFlag)
        "
      >
        <v-col cols="4">
          <v-checkbox
            label="制作会社取引有無"
            v-model="companyProfile.organizationInfo.webdevelopperFlag"
            :readonly="readonly"
            hide-details
            color="btPrimary"
          ></v-checkbox>
        </v-col>
        <v-col cols="8" class="pb-0">
          <v-text-field
            v-if="companyProfile.organizationInfo.webdevelopperFlag"
            v-model="companyProfile.organizationInfo.webdevelopperName"
            placeholder="制作会社名"
            outlined
            :readonly="readonly"
            background-color="white"
            hide-details="auto"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>

      <v-row
        v-show="
          isShowRef(refMode, companyProfile.organizationInfo.seoconsultantFlag)
        "
      >
        <v-col cols="4">
          <v-checkbox
            label="SEOコンサル取引有無"
            v-model="companyProfile.organizationInfo.seoconsultantFlag"
            :readonly="readonly"
            hide-details
            color="btPrimary"
          ></v-checkbox>
        </v-col>
        <v-col cols="8" class="pb-0">
          <v-text-field
            v-if="companyProfile.organizationInfo.seoconsultantFlag"
            v-model="companyProfile.organizationInfo.seoconsultantName"
            placeholder="SEOコンサル会社名"
            outlined
            :readonly="readonly"
            background-color="white"
            hide-details="auto"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>

      <div
        class="mt-16"
        v-show="isShowRef(refMode, companyProfile.organizationInfo.orgFeatures)"
      >
        <parts-input-textarea
          v-model="companyProfile.organizationInfo.orgFeatures"
          :readonly="readonly"
          label="組織特長"
          :refMode="refMode"
        />
      </div>

      <div
        class="mt-10"
        v-show="isShowRef(refMode, companyProfile.organizationInfo.orgIssues)"
      >
        <parts-input-textarea
          v-model="companyProfile.organizationInfo.orgIssues"
          :readonly="readonly"
          label="組織課題"
          :refMode="refMode"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CustomCurrencyField from "@/components/atomic/atoms/CustomCurrencyField";

import PartsInputTextField from "@/components/atomic/molecules/PartsInputTextField";
import PartsElementLabel from "@/components/atomic/atoms/PartsElementLabel.vue";
import PartsInputTextarea from "@/components/atomic/molecules/PartsInputTextarea";
import CommonAddBtn from "../../atoms/CommonAddBtn.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CustomCurrencyField,
    PartsInputTextField,
    PartsElementLabel,
    PartsInputTextarea,
    CommonAddBtn,
  },
  props: ["value", "readonly", "refMode"],
  data() {
    return {
      companyProfile: {
        location1: null,
        locationProperty1: null,
        locationProperty2: null,
        locationProperty3: null,
        locationProperty4: null,
        locationReason: null,
        annualSales: null,
        monthlySales: null,
        monthlyCustomer: null,
        dailyCustomer: null,
        monthlyNewCustomer: null,
        cancellationCustomer: null,
        selfFinancedRate: null,
        recallRate: null,
        occupancyRate: null,
        cancellationRate: null,
        numInfo: [],
        organizationInfo: null,
      },

      rules: {
        required: (value) => !!value || "必須です",
      },
    };
  },
  computed: {
    ...mapGetters(["project"]),

    isDentistry() {
      return this.project.industry == "歯科";
    },
    isRealEstate() {
      return this.project.industry == "不動産";
    },
    isBuilding() {
      return this.project.industry == "建築";
    },
  },
  created() {
    if (this.value) {
      this.companyProfile = this.value;
    }
    this.initNumInfo();
  },
  methods: {
    initNumInfo() {
      // 業界出し分け ＆ 初期値設定
      let labels = [];

      if (this.isDentistry) {
        labels = [
          "ドクター",
          "歯科衛生士",
          "歯科助手",
          "歯科技工士",
          "ユニット数",
        ];
      } else if (this.isRealEstate) {
        labels = ["営業", "管理", "仕入れ"];
      } else if (this.isBuilding) {
        labels = [
          "営業",
          "設計士",
          "施行管理",
          "大工",
          "インテリアコーディネータ",
          "フィナンシャルプランナー",
          "広報",
        ];
      } else {
        labels = [
          "マーケティング",
          "インサイドセールス",
          "フィールドセールス",
          "カスタマーサクセス",
        ];
      }

      if (!this.companyProfile.numInfo.length) {
        labels.forEach((label) => {
          this.companyProfile.numInfo.push({
            brandNumInfoName: label,
            regularEmployment: 0,
            nonRegularEmployment: 0,
          });
        });
      } else {
        this.companyProfile.numInfo.forEach((item, idx) => {
          if (!item.brandNumInfoName && idx < labels.length) {
            item.brandNumInfoName = labels[idx];
          }
        });
      }
    },

    add() {
      this.companyProfile.numInfo.push({
        brandNumInfoName: "",
        regularEmployment: 0,
        nonRegularEmployment: 0,
      });
    },

    deleteItem(target) {
      this.companyProfile.numInfo.splice(target, 1);
    },
  },
  watch: {
    value(to) {
      if (to) {
        this.companyProfile = to;
      }
      this.initNumInfo();
    },
    "companyProfile.inputStatusClient"(val) {
      this.$store.commit("brand_input_status_profile", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.text-field-attribute {
  min-width: 400px;
}
</style>
<style lang="scss">
.performance-textfield-area {
  &__edit-mode {
    background-color: #f3faff !important;
  }
  &__ref-mode {
    background-color: white !important;
  }
  .v-input__slot {
    background-color: white !important;
  }
  .performance-textfield {
    min-width: 170px;
    max-width: 170px;
  }
  .organization-itemcard {
    min-width: 300px;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
