/**
 * プランニング(update)API Request class.
 *
 */

import IssueRequestItem from "./items/IssueRequestItem";
import ObjectiveRequestItem from "./items/ObjectiveRequestItem";
import PlanRequestItem from "./items/PlanRequestItem";
import ReferenceRequestItem from "./items/ReferenceRequestItem";
import SheetInfoRequestItem from "./items/SheetInfoRequestItem";

export default class PlanningInfoRequest {

  // 課題情報
  issue = new IssueRequestItem();
  // 目標情報
  objective = new ObjectiveRequestItem();
  // リファレンス情報
  reference = new ReferenceRequestItem();
  // プラン情報
  plan = new PlanRequestItem();
  // 作成者情報
  sheetInfo = new SheetInfoRequestItem();

}