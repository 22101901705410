<template>
  <div
    class="
      sp-common-width-narrow
      pt-0
      current-strategy-and-action-wrap
      ref-mode
      label-outside
    "
  >
    <div
      v-if="
        isShowRefs(refMode, [
          current.strategicPolicy,
          current.measuresWeb,
          current.measuresOnlineAttract,
          current.measuresReal,
          current.measuresOfflineAttract,
          current.measuresEvaluation,
        ])
      "
    >
      <div class="mb-12" v-show="isShowRef(refMode, current.strategicPolicy)">
        <parts-input-textarea
          v-model="current.strategicPolicy"
          label="戦略方針"
          :readonly="readonly"
          reference="CurrentStrategyAndAction_strategicPolicy.txt"
          :refDoc="true"
          :refMode="refMode"
        ></parts-input-textarea>
      </div>

      <div
        class="my-12"
        v-show="
          isShowRefs(refMode, [
            current.measuresWeb,
            current.measuresOnlineAttract,
            current.measuresReal,
            current.measuresOfflineAttract,
          ])
        "
      >
        <parts-element-label
          :label="'現在実施している施策'"
          :description="''"
          :readonly="readonly"
          class="mb-3"
        />
        <v-card
          class="measures-textfiled-area pa-0"
          :class="
            refMode
              ? 'measures-textfiled-area__ref-mode'
              : 'measures-textfiled-area__edit-mode'
          "
        >
          <v-card-text>
            <v-row
              class="ma-0 pa-0"
              v-show="
                isShowRefs(refMode, [
                  current.measuresWeb,
                  current.measuresOnlineAttract,
                ])
              "
            >
              <v-col cols="11" class="pa-0">
                <v-card-title class="pl-0 py-0 title-online"
                  >オンライン</v-card-title
                >
              </v-col>
              <v-col cols="1" align="right" class="ma-0 pa-0">
                <input-sample
                  v-show="!readonly"
                  class="input-sample"
                  :refDoc="true"
                  reference="CurrentStrategyAndAction_measuresOnlineAttract.txt"
                  label="オンライン"
                ></input-sample>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 mb-3 mx-auto justify-center">
              <v-col
                class="responsive-item"
                v-show="isShowRef(refMode, current.measuresWeb)"
              >
                <span class="text-caption py-1 black--text"
                  >・オンライン施策・タッチポイントの精査</span
                >
                <v-combobox
                  v-model="current.measuresWeb"
                  :items="onlineMeasures"
                  item-text="onlineMeasures"
                  item-value="onlineMeasures"
                  outlined
                  multiple
                  chips
                  deletable-chips
                  hide-details
                  :return-object="false"
                  :readonly="readonly"
                  class="online-combobox"
                ></v-combobox>
              </v-col>
              <v-col
                class="responsive-item"
                v-show="isShowRef(refMode, current.measuresOnlineAttract)"
              >
                <span class="text-caption py-1 black--text"
                  >・オンライン施策詳細</span
                >
                <v-textarea
                  v-model="current.measuresOnlineAttract"
                  outlined
                  auto-grow
                  hide-details
                  rows="3"
                  class="reservation-site"
                  :readonly="readonly"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-divider
              class="py-2"
              v-show="
                isShowRefs(refMode, [
                  current.measuresWeb,
                  current.measuresOnlineAttract,
                ]) &&
                isShowRefs(refMode, [
                  current.measuresReal,
                  current.measuresOfflineAttract,
                ])
              "
            ></v-divider>

            <v-row
              class="ma-0 pa-0"
              v-show="
                isShowRefs(refMode, [
                  current.measuresReal,
                  current.measuresOfflineAttract,
                ])
              "
            >
              <v-col cols="11" class="pa-0">
                <v-card-title class="pl-0 py-0 title-ofline"
                  >オフライン</v-card-title
                >
              </v-col>
              <v-col align="right" class="ma-0 pa-0">
                <input-sample
                  v-show="!readonly"
                  class="input-sample"
                  :refDoc="true"
                  reference="CurrentStrategyAndAction_measuresOfflineAttract.txt"
                  label="オフライン"
                ></input-sample>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 justify-center">
              <v-col
                class="responsive-item"
                v-show="isShowRef(refMode, current.measuresReal)"
              >
                <span class="text-caption py-1 black--text"
                  >・オフライン施策・タッチポイントの精査</span
                >
                <v-combobox
                  v-model="current.measuresReal"
                  :items="offlineMeasures"
                  item-text="offlineMeasures"
                  item-value="offlineMeasures"
                  :return-object="false"
                  outlined
                  multiple
                  chips
                  deletable-chips
                  hide-details
                  :readonly="readonly"
                  class="ofline-combobox"
                ></v-combobox>
              </v-col>
              <v-col
                class="responsive-item"
                v-show="isShowRef(refMode, current.measuresOfflineAttract)"
              >
                <span class="text-caption py-1 black--text"
                  >・オフライン施策詳細</span
                >
                <v-textarea
                  v-model="current.measuresOfflineAttract"
                  outlined
                  auto-grow
                  hide-details
                  rows="3"
                  class="reservation-site"
                  :readonly="readonly"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <div
        class="mt-12"
        v-show="isShowRef(refMode, current.measuresEvaluation)"
      >
        <parts-element-label
          :label="'戦略と施策評価'"
          :description="''"
          :readonly="readonly"
          class="mb-3"
        />
        <v-select
          v-model="current.measuresEvaluation"
          :items="MasterData.strategicEvaluation"
          item-text="strategicEvaluation"
          item-value="strategicEvaluation"
          :return-object="false"
          outlined
          :readonly="readonly"
          background-color="white"
        ></v-select>
      </div>
    </div>
    <text-no-data v-else classVal="text-center" />
  </div>
</template>
<script>
import PartsElementLabel from "../../atoms/PartsElementLabel.vue";
import PartsInputTextarea from "../../molecules/PartsInputTextarea.vue";
import InputSample from "../../atoms/InputSample.vue";
import TextNoData from "../../atoms/TextNoData.vue";

import { mapGetters } from "vuex";
import MasterData from "../../../../utils/MasterData";

export default {
  components: {
    PartsElementLabel,
    PartsInputTextarea,
    InputSample,
    TextNoData,
  },
  props: ["value", "readonly", "refMode"],
  data() {
    return {
      editing: null,
      current: {
        strategicPolicy: null,
        measuresOnlineAttract: null,
        measuresOfflineAttract: null,
        measuresWeb: null,
        measuresReal: null,
        measuresEvaluation: null,
      },
      MasterData,
    };
  },
  computed: {
    ...mapGetters(["project"]),

    isDentistry() {
      return this.project.industry == "歯科";
    },
    isRealEstate() {
      return this.project.industry == "不動産";
    },
    isBuilding() {
      return this.project.industry == "建築";
    },

    onlineMeasures() {
      let rtn;
      if (this.isDentistry) {
        rtn = this.MasterData.onlineMeasuresDentistry;
      } else if (this.isRealEstate) {
        rtn = this.MasterData.onlineMeasuresRealEstate;
      } else if (this.isBuilding) {
        rtn = this.MasterData.onlineMeasuresBuilding;
      } else {
        rtn = this.MasterData.onlineMeasuresOther;
      }

      return rtn;
    },

    offlineMeasures() {
      let rtn;
      if (this.isDentistry) {
        rtn = this.MasterData.offlineMeasuresDentistry;
      } else if (this.isRealEstate) {
        rtn = this.MasterData.offlineMeasuresRealEstate;
      } else if (this.isBuilding) {
        rtn = this.MasterData.offlineMeasuresBuilding;
      } else {
        rtn = this.MasterData.offlineMeasuresOther;
      }

      return rtn;
    },
  },
  created() {
    if (this.value) {
      this.current = this.value;
    }
  },
  methods: {
    //現状の戦略と実施施策セクションの未入力チェック
    isEmpty() {
      if (!this.current) return true;

      const current = this.current;
      let ret = false;

      //各質問項目をチェック
      const questionKeys = [
        "strategicPolicy",
        "measuresOnlineAttract",
        "measuresOfflineAttract",
        "measuresEvaluation",
      ];
      ret = questionKeys.some((i) => current[i]);
      if (ret) return false;

      //オンライン施策・タッチポイント
      if (current.measuresWeb && current.measuresWeb.length > 0) {
        return false;
      }
      //オフライン施策・タッチポイント
      if (current.measuresReal && current.measuresReal.length > 0) {
        return false;
      }

      return true;
    },
  },
  watch: {
    value(val) {
      this.current = val;
    },
    "current.inputStatusCurrent"(val) {
      this.$store.commit("brand_input_status_current", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.reservation-site {
  max-width: 700px;
}
.title-online {
  color: #4caf50;
}
.title-ofline {
  color: #ff9800;
}
</style>
<style lang="scss">
.current-strategy-and-action-wrap {
  .responsive-item {
    min-width: 456px;
    max-width: 620px;
    width: 100%;
  }
  .measures-textfiled-area {
    &__edit-mode {
      background-color: #f3faff !important;
    }
    &__ref-mode {
      background-color: white !important;
    }
    .v-input__slot {
      background-color: white !important;
    }

    .online-combobox {
      .v-input__slot {
        .v-chip:not(.v-chip--active) {
          background: #c8e6c9;
        }
      }
    }
    .ofline-combobox {
      .v-input__slot {
        .v-chip:not(.v-chip--active) {
          background: #ffe0b2;
        }
      }
    }
  }

  .input-sample {
    margin-top: -3px;
  }
}
</style>
