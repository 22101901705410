<template>
  <div class="pt-5 competitors-wrap">
    <v-row>
      <v-col v-for="(item, i) in competitors.addItems" :key="item.id" cols="12" md="6">
        <!-- <v-divider></v-divider> -->
        <v-row class="ma-0 pa-0">
          <v-col align="right" class="ma-0 pa-0 pb-2">
            <input-sample class="input-sample" :refDoc="true" reference="Competitors_competitors.txt" label="競合名・特長"></input-sample>
          </v-col>
        </v-row>
        <v-row class="align-center ma-0 pa-0">
          <v-col cols="10" md="8" class="ma-0 pa-0">
            <v-text-field
              v-model="item.targetName"
              class="competitor-name"
              label="競合名"
              :clearable="!readonly"
              :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="4" class="text-right ma-0 pa-0">
            <v-btn
              v-show="!readonly"
              depressed
              small
              color="primary"
              @click="deleteCompetitor(i)"
            >
              <v-icon left dark dense>mdi-trash-can</v-icon>
              削除
            </v-btn>
          </v-col>
        </v-row>
        <v-text-field
          v-model="item.targetUrl"
          class="competitor-url"
          label="URL"
          :hint="item.targetUrl"
          persistent-hint
          outlined
          :clearable="!readonly"
          :readonly="readonly"
        >
          <template v-slot:message="{ message }">
            <a :href="message" target="_blank">{{message}}</a>
          </template>
        </v-text-field>
        <v-textarea
          v-model="item.targetFeatures"
          label="特長"
          outlined
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
        ></v-textarea>
      </v-col>
      <v-col class="d-flex justify-center align-center my-16" cols="12" md="6">
        <v-btn
          v-show="!readonly"
          fab
          outlined
          color="primary"
          depressed
          @click="addCompetitor"
          width="45"
          height="45"
        >
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>
<script>
import InputSample from '../../atoms/InputSample.vue';

export default {
  components: {
    InputSample,
  },
  props: ['value', 'readonly'],
  data(){
    return{
      competitors: {
        addItems:[
          {
            targetName: null,
            targetUrl: null,
            targetFeatures: null
          },
        ],
      },
    }
  },
  created(){
    if(this.value) {
      this.competitors = this.value
    }
  },
  methods: {
    //競合分析セクションの未入力チェック
    isEmpty() {
      if(!this.competitors) return true

      const competitors = this.competitors
      let ret = false

      //追加質問項目をチェック
      if(competitors.addItems && competitors.addItems.length > 0) {
        ret = competitors.addItems.some(i => i.targetName || i.targetUrl || i.targetFeatures)
        if(ret) return false
      }

      return true
    },
    addCompetitor: function(){
      this.competitors.addItems.push({ targetName: null, targetUrl: null, features: null })
    },
    deleteCompetitor: function(i){
      this.competitors.addItems.splice(i, 1)
    }
  },
  watch: {
    value(val) {
      this.competitors = val
    },
    "competitors.inputStatusCompetitive"(val) {
      this.$store.commit('brand_input_status_competitors', val)
    },
  }
}
</script>
<style lang="scss" scoped>
.competitors-wrap {
  .competitor-name{
    max-width: 700px;
  }
  .competitor-url{
    max-width: 700px;
  }
  .input-sample{
    margin-top: -3px;
  }
}
</style>