<template>
  <div class="sp-common-width-wide persona-info-wrap px-0 ref-mode">
    <parts-element-label
      label="ペルソナ"
      description="ターゲットユーザの属性情報を定義してユーザ像の解像度を高めます。"
      class="mb-5"
    ></parts-element-label>
    <v-tabs v-model="tabs" vertical @change="tabChange($event)">
      <v-tab
        v-for="(persona, i) in persona.data"
        :key="persona.id"
        class="justify-start tab"
      >
        <v-avatar size="36px" class="mr-3">
          <img v-if="persona.imageUrl" :src="persona.imageUrl" />
          <v-icon v-else x-large>mdi-account-circle</v-icon>
        </v-avatar>
        <div class="persona-name">{{ persona.personaName }}</div>

        <v-btn
          v-show="!readonly"
          x-small
          color="gray"
          plain
          fab
          @click="showDialog(i)"
        >
          <v-icon color="gray">mdi-trash-can</v-icon>
        </v-btn>
      </v-tab>

      <!-- dialog -->
      <v-dialog v-model="dialog" persistent max-width="360px">
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>本当にペルソナを削除してもよろしいですか？</v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="pa-3 mr-3 text-body-1"
              width="126"
              color="primary"
              large
              depressed
              @click="
                dialog = false;
                deletePersona();
              "
            >
              削除
            </v-btn>
            <v-btn
              class="pa-3 text-body-1"
              width="126"
              color="grey lighten-2"
              large
              depressed
              @click="dialog = false"
            >
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- dialog -->

      <div class="justify-start mt-3">
        <v-btn
          v-show="!readonly"
          depressed
          color="primary"
          @click="addPersona()"
        >
          ペルソナ追加
          <v-icon small color="white" class="ml-1">mdi-plus-thick</v-icon>
        </v-btn>
      </div>

      <v-tab-item
        v-for="persona in persona.data"
        :key="persona.id"
        class="tab-item-border"
        transition="slide-y-transition"
      >
        <v-card flat>
          <v-capture :visible="noteMode" class="py-1 px-1">
            <div :class="noteMode ? 'persona-capture-area' : ''">
              <!-- BtoC/BtoB切替のラジオボタン -->
              <div class="persona-radio-group">
                <v-radio-group
                  v-model="persona.category"
                  class="mt-1 mb-4 pa-0"
                  :disabled="readonly"
                  hide-details
                  row
                >
                  <v-radio
                    label="BtoC"
                    value="BtoC"
                    class="px-2"
                    color="btPrimary"
                    @click="isBtoC = true"
                  ></v-radio>
                  <v-radio
                    label="BtoB"
                    value="BtoB"
                    class="px-2"
                    color="btPrimary"
                    @click="isBtoC = false"
                  ></v-radio>
                </v-radio-group>
              </div>
              <!-- BtoC プロフィール部 -->
              <v-card-text v-if="isBtoC" class="pa-0">
                <input-sample
                  v-if="!refMode"
                  class="input-sample"
                  :refDoc="true"
                  reference="PersonaInfo_personaToC.txt"
                  label="ペルソナ"
                ></input-sample>
                <v-row class="mt-0 mb-7 mx-10">
                  <v-col sm="4" md="3">
                    <persona-add
                      v-model="persona.personaName"
                      @change-url="persona.imageUrl = $event"
                      :personaImg="persona.imageUrl"
                      :readonly="readonly"
                    ></persona-add>
                  </v-col>
                  <v-col sm="8" md="9">
                    <v-row>
                      <v-col sm="12" md="6" class="py-0 mb-1">
                        <span class="text-caption py-1 black--text">・性別</span>
                        <v-select
                          v-model="persona.gender"
                          :items="genderOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                        <span class="text-caption py-1 black--text"
                          >・年齢層</span
                        >
                        <v-select
                          v-model="persona.age"
                          :items="MasterData.ageOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                        <span class="text-caption py-1 black--text"
                          >・家族構成</span
                        >
                        <v-text-field
                          v-model="persona.familyStructure"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-text-field>
                        <span class="text-caption py-1 black--text">・職業</span>
                        <v-select
                          v-model="persona.job"
                          :items="MasterData.jobOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                      </v-col>
                      <v-col sm="12" md="6" class="py-0 mb-1">
                        <span class="text-caption py-1 black--text">・年収</span>
                        <v-select
                          v-model="persona.incomeYear"
                          :items="MasterData.incomeYearOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                        <span class="text-caption py-1 black--text"
                          >・居住エリア</span
                        >
                        <v-text-field
                          v-model="persona.residenceArea"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-text-field>
                        <span class="text-caption py-1 black--text"
                          >・趣味・趣向</span
                        >
                        <v-text-field
                          v-model="persona.hobbies"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-text-field>
                        <span class="text-caption py-1 black--text"
                          >・価値観</span
                        >
                        <v-text-field
                          v-model="persona.senseOfValue"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" md="6" class="py-0 mb-1">
                    <span class="text-caption py-1 black--text">・交通手段</span>
                    <v-select
                      v-model="persona.transportationType"
                      :items="MasterData.transportationTypeOption"
                      outlined
                      multiple
                      small-chips
                      deletable-chips
                      hide-details
                      :readonly="readonly"
                    ></v-select>
                  </v-col>
                  <v-col sm="12" md="6" class="py-0 mb-1">
                    <span class="text-caption py-1 black--text"
                      >・情報収集手段</span
                    >
                    <v-combobox
                      v-model="persona.collectInfo"
                      :items="MasterData.collectInfoOption"
                      outlined
                      multiple
                      small-chips
                      deletable-chips
                      hide-details
                      :readonly="readonly"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" class="py-0 mb-1">
                    <span class="text-caption py-1 black--text"
                      >・よく使うSNS</span
                    >
                    <v-combobox
                      v-model="persona.favoriteSns"
                      :items="MasterData.favoriteSnsOption"
                      outlined
                      multiple
                      small-chips
                      deletable-chips
                      hide-details
                      :readonly="readonly"
                    ></v-combobox>
                  </v-col>
                  <v-col sm="12" md="6" class="py-0 mb-7">
                    <span class="text-caption py-1 black--text">・特長</span>
                    <v-textarea
                      v-model="persona.features"
                      outlined
                      hide-details
                      auto-grow
                      :readonly="readonly"
                    ></v-textarea>
                  </v-col>
                  <v-col sm="12" md="6" class="py-0 mb-7">
                    <span class="text-caption py-1 black--text">・ニーズ</span>
                    <v-textarea
                      v-model="persona.needs"
                      outlined
                      hide-details
                      auto-grow
                      :readonly="readonly"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- BtoB プロフィール部 -->
              <v-card-text v-else class="pa-0">
                <input-sample
                  v-if="!refMode"
                  class="input-sample"
                  :refDoc="true"
                  reference="PersonaInfo_personaToB.txt"
                  label="ペルソナ"
                ></input-sample>
                <v-row class="mt-0 mb-7 mx-10">
                  <v-col sm="4" md="3">
                    <persona-add
                      v-model="persona.personaName"
                      @change-url="persona.imageUrl = $event"
                      :personaImg="persona.imageUrl"
                      :readonly="readonly"
                    ></persona-add>
                  </v-col>
                  <v-col sm="8" md="9">
                    <v-row>
                      <v-col sm="12" md="6" class="py-0 mb-1">
                        <span class="text-caption py-1 black--text">・性別</span>
                        <v-select
                          v-model="persona.gender"
                          :items="genderOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                        <span class="text-caption py-1 black--text"
                          >・年齢層</span
                        >
                        <v-select
                          v-model="persona.age"
                          :items="MasterData.ageOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                        <span class="text-caption py-1 black--text">・業界</span>
                        <v-text-field
                          v-model="persona.industry"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-text-field>
                        <span class="text-caption py-1 black--text"
                          >・拠点数</span
                        >
                        <v-select
                          v-model="persona.officeNumRange"
                          :items="MasterData.officeNumRangeOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                      </v-col>
                      <v-col sm="12" md="6" class="py-0 mb-1">
                        <span class="text-caption py-1 black--text"
                          >・従業員数</span
                        >
                        <v-select
                          v-model="persona.employeeNumRange"
                          :items="MasterData.employeeNumRangeOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                        <span class="text-caption py-1 black--text"
                          >・売上高</span
                        >
                        <v-select
                          v-model="persona.amountRange"
                          :items="MasterData.amountRangeOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                        <span class="text-caption py-1 black--text"
                          >・所属部署</span
                        >
                        <v-select
                          v-model="persona.department"
                          :items="MasterData.departmentOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                        <span class="text-caption py-1 black--text">・役職</span>
                        <v-select
                          v-model="persona.jobPosition"
                          :items="MasterData.jobPositionOption"
                          outlined
                          hide-details
                          :readonly="readonly"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" md="6" class="py-0 mb-1">
                    <span class="text-caption py-1 black--text">・交通手段</span>
                    <v-select
                      v-model="persona.transportationType"
                      :items="MasterData.transportationTypeOption"
                      outlined
                      multiple
                      small-chips
                      deletable-chips
                      hide-details
                      :readonly="readonly"
                    ></v-select>
                  </v-col>
                  <v-col sm="12" md="6" class="py-0 mb-1">
                    <span class="text-caption py-1 black--text"
                      >・情報収集手段</span
                    >
                    <v-combobox
                      v-model="persona.collectInfo"
                      :items="MasterData.collectInfoOption"
                      outlined
                      multiple
                      small-chips
                      deletable-chips
                      hide-details
                      :readonly="readonly"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" class="py-0 mb-1">
                    <span class="text-caption py-1 black--text"
                      >・よく使うSNS</span
                    >
                    <v-combobox
                      v-model="persona.favoriteSns"
                      :items="MasterData.favoriteSnsOption"
                      outlined
                      multiple
                      small-chips
                      deletable-chips
                      hide-details
                      :readonly="readonly"
                    ></v-combobox>
                  </v-col>
                  <v-col sm="12" md="6" class="py-0 mb-1">
                    <span class="text-caption py-1 black--text">・企業課題</span>
                    <v-textarea
                      v-model="persona.companyIssue"
                      outlined
                      hide-details
                      auto-grow
                      :readonly="readonly"
                    ></v-textarea>
                  </v-col>
                  <v-col sm="12" md="6" class="py-0 mb-1">
                    <span class="text-caption py-1 black--text"
                      >・担当者課題</span
                    >
                    <v-textarea
                      v-model="persona.picIssue"
                      outlined
                      hide-details
                      auto-grow
                      :readonly="readonly"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0 mb-1">
                    <span class="text-caption py-1 black--text"
                      >・企業ニーズ</span
                    >
                    <v-textarea
                      v-model="persona.companyNeeds"
                      outlined
                      hide-details
                      auto-grow
                      :readonly="readonly"
                    ></v-textarea>
                  </v-col>
                  <v-col sm="12" md="6" class="py-0 mb-1">
                    <span class="text-caption py-1 black--text"
                      >・担当者ニーズ</span
                    >
                    <v-textarea
                      v-model="persona.needs"
                      outlined
                      hide-details
                      auto-grow
                      :readonly="readonly"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" class="py-0 mb-7">
                    <span class="text-caption py-1 black--text">・担当者の特徴</span>
                    <v-textarea
                      v-model="persona.features"
                      outlined
                      hide-details
                      auto-grow
                      :readonly="readonly"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-capture>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-divider class="mb-4"></v-divider>

    <parts-element-label
      label="カスタマージャーニーマップ"
      description="ペルソナユーザの行動特性から購買行動プロセスを整理します。ユーザ行動に合わせてどういうタッチポイントでどういうコンテンツ訴求をするのかを整理して、全体的なマーケティング施策の青写真を描きます。"
      class="mb-5"
    ></parts-element-label>
    <customer-journey-map
      v-if="persona.data.length > 0"
      v-model="customerJourneyData"
      :sectionReadonly="readonly"
      :noteMode="noteMode"
      :refMode="refMode"
    ></customer-journey-map>
  </div>
</template>
<script>
import PersonaAdd from "../../molecules/PersonaAdd.vue";
import CustomerJourneyMap from "../../organisms/CustomerJourneyMap";
import InputSample from "../../atoms/InputSample.vue";
import VCapture from "../../atoms/VCapture.vue";
import PartsElementLabel from "../../atoms/PartsElementLabel.vue";

import MasterData from "../../../../utils/MasterData";

export default {
  components: {
    PersonaAdd,
    CustomerJourneyMap,
    InputSample,
    VCapture,
    PartsElementLabel,
  },
  props: ["value", "readonly", "refMode", "noteMode"],
  data() {
    return {
      tabs: 0,
      currentTab: 0,

      persona: {
        data: [
          {
            category: "BtoC",
            // toC,toB共通項目
            personaName: "",
            imageUrl: "",
            gender: "",
            age: "",
            collectInfo: "",
            features: "",
            needs: "",
            favoriteSns: "",
            // toC専用項目
            familyStructure: "",
            job: "",
            incomeYear: "",
            residenceArea: "",
            transportationType: "",
            hobbies: "",
            senseOfValue: "",
            // toB専用項目
            industry: "",
            officeNumRange: "",
            employeeNumRange: "",
            amountRange: "",
            department: "",
            jobPosition: "",
            companyIssue: "",
            picIssue: "",
            companyNeeds: "",
            // カスタマージャーニーマップ
            customerJourneyData: null,
          },
        ],
      },
      customerJourneyData: null,
      genderOption: ["男性", "女性"],
      tab: null,
      dialog: false,
      delete_data: null,
      isBtoC: true,

      MasterData,
    };
  },
  computed: {},
  created() {
    if (this.value) {
      this.persona = this.value;
      
      // ペルソナが1つもなければ追加する
      if(this.persona.data.length == 0) this.addPersona();

      this.initCategory();
      this.isBtoC = this.persona.data[0].category == "BtoC";
      this.customerJourneyData = this.persona.data[0].customerJourneyData;

    }
  },
  watch: {
    value(val) {
      this.persona = val;
      this.initCategory();
      this.isBtoC = this.persona.data[0].category == "BtoC";
    },
    customerJourneyData(val) {
      if (
        JSON.stringify(
          this.persona.data[this.currentTab].customerJourneyData
        ) != JSON.stringify(val)
      ) {
        this.persona.data[this.currentTab].customerJourneyData = val;
      }
    },
  },
  methods: {
    initCategory() {
      // categoryがfalsyな値であれば"BtoC"に洗い替える（Ph1時代のデータ＝nullを想定）
      this.persona.data.forEach((e) => {
        if (!e?.category) {
          e.category = "BtoC";
        }
      });
    },
    deletePersona() {
      this.persona.data.splice(this.delete_data, 1);
    },
    addPersona() {
      this.persona.data.push({
        category: "BtoC",
        personaName: "persona",
        imageUrl: "",
        gender: "",
        age: "",
        familyStructure: "",
        job: "",
        incomeYear: "",
        residenceArea: "",
        transportationType: "",
        hobbies: "",
        senseOfValue: "",
        collectInfo: "",
        features: "",
        needs: "",
        customerJourneyData: null,
      });

      this.tabs = this.persona.data.length - 1;
      this.tabChange(this.persona.data.length - 1);
    },
    showDialog(i) {
      this.dialog = true;
      this.delete_data = i;
    },
    tabChange(i) {
      if (typeof i !== "undefined") {
        this.currentTab = i;
        this.isBtoC = this.persona.data[i].category == "BtoC";
        this.customerJourneyData = this.persona.data[i].customerJourneyData;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.persona-info-wrap {
  min-width: 1040px;
  max-width: 1040px;
  padding: 15px;
}
.input-sample {
  float: right;
  margin-top: -3px;
}
</style>
<style>
.tab {
  max-width: 200px !important;
  min-width: 200px !important;
}
.persona-name {
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  text-transform: none;
}
.tab-item-border {
  border-left: solid 3px #eceff1;
}
.v-avatar img {
  object-fit: cover;
}
.persona-capture-area *{
  /* html2canvasの
    'Error parsing CSS component value, multiple values found when expecting only one' 
    の対応。transitionの設定が読み取れずに落ちるので対象エリアはoffにしておく
  */
  transition: none !important;
}
@media screen and (max-width: 1024px) {
  .tab {
    width: 200px !important;
  }
}
/* セレクトボックスの選択肢の色をreadonly時に変化させない対応 */
.theme--light .v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87);
}
/* BtoC/BtoB切替のラジオボタン */
.persona-radio-group .v-input--radio-group--row .v-input--radio-group__input {
  justify-content: center !important;
}
.persona-radio-group .v-input .v-label {
  letter-spacing: 0.125em !important;
  font-weight: 500 !important;
}
</style>