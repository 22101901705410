/**
 * BPサービス情報 Request Item Class.
 *
 */

export default class ServiceRequestItem {

  // サービス概要
  serviceOverview = new ServiceOverview();

  // サービス内容データセクション
  serviceInfo = [];

  // サービス内容の入力ステータス
  inputStatusService = null;

}

class ServiceOverview {
  businessCategory = null
  location1 = null
  locationProperty1 = null
  locationProperty2 = null
  locationProperty3 = null
  locationProperty4 = null
  locationReason = null
  brokerageMediationTrend = null
  fcMembership = null
  fcMembershipName = null
  serviceTag = null
}