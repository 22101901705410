import PhilosophyRequestItem from "../api/requests/items/PhilosophyRequestItem";
import ProfileRequestItem from "../api/requests/items/ProfileRequestItem";
import VisionRequestItem from "../api/requests/items/VisionRequestItem";
import CurrentRequestItem from "../api/requests/items/CurrentRequestItem";
import ServiceRequestItem from "../api/requests/items/ServiceRequestItem";
import CompetitorsRequestItem from "../api/requests/items/CompetitorsRequestItem";
import SwotRequestItem from "../api/requests/items/SwotRequestItem";
import ThreeCsRequestItem from "../api/requests/items/ThreeCsRequestItem";
import StpRequestItem from "../api/requests/items/StpRequestItem";
import FiveForcesRequestItem from "../api/requests/items/FiveForcesRequestItem";
import PestRequestItem from "../api/requests/items/PestRequestItem";
import FourPsFourCsRequestItem from "../api/requests/items/FourPsFourCsRequestItem";
import FourPsComparisonRequestItem from "../api/requests/items/FourPsComparisonRequestItem";
import BusinessModelCanvasRequestItem from "../api/requests/items/BusinessModelCanvasRequestItem";
import TargetRequestItem from "../api/requests/items/TargetRequestItem";
import PersonaRequestItem from "../api/requests/items/PersonaRequestItem";
import DesignRequirementsItem from "../api/requests/items/DesignRequiredRequestItem";
import TargetValuesRequestItem from "../api/requests/items/TargetValuesRequestItem";
import PlanningInfoRequest from "../api/requests/PlanningInfoRequest";

export default {
  // BP 経営理念 (医療、医院)
  philosophy: new PhilosophyRequestItem(),

  // BP 組織情報
  organization: new ProfileRequestItem(),

  // BP ビジョン (医療、医院)
  mvv: new VisionRequestItem(),

  // BP 現状の戦略と実施施策 (医療、医院)
  strategy: new CurrentRequestItem(),

  // BP サービス
  service: new ServiceRequestItem(),

  // BP 競合分析 (医療、医院)
  competitor: new CompetitorsRequestItem(),

  // SWOT分析
  swot: new SwotRequestItem(),

  // 3C分析
  "3c": new ThreeCsRequestItem(),

  // STP分析
  stp: new StpRequestItem(),

  // 5Force分析
  "5forces": new FiveForcesRequestItem(),

  // PEST分析
  pest: new PestRequestItem(),

  // 4P4C分析
  "4p4c": new FourPsFourCsRequestItem(),

  // 4P競合比較分析
  "4p-comparison": new FourPsComparisonRequestItem(),

  // ビジネスモデルキャンバス
  businessmodel: new BusinessModelCanvasRequestItem(),

  // ターゲットユーザ
  target: new TargetRequestItem(),

  // ペルソナ
  'persona-cjm': new PersonaRequestItem(),

  // デザイン要件
  design: new DesignRequirementsItem(),
  
  // 目標数値
  targetgoal: new TargetValuesRequestItem(),

  // プランニング
  planning: new PlanningInfoRequest(),
};
