/**
 * SWOT分析情報 Request Item Class.
 *
 */

 export default class ThreeCsRequestItem {

  // 市場・顧客観点
  customerViewpoint = null;
  // 競合観点
  competitorViewpoint = null;
  // 自社観点
  companyViewpoint = null;
  // クロス3C
  crossThreeCs = null;
  // 3Cまとめ
  threeCsSummary = null;
  // 入力ステータス
  inputStatusThreeCs = null;

}