/**
 * 目標数値-業績(不動産)情報 Request Item Class.
 *
 */

export default class TargetValuesRealEstateItem {

  // 月間媒介契約件数
  monthlyBrokerage = null;
  // 月間買取件数
  monthlyPurchase = null;
  // 査定サイト月平均件数
  monthlyPortalsiteInbound = null;
  // 査定サイト成約率
  portalsiteCloseRate = null;
  // 平均取引金額（土地）
  averageAmountLand = null;
  // 平均取引金額（戸建て）
  averageAmountHouse = null;
  // 平均取引金額（マンション）
  averageAmountMansion = null;

}