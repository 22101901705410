<template>
  <v-container>
    <v-row>
      <v-col>
        <p class="text-center">{{ title }}</p>

        <v-text-field
          label="サイト名"
          :value="value.siteName"
          @input="update($event, 'siteName')"
          hide-details
          class="mb-3"
          :readonly="readonly"
        ></v-text-field>

        <v-text-field
          label="URL"
          :value="value.siteUrl"
          @input="didInputUrl($event, 'siteUrl')"
          @change="didInputUrl($event, 'siteUrl')"
          hide-details
          class="mb-3"
          :readonly="readonly"
          :loading="loading"
        ></v-text-field>
        <v-card>
          <v-img aspect-ratio="1" class="grey mb-3" :src="value.siteImageUrl" contain />
        </v-card>
        <div>
          <div class="text-caption">・参考ポイント</div>
          <v-textarea
            :value="value.referencePoint"
            @input="update($event, 'referencePoint')"
            outlined
            auto-grow
            hide-details
            :readonly="readonly"
          ></v-textarea>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import {RepositoryFactory} from '../../../api/RepositoryFactory'
// import axios from 'axios'

export default {
  props: {
    value:      Object,
    title:      String,
    clearable:  Boolean,
    readonly:   Boolean,
  },
  data () {
    return {
      loading: false,
    }
  },

  created() {
    // URLを入力した場合
    // 遅延で処理する
    this.didInputUrl =  _.debounce((v, k) => {
      this.update(v, k)
      this.fetchSiteImage(v)
    }, 1000)
  },

  methods: {
    // サイト画像を読み込む
    async fetchSiteImage(value) {
      // TODO: APIにURLを渡して画面に表示する
      let image = ''

      if (value && value.length) {
        if(value.match(/https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g) != null) {
          this.loading = true

          // const res = await axios.get('http://54.178.51.99:3000/api/v1/ogp', {
          //   params: {
          //     siteUrl: value
          //   }
          // });

          // this.loading = false

          // if(res.data.data) {
          //   image = res.data.data.image
          // }

          const OgpRepository = RepositoryFactory.get('ogp')
          const response = await OgpRepository.getOgp(
            {
              params: {siteUrl: value}
            }
          )

          this.loading = false

          if (response.data.data) {
            image = response.data.data.image
          }

        }
      }

      let site = this.value
      site.siteImageUrl = image
      this.$emit('input', site)
    },

    // データの更新
    update(value, key) {
      let site = this.value
      site[key] = value
      this.$emit('input', site)
    }
  },
}
</script>
