<template>
  <div v-click-outside="save">
    <v-card color="grey lighten-3">
      <v-toolbar class="x-scroll" elevation="0">
        <v-btn :disabled="readonly || sectionReadonly" :color="editable ? 'success' : 'primary'" class="mr-5" @click="saveOrEditStart()" depressed>
          <v-icon>mdi-content-save-edit</v-icon>
          {{editable ? '終了' : '編集'}}
        </v-btn>
        <v-btn :disabled="!editMode || sectionReadonly" color="primary" class="mr-5" @click="openResetDialog()" depressed>
          RESET
        </v-btn>
        <v-spacer></v-spacer>
        <!-- <a @click="download()" download="Cross3C.png" :href="fileImage" class="black--text">DOWNLOAD</a> -->
        <div class="d-flex">
          <div>
            <v-btn @click="imgCopy()" class="btPrimary" small icon>
              <v-icon color="white" small>mdi-content-copy</v-icon>
            </v-btn>
          </div>
          <div class="ml-3">
            <v-btn @click="download()" class="btPrimary" small icon>
              <v-icon color="white" small>mdi-download-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </v-toolbar>
      <div class="x-scroll" style="overflow-x: auto">
        <v-card color="transparent" tile elevation="0" :disabled="sectionReadonly">
          <div class="d-flex justify-center pa-10">
            <canvas ref="cross3C-editor-canvas" class="canvas" width="800" height="500"></canvas>
          </div>
        </v-card>
      </div>
      <v-dialog v-model="reset" max-width="340">
        <v-card class="pb-2">
          <v-card-text class="pb-1">
            <div class="px-3 py-5 text-center">
              入力内容を消去してもよろしいですか？
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" depressed @click="resetOK()">
              OK
            </v-btn>
            <v-btn color="grey lighten-2" depressed @click="resetCancel()">
              CANCEL
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <!-- コピーした通知 -->
    <v-snackbar v-model="notice" timeout="2000" top>
      <div class="text-center">{{ message }}</div>
    </v-snackbar>
  </div>
</template>
<script>
import { fabric } from 'fabric';

export default {
  props: ['value', 'sectionReadonly'],
  data() {
    return {
      readonly: false,
      editable: false,

      titles: [],
      texts: [],
      canvas: null,
      fileImage: null,

      reset: false,

      notice: false,
      message: "クリップボードにコピーしました。",
    }
  },
  mounted() {
    const ref = this.$refs['cross3C-editor-canvas'];
    this.canvas = new fabric.Canvas(ref);
    this.canvas.preserveObjectStacking = true

    if(this.value) {
      this.initCanvas(false);
      const _obj = JSON.parse(this.value)

      _obj.titles.forEach(i => this.addTitleLabel(i.left, i.top, i.text));
      _obj.texts.forEach(i => this.addTextbox(i.left, i.top, i.text, i.width, i.height));

      this._changeEditable(false)
    } else {
      this.initCanvas(true);
    }
  },
  computed: {
    editMode() {
      return  !this.readonly && this.editable
    },
  },
  methods: {
    _changeEditable(val) {
      if(!val) this.canvas.discardActiveObject().renderAll();
      
      this.canvas.forEachObject(o => {
        if(o._l_type) {
          o.selectable = val
        }
      })

    },

    saveOrEditStart() {
      if(this.editable) {
        this.save()
      } else {
        this.editable = true;
      }
      this._changeEditable(this.editable)
    },

    save() {
      if(!this.editable) return;

      const _obj = {
        titles: this.titles.filter(i => i).map(i => {
          return {
            left: i.left + i.width/2, 
            top: i.top, 
            text: i.text, 
          }
        }),
        texts: this.texts.filter(i => i).map(i => {
          return {
            left: i.left, 
            top: i.top, 
            text: i.text,
            width: i.width,
            height: i.height, 
          }
        }),
      }

      this.$emit('input', JSON.stringify(_obj))
      this.editable = false;
    },

    initCanvas(withLabel) {
      this.canvas.clear()
      this.canvas.backgroundColor = 'rgb(255,255,255)'

      this.titles.splice(0);
      this.texts.splice(0);

      const radius = 80;
      const overlap = 20;
      const paddingTop = 80;

      const th = (radius - overlap) * Math.tan(60 * Math.PI /180);
      

      this.addCircle(400 - radius, paddingTop, '市場・顧客\nCustomer', radius);
      this.addCircle(400 - radius - (radius-overlap), paddingTop + th, '自社\nCompany', radius);
      this.addCircle(400 - radius + (radius-overlap), paddingTop + th, '競合\nCompetitor', radius);

      const origin_x = 400;
      const origin_y = paddingTop + radius + (th + overlap)/2;

      this.addNaviLine(60, origin_x, origin_y);
      this.addNaviLine(180, origin_x, origin_y);
      this.addNaviLine(300, origin_x, origin_y);

      if(withLabel) {
        this.addTitleLabel(100, 20, '市場×自社');
        this.addTitleLabel(600, 20, '市場×競合');
        this.addTitleLabel(200, paddingTop + radius*3+10, '自社×競合');

        this.addTextbox(20, 45, "");
        this.addTextbox(500, 45, "");
        this.addTextbox(100, paddingTop + radius*3 + 10 + 25, "");
      }

    },
    addCircle(left, top, text, radius) {
      const fill = 'rgba(170,170,250,0.8)',
      stroke = 'rgb(0,0,255,0.2)',
      strokeWidth = 1
      
      const c = new fabric.Circle({
        left: left,
        top: top,
        radius: radius,
        stroke: stroke,
        strokeWidth: strokeWidth,
        fill: fill,
        selectable: false,
        evented: false,
      });
      this.canvas.add(c);

      const t = new fabric.Text(text, {
        fill: '#000',
        left: 0,
        top: 0,
        padding: 5,
        fontSize: 15,
        hasControls: false,
        selectable: false,
        textAlign: 'center',
        fontFamily: 'Noto Sans JP',
      });

      t.top = c.top + (c.height - t.height) / 2;
      t.left = c.left + (c.width - t.width) / 2;

      this.canvas.add(t);
    },

    addNaviLine(angle, origin_x, origin_y) {
      const x1 = 400;
      const y1 = 70;
      const x2 = 400;
      const y2 = y1+80;
      
      const rotation_origin = new fabric.Point(origin_x, origin_y);
      const angle_radians = fabric.util.degreesToRadians(angle);
      const start = fabric.util.rotatePoint(new fabric.Point(x1,y1), rotation_origin, angle_radians);
      const end = fabric.util.rotatePoint(new fabric.Point(x2,y2), rotation_origin, angle_radians);
      const line1 = new fabric.Line([start.x, start.y, end.x, end.y], {
          stroke: '#4444ff',
          strokeWidth: 1
      });
      this.canvas.add(line1);
    },

    addTitleLabel(left, top, text) {
      const t = new fabric.Text(text, {
        _l_type: 'title',
        fill: '#00f',
        left: left,
        top: top,
        padding: 5,
        fontSize: 15,
        hasControls: false,
        selectable: false,
        textAlign: 'center',
        fontFamily: 'Noto Sans JP',
      });

      //指定pointが中央になるよう調整
      t.left = t.left - t.width/2;
      this.canvas.add(t);

      this.titles.push(t);
    },
    addTextbox(left, top, text, width, height) {
      const textbox = new fabric.Textbox(text, {
        _l_type: 'text',
        fill: '#000',
        left: left,
        top: top,
        width: width || 230,
        fontSize: 14,
        hasControls: true,
        selectable: false,
        fontFamily: 'Noto Sans JP',
        backgroundColor: "#f2f2ff",

        cornerColor: 'gray',
        cornerSize: 6,
        transparentCorners: false,
      });
      textbox.setControlVisible('mtr', false);
      textbox.setControlVisible('mt', false);
      textbox.setControlVisible('mb', false);
      textbox.setControlVisible('bl', false);
      textbox.setControlVisible('br', false);
      textbox.setControlVisible('tl', false);
      textbox.setControlVisible('tr', false);
      textbox.set({height: height || 45});

      this.canvas.add(textbox);
      this.texts.push(textbox);
    },

    download() {
      // this.fileImage = this.canvas.toDataURL({format: 'png'});
    
      // this.fileImage = this.canvas.toDataURL({format: 'png'});
      var dt = new Date();
      var y = dt.getFullYear();
      var m = ("00" + (dt.getMonth()+1)).slice(-2);
      var d = ("00" + dt.getDate()).slice(-2);
      var dtStr = y + m + d;
      
      let link = document.createElement("a");
      link.href = this.canvas.toDataURL("image/png");
      link.download = `Cross3C_${dtStr}`;
      link.click();
    },

    imgCopy() {
      let canvas = this.$refs['cross3C-editor-canvas'];
      canvas.toBlob((blob) => {
        // http環境ではクリップボードの使用が許可されていないので動かない
        if (window.navigator.clipboard === undefined) {
          console.log("クリップボードの使用が許可されていません");
          return;
        }

        // クリップボードにコピー
        window.navigator.clipboard
          .write([new ClipboardItem({ "image/png": blob })])
          .then(() => {
            this.notice = true;
          });
      });
    },

    openResetDialog() {
      this.reset = true;
    },
    resetOK() {
      this.initCanvas(true);
      this._changeEditable(true);
      this.reset = false
    },
    resetCancel() {
      this.reset = false
    },
  },
  watch: {
    readonly(val) {
      this._changeEditable(!val)
    },
  },
}
</script>
<style lang="scss" scoped>
.canvas {
  background-color: #fff;
  // width: 100%;
  // border: 1px solid black;
}
.x-scroll {
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar{
      height: 8px;
      border-radius: 2.5px;
  }

  &::-webkit-scrollbar-track{
      background: #fff;
      border: none;
      border-radius: 2.5px;
  }

  &::-webkit-scrollbar-thumb{
      background: #ccc;
      border-radius: 10px;
      box-shadow: none;
  }

}

</style>