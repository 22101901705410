<template>
  <div>
    <div v-if="helpLeft">
      <div v-if="!(refMode || readonly)" class="d-flex justify-end">
        <v-btn
          icon
          color="btPrimary"
          dark
          @click="clickAppendIcon"
        >
          <v-icon size="32">mdi-help-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-textarea
        :label="label"
        outlined
        :rows="rows"
        :auto-grow="!rows"
        hide-details
        :readonly="readonly"
        :value="value"
        @input="_input($event)"
      ></v-textarea>
    </div>
    <div v-else class="d-flex flex-row-reverse">
      <div v-if="!(refMode || readonly)" class="d-flex justify-end">
        <v-btn
          icon
          color="btPrimary"
          dark
          @click="clickAppendIcon"
        >
          <v-icon size="32">mdi-help-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-textarea
        :label="label"
        outlined
        :rows="rows"
        :auto-grow="!rows"
        hide-details
        :readonly="readonly"
        :value="value"
        @input="_input($event)"
      ></v-textarea>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    label: String,
    readonly: Boolean,
    rows: String,
    reference: String,
    refDoc: Boolean,  //参照ファイルがテキストの場合
    refImg: Boolean,  //参照ファイルが画像の場合
    refMode: { default: false },
    helpLeft: Boolean,
  },
  methods: {
    clickAppendIcon(e) {
      if (this.refDoc) {
        this.$store.commit('open_referenceDocument', {top: e.clientY, left: e.clientX, id: this.reference, label: this.label})
      }
      if (this.refImg) {
        this.$store.commit('open_referenceImage', {top: e.clientY, left: e.clientX, id: this.reference, label: this.label})
      }
    },
    _input(val) {
      this.$emit('input', val)
    },
  },
}
</script>
