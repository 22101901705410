/**
 * 目標数値-業績(建築)情報 Request Item Class.
 *
 */

export default class TargetValuesBuildingItem {

  // 平均契約単価
  averageAmount = null;
  // 年間取引件数
  annualQuantity = null;
  // 新規集客数
  monthlyNewLead = null;
  // 新規問合せ数
  monthlyNewInquiry = null;

}